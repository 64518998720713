import * as React from "react";
import { Crumb } from "../../entities/Crumb";
import CrumbItem from "./CrumbItem";

interface Props {
  crumbs: Crumb[];
}

const Breadcrumbs = (props: Props) => {
  return (
    <nav className="breadcrubs">
      <ul>
        {props.crumbs.map((crumb, index) => (
          <CrumbItem
            key={index}
            type={
              index === 0
                ? "home"
                : index === props.crumbs.length - 1
                ? "active"
                : "link"
            }
            text={crumb.name}
            url={crumb.url}
          />
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
