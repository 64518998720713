import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShopItem } from "entities/nakup/Envelopes";
import { getPeriodLabel } from "features/period/periodHelper";
import { ChangeEvent, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useComodityBuyAllowed } from "widgets/pages/nakup/hooks/useComodityBuyAllowed";
import { cenaProTranse, period } from "../../entities/period/Prices";
import { useSelectPeriod } from "./useSelectPeriod";
import CircleLoader from "shared/components/circleLoader/CircleLoader";

interface Props {
  shopItem: ShopItem;

  onChange: (
    event: ChangeEvent<HTMLInputElement>,
    period: period,
    pricesAll: cenaProTranse[] // Tohle je hnus
  ) => void;
}

const SelectPeriod = (props: Props) => {
  const { accessTokenPayload } = useOidcAccessToken();
  const { getCommodityBuyAllowed } = useComodityBuyAllowed();

  const {
    periods,
    selectedPeriodCode,
    setSelectedPeriodCode,
    pricesAll,
    periodWithPriceIsFetching,
    pricesIsFetching,
    pricesWithDataIsFetching,
  } = useSelectPeriod(
    accessTokenPayload.email,
    props.shopItem.envelope?.purchaseEnvelopeId ?? "",
    {
      email: accessTokenPayload.email ?? "",
      purchaseEnvelopeId: props.shopItem.envelope?.purchaseEnvelopeId ?? "",
    },
    {
      rokOd: props.shopItem.envelope?.purchaseEnvYear.toString() ?? "",
      rokDo: props.shopItem.envelope?.purchaseEnvYear.toString() ?? "",
    },
    props.shopItem.prices?.produkt ?? ""
  );

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedPeriodCode(event.target.value);
  };

  useEffect(() => {
    if (periods.length === 0) return;

    const selectedPeriod = periods.find(
      (x) => x.periodCode === selectedPeriodCode
    );

    if (selectedPeriod === undefined) return;

    props.onChange(
      {
        target: {
          name: "period",
          value: selectedPeriodCode,
        },
      } as ChangeEvent<HTMLInputElement>,
      selectedPeriod,
      pricesAll
    );
  }, [periods.length]);

  return (
    <div className={"customSelect"}>
      <Form.Control
        as="select"
        defaultValue={props.shopItem.prices?.produkt}
        // disabled={}
        onChange={handleOnChange}
      >
        {props.shopItem.periodWithPrice?.data.periodList.map(
          (periodPriceItem, index) => {
            if (
              periods.find(
                (x) => x.periodCode === periodPriceItem.periodCode
              ) === undefined
            )
              return undefined;

            const platnostDo =
              props.shopItem.pricesAll?.find(
                (x) => x.produkt === periodPriceItem.periodCode
              )?.platnostDo ?? "";

            const platnostDoDate = platnostDo
              ? new Date(platnostDo)
              : new Date();

            const period = props.shopItem.periody?.find(
              (x) => x.periodCode === periodPriceItem.periodCode
            );

            const isDisabled = getCommodityBuyAllowed(
              platnostDo,
              platnostDoDate,
              period
            );

            return (
              <option
                disabled={!isDisabled}
                selected={
                  selectedPeriodCode === periodPriceItem.periodCode
                    ? true
                    : false
                }
                value={periodPriceItem.periodCode}
                key={index}
              >
                {getPeriodLabel(
                  periodPriceItem.periodCode,
                  props.shopItem.envelope?.purchaseEnvYear || 0
                )}{" "}
                (CZECH BL {periodPriceItem.periodCode})
              </option>
            );
          }
        )}
      </Form.Control>
      <FontAwesomeIcon icon={faChevronDown} />
      {(pricesIsFetching ||
        pricesWithDataIsFetching ||
        periodWithPriceIsFetching) && <CircleLoader />}
    </div>
  );
};

export default SelectPeriod;
