import * as React from "react";
import TableHeading from "./TableHeading";
import TableBody from "./TableBody";
import TableB from "react-bootstrap/Table";
import { TrancheBoughtList } from "../../../../entities/nakup/Envelopes";

interface Props {
    history: TrancheBoughtList[];
    year: number;
}

const Table = (props: Props) => {
    return (
        <>
            <TableB className="basic-table" responsive="xl">
                <TableHeading
                    content={[
                        "Datum nákupu",
                        "Období",
                        "Objem nákupu",
                        "Cena/jednotka",
                        "Cena celkem",
                        "",
                    ]}
                />
                <TableBody history={props.history} year={props.year} />
            </TableB>
            <p className="text-left"><i>Objem elektřiny v tabulkových přehledech výše uvádíme v jednotkách MWh.</i></p>
        </>
    );
};

export default Table;
