import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

const LinkButton = (props: Props) => {
  return (
    <button className="button-link" {...props}>
      {props.children}
    </button>
  );
};

export default LinkButton;
