import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FileUpload from "../../../../pages/modal/FileUpload";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { LoaderMultiple } from "../../../../entities/diagram/Diagrams";
import { useDiagramSlice } from "../../../../app/store/diagram/useDiagramSlice";
import { useSelector } from "react-redux";
import { selectDiagramItem } from "../../../../app/store/diagram/diagramSlice";
import { useSnackbar } from "notistack";
import conf from "app/config/appConfig";

interface Props {
  show: boolean;
  onHide: () => void;
  onUploaded?: () => void;
  content?: React.ReactNode;
  heading?: string;
  idNakupObalkaOcenovaneMisto: number | null;
}

const ModalUpload = (props: Props) => {
  const { diagramItemUpdate } = useDiagramSlice();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const diagramItem = useSelector(selectDiagramItem);
  const [file, setFile] = useState<any>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loader, setLoader] = React.useState<LoaderMultiple>({
    status: false,
    pid: null,
  });
  const [error, setError] = useState<React.ReactNode>(null);

  const handleFileChange = (file: File) => {
    setFile(file);
    setError(null);
    setIsButtonDisabled(false);
  };

  const handleFileDrop = (file: File) => {
    setFile(file);
    setError(null);
    setIsButtonDisabled(false);
  };

  useEffect(() => {
    if (props.show) {
      diagramItemUpdate({
        sucess: false,
        error: diagramItem.error,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    setError(null);
    setLoader({ status: false, pid: props.idNakupObalkaOcenovaneMisto });
    setFile(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show, props.idNakupObalkaOcenovaneMisto, props.onHide]);

  const handleUploadFile = (idNakupObalkaOcenovaneMisto: number | null) => {
    if (!idNakupObalkaOcenovaneMisto || !file) return;

    setLoader({ status: true, pid: idNakupObalkaOcenovaneMisto });
    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "idNakupObalkaOcenovaneMisto",
      idNakupObalkaOcenovaneMisto.toString()
    );

    axios
      .post(`${conf.REACT_APP_API_URL}PlanEnvelope/Import`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Chyba WCF
        if (response.data.data.length > 0) {
          setError(
            <div style={{ color: "red" }}>
              Nastala chyba při nahrávání souboru. <br />
              <br />
              {response.data.data.map((message: string) => {
                if (
                  message.includes("System.IO.FileFormatException") &&
                  message.includes("File contains corrupted data.")
                ) {
                  message = "";
                }
                if (message.includes("File contains corrupted data.")) {
                  message = "Nesprávný formát souboru.";
                }

                return (
                  <div>
                    {message} <br />
                  </div>
                );
              })}
              Opakujte prosím pokus.
            </div>
          );
          setFile(null);

          setLoader({ status: false, pid: idNakupObalkaOcenovaneMisto });
          setIsButtonDisabled(true);
          enqueueSnackbar("Nastala chyba při nahrávání souboru.", {
            variant: "error",
          });

          return;
        }

        if (response.data.alert.severity !== "Success") {
          setError(
            <div style={{ color: "red" }}>
              Nastala chyba při nahrávání souboru. <br />
              {response.data.alert.message} <br />
              Opakujte prosím pokus.
            </div>
          );
          setFile(null);

          setLoader({ status: false, pid: idNakupObalkaOcenovaneMisto });
          setIsButtonDisabled(true);
          enqueueSnackbar("Nastala chyba při nahrávání souboru.", {
            variant: "error",
          });
        } else {
          setLoader({ status: false, pid: idNakupObalkaOcenovaneMisto });

          diagramItemUpdate({
            sucess: true,
            error: diagramItem.error,
          });

          enqueueSnackbar("Soubor byl úspěšně nahrán.", {
            variant: "success",
          });

          props.onUploaded?.();
          props.onHide();
        }
      })
      .catch((error) => {
        setFile(null);

        setLoader({ status: false, pid: idNakupObalkaOcenovaneMisto });
        setIsButtonDisabled(true);
        setError(
          <div style={{ color: "red" }}>
            Nastala chyba při nahrávání souboru.
          </div>
        );
        enqueueSnackbar("Nastala chyba při nahrávání souboru.", {
          variant: "error",
        });
      });
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        centered
        backdrop={loader.status ? "static" : true}
      >
        <Modal.Header>
          {props.heading && <Modal.Title>{props.heading}</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          {props.content ? props.content : ""}
          <FileUpload
            handleChange={handleFileChange}
            handleDrop={handleFileDrop}
            error={error}
          />
        </Modal.Body>
        <Modal.Footer>
          {error && (
            <p className="error-p">
              <FontAwesomeIcon icon={faExclamationCircle} color="red" />
              <br /> {error}
            </p>
          )}
          <Button
            onClick={() => handleUploadFile(props.idNakupObalkaOcenovaneMisto)}
            className="basic-button"
            disabled={
              isButtonDisabled ||
              (loader.pid === props.idNakupObalkaOcenovaneMisto &&
                loader.status)
            }
          >
            {loader.pid === props.idNakupObalkaOcenovaneMisto &&
            loader.status ? (
              <div>
                <span className="spinner btnsmall" />
                Nahrávání...
              </div>
            ) : (
              "Nahrát"
            )}
          </Button>
          <Button variant="button-link" onClick={props.onHide}>
            Zrušit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalUpload;
