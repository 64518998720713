import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useEffect } from "react";
import axios from "axios";
import { useAuthSlice } from "app/store/auth/useAuthSlice";

interface Props {
  isAuthHeader: boolean;
  setIsAuthHeader: React.Dispatch<React.SetStateAction<boolean>>;
}

const LayoutUnauthorized = (props: Props) => {
  const { accessToken } = useOidcAccessToken();

  const { authUpdate } = useAuthSlice();

  useEffect(() => {
    if (!accessToken) return;

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

    authUpdate({
      token: accessToken,
    });

    if (!props.isAuthHeader) props.setIsAuthHeader(true);
  }, [accessToken]);

  return <></>;
};

export default LayoutUnauthorized;
