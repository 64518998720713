import { useState } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { ShopItem, ShowGraph } from "../../../entities/nakup/Envelopes";
import Heading from "../../../shared/widgets/Heading";
import DetailGraph from "./DetailGraph";
import OfferBuyProcessForm from "./OfferBuyProcessForm";

interface Props {
    error: boolean;
    shopItem: ShopItem;
    navigation: string[];
    newDateToCountDown: (date: Date) => void;
}

const OfferBuyProcess = (props: Props) => {
    const [activeIndex, setActiveIndex] = useState(0); // Aktivní index
    const [graphHighlight, setGraphHighlight] = useState<ShowGraph>(); // Aktivní index

    const handleButtonClick = (filter: string, index: number) => {
        setActiveIndex(index);
    };

    function handleShowInGraph(graphInfo: ShowGraph | undefined): void {
        setGraphHighlight(graphInfo);
    }

    function handleDateChange(date: Date): void {
        props.newDateToCountDown(date);
    }

    return (
        <section className="OfferBuyProcess widget">
            <Heading content={"Informace o nákupní skupině"} />
            <div className="widget-content">
                <Row className="pb-4">
                    <Col lg={12} xl={6} className="break-sooner">
                        <OfferBuyProcessForm
                            error={props.error}
                            shopItem={props.shopItem}
                            showInGraph={handleShowInGraph}
                            newDateToCountDown={handleDateChange}
                        />
                    </Col>
                    <Col lg={12} xl={6} className="break-sooner">
                        <ButtonGroup>
                            {props.navigation.map((item, index) => (
                                <Button
                                    key={index}
                                    variant="secondary"
                                    onClick={() => handleButtonClick(item, index)}
                                    className={index === activeIndex ? "active" : ""}
                                >
                                    {item}
                                </Button>
                            ))}
                        </ButtonGroup>
                        <DetailGraph
                            envelope={props.shopItem.envelope!}
                            activeIndex={activeIndex}
                            formulaTrancheType={
                                props.shopItem.pricesWithData?.data.formulaList[0]
                                    .formulaTrancheType
                            }
                            graphHighlight={graphHighlight}
                        />
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default OfferBuyProcess;
