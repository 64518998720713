import * as React from 'react';
import TableHeadingRow from './TableHeadingRow';

interface Props {
    content: string[];
}

const TableHeading = (props: Props) => {
    return (
        <thead>
            <TableHeadingRow content={props.content} />
        </thead>
    );
};

export default TableHeading;
