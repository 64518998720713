import { PeriodWithPrice } from "entities/period/PeriodWithPrice";
import { Prices, periodListToSendItem } from "entities/period/Prices";

export const getPeriodLabel = (obdobi: string, rok: number) => {
  if (obdobi.startsWith("M")) {
    const month = obdobi.slice(1);
    const monthName = getMonthName(month);
    return `${monthName} ${rok}`;
  } else if (obdobi.startsWith("Q")) {
    const quarter = obdobi.slice(1);
    return `${quarter}. Kvartál ${rok}`;
  } else if (obdobi === "R") {
    return `Celý rok ${rok}`;
  }
  return obdobi;
};

export const getMonthName = (month: string) => {
  const monthIndex = parseInt(month, 10) - 1;
  const monthNames = [
    "Leden",
    "Únor",
    "Březen",
    "Duben",
    "Květen",
    "Červen",
    "Červenec",
    "Srpen",
    "Září",
    "Říjen",
    "Listopad",
    "Prosinec",
  ];
  return monthNames[monthIndex];
};

export const getPeriodsToSend = (
  periodWithPrice: PeriodWithPrice,
  prices: Prices | null | undefined
): periodListToSendItem[] => {
  const periodListToSend: periodListToSendItem[] = [];

  periodWithPrice.data.periodList.forEach((periodItem) => {
    const cenaProTranseEE = prices?.data?.cenaProTranseEE;
    const cenaProTranseItem = cenaProTranseEE?.find(
      (item) => item.produkt === periodItem.periodCode
    );
    let cena = cenaProTranseItem?.cenaEurMWh;

    const periodToSend: periodListToSendItem = {
      periodCode: periodItem.periodCode,
      priceBaseLoad: cena ?? 0,
      exchangeRate: 0,
      kindDerivationPrice: periodItem.kindDerivationPrice,
      dateLoadedPrice: new Date().toISOString(),
    };

    periodListToSend.push(periodToSend);
  });

  return periodListToSend;
};
