import { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import conf from "../../../app/config/appConfig";

interface Props {
    label: string;

    name: string;

    value?: string | number | string[] | undefined;

    disabled?: boolean;

    input: string;

    onBlur?: (e: any) => void;
}

const OfferBuyProcessAmountInput = (props: Props) => {
    const [value, setValue] = useState<number>(0);

    useEffect(() => {
        const parsedValue = typeof props.value === 'string' ? parseFloat(props.value) : props.value;
        const numericValue = typeof parsedValue === 'number' && !isNaN(parsedValue) ? parsedValue : 0;
        setValue(numericValue);
        
    },[props.value]);

    const handleOnChange = (e: any) => {
        let newValue = e.target.value;

        if (props.name === "percent") {
            // Omezte vstup na cel� ��sla
            newValue = newValue.split(/[.,]/)[0];
        } else if (props.name === "mw") {
            const parts = newValue.split(",");
            if (parts.length > 1 && parts[1].length > 2) {
                parts[1] = parts[1].slice(0, conf.REACT_APP_MW_DECIMALS);
            }
            newValue = parts.join(".");
        } else if (props.name === "mwh") {
            const parts = newValue.split(".");
            if (parts.length > 1 && parts[1].length > 2) {
                parts[1] = parts[1].slice(0, conf.REACT_APP_MWH_DECIMALS);
            }
            newValue = parts.join(",");
        }

        setValue(newValue);
    };

    return (
        <InputGroup>
            <Form.Control
                name={props.name}
                type="text"
                placeholder={props.input === "disabled+" ? "" : "0"}
                disabled={props.disabled || props.input !== ""}
                step={props.name === "mw" ? "0.01" : "1"}
                aria-describedby="desc1"
                value={props.input === "disabled+" ? "" : value.toString().replace(".", ",")}
                onChange={handleOnChange}
                onBlur={props.onBlur}
            />
            <InputGroup.Text id="desc1">{props.label}</InputGroup.Text>
        </InputGroup>
    );
};

export default OfferBuyProcessAmountInput;
