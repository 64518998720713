import { PricesWithData } from "entities/period/Prices";
import { mainBaseApi } from "../mainBaseApi";
import PriceGetParams from "./PriceGetParams";

const endpointUrl = "Price";

const priceApi = mainBaseApi.injectEndpoints({
  endpoints: (build) => ({
    getPrice: build.query<PricesWithData | null, PriceGetParams | null>({
      query: (body) => ({
        url: `${endpointUrl}/Get`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useGetPriceQuery } = priceApi;
