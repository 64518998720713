import { useDispatch } from "react-redux";
import { Years } from "../../../entities/diagram/Diagrams";
import { actions } from "./yearsSlice";

export const useYearsSlice = () => {
  const dispatch = useDispatch();

    const yearsUpdate = (
        yearsItem: Partial<Years>
    ) => {
        dispatch(actions.yearsUpdate(yearsItem));
  };

  return {
      yearsUpdate,
  };
};
