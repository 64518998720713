import { useAuthSlice } from "app/store/auth/useAuthSlice";
import { store } from "app/store/store";
import { useEffect } from "react";

export const usePinLastActivity = () => {
  const { authUpdate } = useAuthSlice();

  useEffect(() => {
    function handleUpdateOnWindowClick(event: any) {
      const state = store.getState();

      if (event.target.name === "pin") return;
      if (event.target.name === "resetPIN") return;
      if (event.target.name === "verifyPIN") return;

      // Pin nebyl ověřen
      if (!state.auth.authModel.pinVerifiedTimestamp) return;
      // Pin vypršel

      // Pokud nejsme na stránce nakup neaktualizujeme
      if (!window.location.href.includes("nakup")) return;

      authUpdate({
        pinVerifiedTimestamp: new Date().toISOString(),
      });
    }
    window.removeEventListener("click", handleUpdateOnWindowClick);
    window.addEventListener("click", handleUpdateOnWindowClick);
  }, []);

  return {};
};
