import { useOidcAccessToken } from "@axa-fr/react-oidc";
import PeriodGetParams from "app/api/main/period/PeriodGetParams";
import { useGetPeriodQuery } from "app/api/main/period/periodApi";
import PriceGetParams from "app/api/main/price/PriceGetParams";
import { useGetPriceQuery } from "app/api/main/price/priceApi";
import GetCenaParams from "app/api/main/tranche/GetCenaParams";
import { useGetCenaQuery } from "app/api/main/tranche/trancheApi";
import { usePeriodSlice } from "app/store/period/usePeriodSlice";
import { getPeriodsToSend } from "features/period/periodHelper";
import { useEffect, useState } from "react";
import CircleLoader from "shared/components/circleLoader/CircleLoader";
import { PurchaseEnvelopeList } from "../../../entities/nakup/Envelopes";
import {
    PricesWithData,
    periodListToSendItem,
    Prices,
} from "../../../entities/period/Prices";
import Heading from "../../../shared/widgets/Heading";
import Table from "./offerListTable/Table";

interface Props {
    shopAllowed: boolean;
    envelope: PurchaseEnvelopeList;
}

const OffersList = (props: Props) => {
    const [filter, setFilter] = useState<string>("Vše");
    const { accessTokenPayload } = useOidcAccessToken();

    const { periodsToSendSet } = usePeriodSlice();

    // GetPeriodQuery
    const [periodGetParams, setPeriodGetParams] =
        useState<PeriodGetParams | null>(null);
    const { data: periodWithPrice, isFetching: periodWithPriceIsFetching } =
        useGetPeriodQuery(periodGetParams, {
            skip: periodGetParams === null,
        });

    // GetPriceQuery
    const [priceGetParams, setPriceGetParams] = useState<PriceGetParams | null>(
        null
    );
    const { data: pricesWithData, isFetching: pricesWithDataIsFetching } =
        useGetPriceQuery(priceGetParams, {
            skip: priceGetParams === null,
        });

    // GetCenaQuery
    const [getCenaParams, setGetCenaParams] = useState<GetCenaParams | null>(
        null
    );
    const { data: prices, isFetching: pricesIsFetching } = useGetCenaQuery(
        getCenaParams,
        {
            skip: getCenaParams === null,
        }
    );

    useEffect(() => {
        getPeriodWithPrice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.envelope]);

    useEffect(() => {
        if (pricesIsFetching) return;
        if (!periodWithPrice) return;

        periodsToSendSet(getPeriodsToSend(periodWithPrice, prices));
    }, [periodWithPriceIsFetching]);

    useEffect(() => {
        if (periodWithPriceIsFetching) return;

        getPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodWithPriceIsFetching]);

    useEffect(() => {
        if (pricesIsFetching) return;

        getPriceWithData();
    }, [pricesIsFetching, JSON.stringify(periodWithPrice?.data)]);

    function filterOffers(filterVal: string): void {
        setFilter(filterVal);
    }

    const getPriceWithData = () => {
        let email = accessTokenPayload.email;
        let periodListToSend: periodListToSendItem[] = [];

        if (periodWithPrice?.data.periodList) {
            periodListToSend = getPeriodsToSend(periodWithPrice, prices);
        }

        setPriceGetParams({
            Email: email ? email : "",
            PurchaseEnvelopeId: props.envelope.purchaseEnvelopeId,
            PeriodList: periodListToSend,
        });
    };

    const getPrices = () => {
        setGetCenaParams({
            rokOd: props.envelope.purchaseEnvYear.toString(),
            rokDo: props.envelope.purchaseEnvYear.toString(),
        });
    };

    const getPeriodWithPrice = () => {
        setPeriodGetParams({
            email: accessTokenPayload.email,
            purchaseEnvelopeId: props.envelope.purchaseEnvelopeId,
        });
    };

    const renderTable = (pPricesWithData: PricesWithData | null, pPrices: Prices | null) => {
        if (!pPricesWithData) {
            return <CircleLoader />;
        }
        if (!pPrices) {
            return <CircleLoader />;
        }

        return (
            <Table
                content={periodWithPrice ?? null}
                pricesWithData={pPricesWithData}
                envelope={props.envelope}
                prices={pPrices}
                filter={filter}
                shopAllowed={props.shopAllowed}
                isFetching={pricesWithDataIsFetching}
            />
        );
    };

    return (
        <section className="OfferList widget">
            <Heading
                content={"Nákup komodity"}
                navigation={["Vše", "Měsíční", "Kvartální", "Roční"]}
                filterOffers={filterOffers}
            />
            <div className="widget-content">
                {renderTable(pricesWithData ?? null, prices ?? null)}
            </div>
        </section>
    );
};

export default OffersList;
