import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Props {
    toggleUpload: (toggle: boolean) => void;
}


const HeaderButtonToggle = (props: any) => {
    const [sidebarToggle, setSidebarToggle] = React.useState<boolean>(false);

    function handleToggle(toggle: boolean): void {
        setSidebarToggle(toggle);
        props.toggleUpload(toggle);
    }

    return (
        <>
            <button className={sidebarToggle ? "header-buttonToggle toggled-respo" : "header-buttonToggle"} onClick={() => handleToggle(!sidebarToggle)} >
                <FontAwesomeIcon icon={faBars} className={"bars"} /><FontAwesomeIcon icon={faTimes} className={"respo-cross"} />
            </button>
        </>
    );
};

export default HeaderButtonToggle;