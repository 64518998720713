/* eslint-disable eqeqeq */
import * as React from "react";
import { statusDne } from "../../entities/nakup/Envelopes";
import InfoWindow from "./infoWindow/InfoWindow";
import conf from "app/config/appConfig";

interface Props {
    setShopAllowed: (toggle: boolean) => void;
    statusDne: statusDne | undefined;
    additionalContent?: JSX.Element; // No proste prasarna
}

const InfoHeadingWithErrors = (props: Props) => {
    const phoneNumber = conf.REACT_APP_TEL;
    const phoneNumberWithoutSpaces = phoneNumber
        ? phoneNumber.replace(/\s/g, "")
        : "";
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // 0 = neděle, 1 = pondělí, ..., 6 = sobota
    const currentTime = currentDate.getHours() * 100 + currentDate.getMinutes();

    // Získání časového rozmezí pro aktuální den
    let timeRange = null;
    switch (currentDay) {
        case 1: // Pondělí
            timeRange = conf.REACT_APP_PO;
            break;
        case 2: // Úterý
            timeRange = conf.REACT_APP_UT;
            break;
        case 3: // Středa
            timeRange = conf.REACT_APP_ST;
            break;
        case 4: // Čtvrtek
            timeRange = conf.REACT_APP_CT;
            break;
        case 5: // Pátek
            timeRange = conf.REACT_APP_PA;
            break;
        default:
            break;
    }

    if (props.statusDne != undefined) {
        if (
            !props.statusDne.obchodniDen &&
            !props.statusDne.pracovniDen &&
            props.statusDne.svatek &&
            props.statusDne.volnyDen
        ) {
            props.setShopAllowed(false);
            return (
                <InfoWindow
                    heading="Aktuálně není možné obchodovat"
                    type="warning"
                    content={
                        <React.Fragment>
                            Obchodování je možné ve všední dny. <br /> Pro více informací nás
                            kontaktujte na telefonu{" "}
                            <b>
                                <a href={"tel:" + phoneNumberWithoutSpaces}>{phoneNumber}</a>
                            </b>{" "}
                            nebo nám napište
                            <b>
                                {" "}
                                <a href={"mailto:" + conf.REACT_APP_MAIL}>
                                    {conf.REACT_APP_MAIL}
                                </a>
                            </b>
                            .
                        </React.Fragment>
                    }
                />
            );
        }
    }

    if (timeRange) {
        const times = timeRange.split(" a ");

        const [startTime1, endTime1] = times[0].split(" - ");
        const [startTime2, endTime2] = times[1].split(" - ");

        const start1 = parseInt(startTime1.replace(":", ""), 10);
        const end1 = parseInt(endTime1.replace(":", ""), 10);
        const start2 = parseInt(startTime2.replace(":", ""), 10);
        const end2 = parseInt(endTime2.replace(":", ""), 10);

        if (
            (currentTime >= start1 && currentTime <= end1) ||
            (currentTime >= start2 && currentTime <= end2)
        ) {
            // Čas je v rozmezí, vrátit odpovídající heading
            props.setShopAllowed(true);
            return (
                <InfoWindow
                    heading="Aktuálně je možné obchodovat"
                    type="success"
                    content={
                        <>
                            {props.additionalContent !== undefined && (
                                <>{props.additionalContent}</>
                            )}
                            Obchodování je možné v pracovní dny mezi
                            <b> {timeRange}</b>.<br />V případě problémů nebo pro více
                            informací nás kontaktujte telefonem na čísle{" "}
                            <b>
                                <a href={"tel:" + phoneNumberWithoutSpaces}>{phoneNumber}</a>
                            </b>
                            , nebo nám napište email na
                            <b>
                                {" "}
                                <a href={"mailto:" + conf.REACT_APP_MAIL}>
                                    {conf.REACT_APP_MAIL}
                                </a>
                            </b>
                            .
                        </>
                    }
                />
            );
        } else {
            props.setShopAllowed(false);
            return (
                <InfoWindow
                    heading="Aktuálně není možné obchodovat"
                    type="warning"
                    content={
                        <React.Fragment>
                            Obchodování je možné v pracovní dny mezi
                            <b> {timeRange}</b>.<br /> V případě problémů nebo pro více
                            informací nás kontaktujte telefonem na čísle{" "}
                            <b>
                                <a href={"tel:" + phoneNumberWithoutSpaces}>{phoneNumber}</a>
                            </b>
                            , nebo nám napište email na
                            <b>
                                {" "}
                                <a href={"mailto:" + conf.REACT_APP_MAIL}>
                                    {conf.REACT_APP_MAIL}
                                </a>
                            </b>
                            .
                        </React.Fragment>
                    }
                />
            );
        }
    } else {
        props.setShopAllowed(false);
        return (
            <InfoWindow
                heading="Aktuálně není možné obchodovat"
                type="warning"
                content={
                    <React.Fragment>
                        Obchodování je možné v pracovní dny.<br /> V případě problémů nebo pro více
                        informací nás kontaktujte telefonem na čísle{" "}
                        <b>
                            <a href={"tel:" + phoneNumberWithoutSpaces}>{phoneNumber}</a>
                        </b>
                        , nebo nám napište email na
                        <b>
                            {" "}
                            <a href={"mailto:" + conf.REACT_APP_MAIL}>
                                {conf.REACT_APP_MAIL}
                            </a>
                        </b>
                        .
                    </React.Fragment>
                }
            />
        );
    }
};

export default InfoHeadingWithErrors;
