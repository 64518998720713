import Q from "q";
import { useEffect, useState } from "react";
import {
    Bar,
    BarChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import {
    GraphInfo,
    MonthData,
    PurchaseEnvelopeList,
    ShowGraph,
} from "../../../entities/nakup/Envelopes";
import conf from "app/config/appConfig";

interface Props {
    envelope: PurchaseEnvelopeList;
    activeIndex?: number;
    graphHighlight?: ShowGraph | undefined;
    formulaTrancheType?: string;
}

const DetailGraph = (props: Props) => {
    const [activeIndexSetter, setActiveIndexSetter] = useState(0);
    const [newData, setNewData] = useState<MonthData[] | undefined>(undefined);
    const [lineHighlightSize, setLineHighlightSize] = useState(0.5);
    const [graphInfo, setGraphInfo] = useState<GraphInfo>({
        original: 0,
        totalBought: 0,
        monthly: 0,
        quarterly: 0,
        yearly: 0,
        buying: 0,
    });

    function allMonthsNonZero(
        boughtList: { month: number; amount: number }[]
    ): boolean {
        return boughtList.every((item) => item.amount !== 0);
    }

    function onlyOneValueFilled(
        boughtList: { month: number; amount: number }[]
    ): boolean {
        const filledValues = boughtList.filter((item) => item.amount !== 0);
        return filledValues.length === 1;
    }
    const months: string[] = [
        "Leden",
        "Únor",
        "Březen",
        "Duben",
        "Květen",
        "Červen",
        "Červenec",
        "Srpen",
        "Září",
        "Říjen",
        "Listopad",
        "Prosinec",
    ];
    function createMonthData(
        name: string,
        position: number,
        activeIndes: number,
        highlighter: ShowGraph | undefined
    ): MonthData {
        let thisMonthTotal =
            activeIndes === 1
                ? props.envelope.aktPlanNOList[position].amount
                : props.envelope.ocPlanNOList[position].amount;
        let yearValue = 0;
        let quarterValue = 0;
        let monthValue = 0;

        props.envelope.trancheBoughtList.forEach((item) => {
            if (item.purchaseStatus !== "Storno") {
                //nepocitat stornovane?
                if (allMonthsNonZero(item.boughtList)) {
                    //vsechny mesice maji hodnotu = celý rok
                    yearValue = yearValue + item.boughtList[position].amount;
                } else {
                    if (onlyOneValueFilled(item.boughtList)) {
                        //mesic
                        monthValue = monthValue + item.boughtList[position].amount;
                    } else {
                        //kvartal
                        quarterValue = quarterValue + item.boughtList[position].amount;
                    }
                }
            }
        });

        let mnozstviHiglight = 0;
        let highlight = false;
        let whatToHighlight = "";
        let rokBuying = 0;
        let ctvrtletiBuying = 0;
        let mesicBuying = 0;
        let rokBuyingOF = 0;
        let ctvrtletiBuyingOF = 0;
        let mesicBuyingOF = 0;

        if (props.formulaTrancheType === "PeakLoad") {
            //Pokud bude FormulaTrancheType = PeakLoad, pak se nic v grafu vyznačovat nebude
        } else if (props.formulaTrancheType === "Profil") {
            //Pokud bude FormulaTrancheType = Profil, podle procenta se dopočte množství na dané období  z profilu Oceněný plán
            //reseni prave nakupujete
            if (highlighter) {
                let obdobi = highlighter.periodCode;
                let index = Number(obdobi.substring(1));
                let positionFix = position + 1;

                if (obdobi.startsWith("M")) {
                    if (positionFix === index) {
                        mnozstviHiglight = isNaN(highlighter.ammount) ? 0 : highlighter.ammount;
                        highlight = true;
                        whatToHighlight = "M";
                        mesicBuying = mnozstviHiglight;
                    }
                    //Měsíční - nic se nemusí počítat
                } else if (obdobi.startsWith("Q")) {
                    const quarter = index;
                    const startMonth = (quarter - 1) * 3 + 1;
                    const endMonth = quarter * 3;

                    if (positionFix >= startMonth && positionFix <= endMonth) {
                        highlight = true;
                        let zaklad =
                            props.envelope.ocPlanNOList.find((x) => x.month === startMonth)
                                ?.amount ?? 0;
                        zaklad +=
                            props.envelope.ocPlanNOList.find(
                                (x) => x.month === startMonth + 1
                            )?.amount ?? 0;
                        zaklad +=
                            props.envelope.ocPlanNOList.find((x) => x.month === endMonth)
                                ?.amount ?? 0;

                        let procentoMesice =
                            (props.envelope.ocPlanNOList.find((x) => x.month === positionFix)
                                ?.amount ?? 0) /
                            (zaklad / 100);
                        mnozstviHiglight = isNaN(highlighter.ammount) ? 0 : highlighter.ammount * (procentoMesice / 100);
                        whatToHighlight = "Q";
                        ctvrtletiBuying = mnozstviHiglight;
                    }
                } else if (obdobi === "R") {
                    // Předpokládáme, že props.envelope.ocPlanNOList je pole objektů s vlastnostmi month a amount
                    let zaklad = props.envelope.ocPlanNOList.reduce(
                        (sum, item) => sum + (item.amount ?? 0),
                        0
                    );
                    let procentoMesice =
                        (props.envelope.ocPlanNOList.find((x) => x.month === positionFix)
                            ?.amount ?? 0) /
                        (zaklad / 100);
                    mnozstviHiglight = isNaN(highlighter.ammount) ? 0 : highlighter.ammount * (procentoMesice / 100);
                    highlight = true;
                    whatToHighlight = "R";
                    rokBuying = mnozstviHiglight;
                }
            }
        } else if (props.formulaTrancheType === "BaseLoad") {
            //Pokud bude FormulaTrancheType = BaseLoad
            if (highlighter) {
                let obdobi = highlighter.periodCode;
                let index = Number(obdobi.substring(1));
                let positionFix = position + 1;

                if (obdobi.startsWith("M")) {
                    if (positionFix === index) {
                        mnozstviHiglight = isNaN(highlighter.ammount) ? 0 : highlighter.ammount;
                        highlight = true;
                        whatToHighlight = "M";
                        mesicBuying = mnozstviHiglight;
                    }
                } else if (obdobi.startsWith("Q")) {
                    const quarter = index;
                    const startMonth = (quarter - 1) * 3 + 1;
                    const endMonth = quarter * 3;

                    if (positionFix >= startMonth && positionFix <= endMonth) {
                        highlight = true;
                        let zaklad = new Date(
                            props.envelope.purchaseEnvYear,
                            positionFix - 1,
                            1
                        );

                        const getNextMonthFirstDate = () => {
                            return new Date(props.envelope.purchaseEnvYear, positionFix, 1);
                        };

                        const calculateHoursInMonth = () => {
                            const currentMonthFirstDate = zaklad;
                            const nextMonthFirstDate = getNextMonthFirstDate();
                            const differenceInMilliseconds =
                                nextMonthFirstDate.getTime() - currentMonthFirstDate.getTime();
                            const hoursInMonth = differenceInMilliseconds / 3600000; // Počet milisekund v hodině
                            return hoursInMonth;
                        };

                        let hoursInMonth = calculateHoursInMonth();
                        let leepHour = 0;

                        if (positionFix === 3) {
                            leepHour = -1;
                        }

                        if (positionFix === 10) {
                            leepHour = 1;
                        }
                        hoursInMonth += leepHour;

                        mnozstviHiglight = isNaN(highlighter.ammountMw) ? 0 : highlighter.ammountMw * hoursInMonth;
                        whatToHighlight = "Q";
                        ctvrtletiBuying = mnozstviHiglight;
                    }
                } else if (obdobi === "R") {
                    // Předpokládáme, že props.envelope.ocPlanNOList je pole objektů s vlastnostmi month a amount
                    let zaklad = new Date(
                        props.envelope.purchaseEnvYear,
                        positionFix - 1,
                        1
                    );

                    const getNextMonthFirstDate = () => {
                        return new Date(props.envelope.purchaseEnvYear, positionFix, 1);
                    };

                    const calculateHoursInMonth = () => {
                        const currentMonthFirstDate = zaklad;
                        const nextMonthFirstDate = getNextMonthFirstDate();
                        const differenceInMilliseconds =
                            nextMonthFirstDate.getTime() - currentMonthFirstDate.getTime();
                        const hoursInMonth = differenceInMilliseconds / 3600000; // Počet milisekund v hodině
                        return hoursInMonth;
                    };

                    let hoursInMonth = calculateHoursInMonth();
                    let leepHour = 0;

                    if (positionFix === 3) {
                        leepHour = -1;
                    }

                    if (positionFix === 10) {
                        leepHour = 1;
                    }
                    hoursInMonth += leepHour;

                    mnozstviHiglight = isNaN(highlighter.ammountMw) ? 0 : highlighter.ammountMw * hoursInMonth;
                    highlight = true;
                    whatToHighlight = "R";
                    rokBuying = mnozstviHiglight;
                }
            }
        }

        let rokof = 0;
        let ctvrtletiof = 0;
        let mesicof = 0;

        if (isNaN(mnozstviHiglight)) {
            mnozstviHiglight = 0;
        } 

        var zbytek = thisMonthTotal - yearValue - quarterValue - monthValue - mnozstviHiglight;
        //zjistit zda li je došlo k přetečení a pokud ano tak rozdělit blok a zbytek dopsat v OF
        if (
            thisMonthTotal <
            yearValue + quarterValue + monthValue + mnozstviHiglight
        ) {
            zbytek = 0;

            if (highlight && mnozstviHiglight !== 0) {
                if (whatToHighlight === "R") {
                    //rok
                    rokBuying = mnozstviHiglight;
                    rokBuying = rokBuying < 0 ? 0 : rokBuying;
                    mnozstviHiglight = rokBuying;

                    if (thisMonthTotal < yearValue) {
                        //maximalka musi rozdelit rok
                        rokof = yearValue - thisMonthTotal;
                        yearValue = thisMonthTotal;
                        rokBuyingOF = rokBuying;
                        rokBuying = 0;
                        ctvrtletiof = quarterValue;
                        quarterValue = 0;
                        mesicof = monthValue;
                        monthValue = 0;
                    } else if (thisMonthTotal < yearValue + mnozstviHiglight) {
                        rokBuyingOF = yearValue + mnozstviHiglight - thisMonthTotal;
                        rokBuying = thisMonthTotal - yearValue;
                        ctvrtletiof = quarterValue;
                        quarterValue = 0;
                        mesicof = monthValue;
                        monthValue = 0;
                    } else if (
                        thisMonthTotal <
                        yearValue + mnozstviHiglight + quarterValue
                    ) {
                        //maximalka musi rozdelit quarter
                        ctvrtletiof =
                            yearValue + mnozstviHiglight + quarterValue - thisMonthTotal;
                        quarterValue = thisMonthTotal - (yearValue + mnozstviHiglight);
                        mesicof = monthValue;
                        monthValue = 0;
                    } else {
                        //maximalka musi rozdelit mesic
                        mesicof =
                            yearValue +
                            mnozstviHiglight +
                            quarterValue +
                            monthValue -
                            thisMonthTotal;
                        monthValue =
                            thisMonthTotal - yearValue - mnozstviHiglight - quarterValue;
                        rokBuyingOF = 0;
                        mesicBuyingOF = rokBuying;
                        rokBuying = 0;
                    }
                } else if (whatToHighlight === "Q") {
                    ctvrtletiBuying = mnozstviHiglight;
                    ctvrtletiBuying = ctvrtletiBuying < 0 ? 0 : ctvrtletiBuying;

                    //mnozstviHiglight = ctvrtletiBuying;
                    if (thisMonthTotal < yearValue) {
                        //maximalka musi rozdelit rok
                        rokof = yearValue - thisMonthTotal;
                        yearValue = thisMonthTotal;
                        ctvrtletiBuyingOF = mnozstviHiglight;
                        ctvrtletiBuying = 0;
                        mesicof = monthValue;
                        monthValue = 0;
                        ctvrtletiof = quarterValue;
                        quarterValue = 0;
                    } else if (thisMonthTotal < yearValue + quarterValue) {
                        //maximalka musi rozdelit quarter
                        ctvrtletiof = yearValue + quarterValue - thisMonthTotal;
                        quarterValue = thisMonthTotal - yearValue;
                        ctvrtletiBuyingOF = mnozstviHiglight;
                        ctvrtletiBuying = 0;
                        mesicof = monthValue;
                        monthValue = 0;
                    } else if (
                        thisMonthTotal <
                        yearValue + quarterValue + mnozstviHiglight
                    ) {
                        ctvrtletiBuyingOF =
                            yearValue + mnozstviHiglight + quarterValue - thisMonthTotal;
                        ctvrtletiBuying = thisMonthTotal - yearValue - quarterValue;
                        mesicof = monthValue;
                        monthValue = 0;
                    } else {
                        //maximalka musi rozdelit mesic
                        mesicof =
                            yearValue +
                            mnozstviHiglight +
                            quarterValue +
                            monthValue -
                            thisMonthTotal;
                        monthValue =
                            thisMonthTotal - yearValue - quarterValue - mnozstviHiglight;
                        ctvrtletiBuyingOF = 0;
                        mesicBuyingOF = mnozstviHiglight;
                        ctvrtletiBuying = 0;
                    }
                } else {
                    mesicBuying = mnozstviHiglight;
                    mesicBuying = mesicBuying < 0 ? 0 : mesicBuying;
                    //mnozstviHiglight = mesicBuying;
                    if (thisMonthTotal < yearValue) {
                        //maximalka musi rozdelit rok
                        rokof = yearValue - thisMonthTotal;
                        yearValue = thisMonthTotal;
                        mesicBuyingOF = mnozstviHiglight;
                        mesicBuying = 0;
                        mesicof = monthValue;
                        monthValue = 0;
                        ctvrtletiof = quarterValue;
                        quarterValue = 0;
                    } else if (thisMonthTotal < yearValue + quarterValue) {
                        //maximalka musi rozdelit quarter
                        ctvrtletiof = yearValue + quarterValue - thisMonthTotal;
                        quarterValue = thisMonthTotal - yearValue;
                        mesicBuyingOF = mnozstviHiglight;
                        mesicBuying = 0;
                        mesicof = monthValue;
                        monthValue = 0;
                    } else if (thisMonthTotal < yearValue + quarterValue + monthValue) {
                        //maximalka musi rozdelit mesic
                        mesicof = yearValue + quarterValue + monthValue - thisMonthTotal;
                        monthValue = thisMonthTotal - yearValue - quarterValue;
                        mesicBuyingOF = mnozstviHiglight;
                        mesicBuying = 0;
                    } else if (
                        thisMonthTotal <
                        yearValue + quarterValue + monthValue + mnozstviHiglight
                    ) {
                        mesicBuyingOF =
                            yearValue +
                            mnozstviHiglight +
                            quarterValue +
                            monthValue -
                            thisMonthTotal;
                        mesicBuying =
                            thisMonthTotal - yearValue - quarterValue - monthValue;
                    }
                }
            } else {
                if (thisMonthTotal < yearValue) {
                    //maximalka musi rozdelit rok
                    rokof = yearValue - thisMonthTotal;
                    yearValue = thisMonthTotal;
                    ctvrtletiof = quarterValue;
                    quarterValue = 0;
                    mesicof = monthValue;
                    monthValue = 0;
                } else if (thisMonthTotal < yearValue + quarterValue) {
                    //maximalka musi rozdelit quarter
                    ctvrtletiof = yearValue + quarterValue - thisMonthTotal;
                    quarterValue = thisMonthTotal - yearValue;
                    mesicof = monthValue;
                    monthValue = 0;
                } else {
                    //maximalka musi rozdelit mesic
                    mesicof = yearValue + quarterValue + monthValue - thisMonthTotal;
                    monthValue = thisMonthTotal - yearValue - quarterValue;
                }
            }
        }

        let lineFinal = lineHighlightSize;
        return {
            name: name,
            rok: yearValue,
            rokBuying: rokBuying,
            ctvrtleti: quarterValue,
            ctvrtletiBuying: ctvrtletiBuying,
            mesic: monthValue,
            mesicBuying: mesicBuying,
            zbytekdoceleho: zbytek,
            redline: lineFinal,
            rokOF: rokof,
            rokBuyingOF: rokBuyingOF,
            ctvrtletiOF: ctvrtletiof,
            ctvrtletiBuyingOF: ctvrtletiBuyingOF,
            mesicOF: mesicof,
            mesicBuyingOF: mesicBuyingOF,
            puvodniZbytek: thisMonthTotal,
        };
    }

    useEffect(() => {
        if (props.activeIndex !== undefined) {
            setActiveIndexSetter(props.activeIndex);
        }

        const newDataMini: MonthData[] = [];
        setNewData(undefined);

        let yearly = 0;
        let monthly = 0;
        let quarterly = 0;
        let original = 0;
        let totalBought = 0;
        let buying = 0;

        for (let i = 0; i < months.length; i++) {
            const monthData = createMonthData(
                "" + (i + 1),
                i,
                props.activeIndex ?? 0,
                props.graphHighlight
            );
            newDataMini.push(monthData);
            yearly = yearly + monthData.rok + monthData.rokOF;
            monthly = monthly + monthData.mesic + monthData.mesicOF;
            quarterly = quarterly + monthData.ctvrtleti + monthData.ctvrtletiOF;
            original = original + monthData.puvodniZbytek;
            totalBought =
                totalBought +
                monthData.rok +
                monthData.mesic +
                monthData.ctvrtleti +
                monthData.rokOF +
                monthData.mesicOF +
                monthData.ctvrtletiOF;
            buying =
                buying +
                monthData.ctvrtletiBuying +
                monthData.mesicBuying +
                monthData.rokBuying +
                monthData.ctvrtletiBuyingOF +
                monthData.mesicBuyingOF +
                monthData.rokBuyingOF;
        }

        setGraphInfo({
            original: original,
            totalBought: totalBought,
            monthly: monthly,
            quarterly: quarterly,
            yearly: yearly,
            buying: buying,
        });

        let redlineTotal = 0;
        let line = 0.5;
        let lineFinal = 0.1;
        for (let i = 0; i < months.length; i++) {
            let totalis =
                newDataMini[i].rok +
                newDataMini[i].mesic +
                newDataMini[i].ctvrtleti +
                newDataMini[i].rokOF +
                newDataMini[i].mesicOF +
                newDataMini[i].ctvrtletiOF +
                newDataMini[i].zbytekdoceleho +
                newDataMini[1].ctvrtletiBuying +
                newDataMini[1].mesicBuying +
                newDataMini[1].rokBuying +
                newDataMini[1].ctvrtletiBuyingOF +
                newDataMini[1].mesicBuyingOF +
                newDataMini[1].mesicBuyingOF;

            if (redlineTotal < totalis) {
                redlineTotal = totalis;
            }
        }

        for (let i = 0; i < months.length; i++) {
            lineFinal = (line / 100) * redlineTotal;

            newDataMini[i].redline = lineFinal;
        }

        setLineHighlightSize(lineFinal);
        setNewData(newDataMini);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.envelope,
        props.activeIndex,
        props.graphHighlight,
        props.formulaTrancheType,
    ]);

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            let total = payload[0].payload.puvodniZbytek;
            let totalBought =
                payload[0].payload.rok +
                payload[0].payload.mesic +
                payload[0].payload.ctvrtleti +
                payload[0].payload.rokOF +
                payload[0].payload.mesicOF +
                payload[0].payload.ctvrtletiOF;
            let rightNowBuying =
                payload[0].payload.ctvrtletiBuying +
                payload[0].payload.mesicBuying +
                payload[0].payload.rokBuying +
                payload[0].payload.ctvrtletiBuyingOF +
                payload[0].payload.mesicBuyingOF +
                payload[0].payload.rokBuyingOF;

            return (
                <div className="custom-tooltip">
                    <p className="label">{`${months[label - 1]} ${props.envelope.purchaseEnvYear
                        }`}</p>
                    <div className={"info-center"}>
                        <div className={"original"}>
                            <span></span> {activeIndexSetter === 0 ? "Sjednaný" : "Zpřesněný"}{" "}
                            diagram{" "}
                            <p>
                                {total
                                    .toFixed(conf.REACT_APP_DECIMALS)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                    .replace(".", ",")}{" "}
                                MWh
                            </p>
                        </div>
                        <div className={"celkem"}>
                            Celkem nakoupeno:{" "}
                            <p>
                                {totalBought
                                    .toFixed(conf.REACT_APP_DECIMALS)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                    .replace(".", ",")}{" "}
                                MWh
                            </p>
                        </div>
                        <div className={"monthly"}>
                            <span></span> Měsíční produkt:{" "}
                            <p>
                                {(payload[0].payload.mesic + payload[0].payload.mesicOF)
                                    .toFixed(conf.REACT_APP_DECIMALS)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                    .replace(".", ",")}{" "}
                                MWh (
                                {getPercents(
                                    totalBought,
                                    payload[0].payload.mesic + payload[0].payload.mesicOF
                                )}{" "}
                                %)
                            </p>
                        </div>
                        <div className={"quarterly"}>
                            <span></span> Čtvrtletní produkt:{" "}
                            <p>
                                {(payload[0].payload.ctvrtleti + payload[0].payload.ctvrtletiOF)
                                    .toFixed(conf.REACT_APP_DECIMALS)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                    .replace(".", ",")}{" "}
                                MWh (
                                {getPercents(
                                    totalBought,
                                    payload[0].payload.ctvrtleti + payload[0].payload.ctvrtletiOF
                                )}{" "}
                                %)
                            </p>
                        </div>
                        <div className={"yearly"}>
                            <span></span> Roční produkt:{" "}
                            <p>
                                {(payload[0].payload.rok + payload[0].payload.rokOF)
                                    .toFixed(conf.REACT_APP_DECIMALS)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                    .replace(".", ",")}{" "}
                                MWh (
                                {getPercents(
                                    totalBought,
                                    payload[0].payload.rok + payload[0].payload.rokOF
                                )}{" "}
                                %)
                            </p>
                        </div>
                        <div className={"nowShopping"}>
                            <span></span> Právě nakupujete:{" "}
                            <p>
                                {rightNowBuying
                                    .toFixed(conf.REACT_APP_DECIMALS)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                    .replace(".", ",")}{" "}
                                MWh ({getPercents(total, rightNowBuying)} %)
                            </p>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <ResponsiveContainer
                width="100%"
                height="100%"
                minHeight="450px"
                maxHeight={450}
            >
                <BarChart
                    width={100}
                    height={100}
                    data={newData}
                    margin={{
                        top: 0,
                        right: 20,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <XAxis
                        dataKey="name"
                        interval={0}
                        style={{
                            fontSize: "12px",
                        }}
                    />
                    <YAxis
                        label={{
                            value: "[MWh]",
                            position: "insideTopLeft",
                            angle: 0,
                            dy: 0,
                            dx: 0,
                        }}
                        padding={{ top: 35 }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="rok" stackId="a" barSize={30} fill="#7abe5f" />
                    <Bar dataKey="rokBuying" stackId="a" barSize={30} fill="#429CDA" />
                    <Bar dataKey="ctvrtleti" stackId="a" barSize={30} fill="#3f7665" />
                    <Bar
                        dataKey="ctvrtletiBuying"
                        stackId="a"
                        barSize={30}
                        fill="#429CDA"
                    />
                    <Bar dataKey="mesic" stackId="a" barSize={30} fill="#17301c" />
                    <Bar dataKey="mesicBuying" stackId="a" barSize={30} fill="#429CDA" />
                    <Bar
                        dataKey="zbytekdoceleho"
                        stackId="a"
                        barSize={30}
                        fill="#d7d8d7"
                    />
                    <Bar dataKey="redline" stackId="a" barSize={30} fill="#ff0000" />
                    <Bar dataKey="rokOF" stackId="a" barSize={30} fill="#7abe5f" />
                    <Bar dataKey="rokBuyingOF" stackId="a" barSize={30} fill="#429CDA" />
                    <Bar dataKey="ctvrtletiOF" stackId="a" barSize={30} fill="#3f7665" />
                    <Bar
                        dataKey="ctvrtletiBuyingOF"
                        stackId="a"
                        barSize={30}
                        fill="#429CDA"
                    />
                    <Bar dataKey="mesicOF" stackId="a" barSize={30} fill="#17301c" />
                    <Bar
                        dataKey="mesicBuyingOF"
                        stackId="a"
                        barSize={30}
                        fill="#429CDA"
                    />
                </BarChart>
            </ResponsiveContainer>
            <div className="graphInfo">
                <div className={"info-center"}>
                    <p className="label">{`Celý rok ${props.envelope.purchaseEnvYear}`}</p>
                    <div className={"original"}>
                        <span></span> {activeIndexSetter === 0 ? "Sjednaný" : "Zpřesněný"}{" "}
                        diagram{" "}
                        <p>
                            {graphInfo.original
                                .toFixed(conf.REACT_APP_DECIMALS)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                .replace(".", ",")}{" "}
                            MWh
                        </p>
                    </div>
                    <div className={"celkem"}>
                        Celkem nakoupeno:{" "}
                        <p>
                            {graphInfo.totalBought
                                .toFixed(conf.REACT_APP_DECIMALS)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                .replace(".", ",")}{" "}
                            MWh
                        </p>
                    </div>
                    <div className={"monthly"}>
                        <span></span> Měsíční produkt:{" "}
                        <p>
                            {graphInfo.monthly
                                .toFixed(conf.REACT_APP_DECIMALS)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                .replace(".", ",")}{" "}
                            MWh ({getPercents(graphInfo.totalBought, graphInfo.monthly)} %)
                        </p>
                    </div>
                    <div className={"quarterly"}>
                        <span></span> Čtvrtletní produkt:{" "}
                        <p>
                            {graphInfo.quarterly
                                .toFixed(conf.REACT_APP_DECIMALS)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                .replace(".", ",")}{" "}
                            MWh ({getPercents(graphInfo.totalBought, graphInfo.quarterly)} %)
                        </p>
                    </div>
                    <div className={"yearly"}>
                        <span></span> Roční produkt:{" "}
                        <p>
                            {graphInfo.yearly
                                .toFixed(conf.REACT_APP_DECIMALS)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                .replace(".", ",")}{" "}
                            MWh ({getPercents(graphInfo.totalBought, graphInfo.yearly)} %)
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

const getPercents = (total: number, piece: number): string => {
    const result = total === 0 ? 0 : piece / (total / 100);
    return result
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace(".", ",");
};

export default DetailGraph;
