import * as React from "react";
import { useEffect, useState } from "react";
import {
  Envelopes,
  purchaseCustomerList,
  PurchaseEnvelopeList,
} from "../../../entities/nakup/Envelopes";
import Heading from "../../../shared/widgets/Heading";
import Table from "./table/Table";

interface Props {
  year: number;
  data: Envelopes;
  shopAllowed: boolean;
  selectedCustomerId: string;
}

const PurchaseOffersList = (props: Props) => {
  const [filteredData, setFilteredData] = useState<purchaseCustomerList[]>([]);

  function filterDataByYear() {
    const filteredData = props.data.data.purchaseCustomerList.map(
      (customer: purchaseCustomerList) => ({
        ...customer,
        purchaseEnvelopeList: customer.purchaseEnvelopeList.filter(
          (envelope: PurchaseEnvelopeList) =>
            envelope.purchaseEnvYear === props.year
        ),
      })
    );

    return filteredData;
  }

  useEffect(() => {
    setFilteredData([...filterDataByYear()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.data.datumDotazu]);

  const filteredDataByCustomer = props.selectedCustomerId
    ? filteredData.filter(
        (customer: purchaseCustomerList) =>
          customer.compRegNumber === props.selectedCustomerId
      )
    : filteredData;

  const allListsEmpty = filteredDataByCustomer.every(
    (customer: purchaseCustomerList) =>
      customer.purchaseEnvelopeList.length === 0
  );

  if (allListsEmpty) {
    return null;
  }

  return (
    <section className="purchaseOffersList widget">
      <Heading content={`Nákup pro rok ${props.year}`} />
      <div className="widget-content">
        <Table
          content={filteredDataByCustomer}
          shopAllowed={props.shopAllowed}
        />
      </div>
    </section>
  );
};

export default PurchaseOffersList;
