import { useShopSlice } from "app/store/shop/useShopSlice";
import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectShopItem } from "../../app/store/shop/shopSlice";
import InfoWindow from "../../shared/features/infoWindow/InfoWindow";
import HeadingMain from "../../shared/pages/heading";
import OfferBuyProcess from "../../widgets/pages/nakup/OfferBuyProcess";
import ContractShopDetailCountdown from "./ContractShopDetailCountdown";
import ContractShopInfoWindow from "./ContractShopInfoWindow";
import InfoHeadingWithErrors from "shared/features/InfoHeadingWithErrors";

const ContractShopDetail = () => {
  const shopItem = useSelector(selectShopItem);

  const [newDateDo, setNewDateDo] = useState(
    new Date(shopItem.prices?.platnostDo ?? "")
  );
  const [shopAllowed, setShopAllowed] = React.useState<boolean>(false);

  // const [newDateDo, setNewDateDo] = useState(
  //   new Date(new Date().getTime() + 1 * 60000)
  // );

  const [error, setError] = useState(false);
  const { messagesReset, messagesUpdate } = useShopSlice();

  const breadcrumbsData = [
    // { name: "Domů", url: "/" },
    { name: "Nákupní zóna", url: "/nakup" },
    { name: "Detail skupiny", url: "/nakup/smlouva" },
    { name: "Nákup energií", url: "/nakup/smlouva/nakup-komodity" },
  ];

  // Vycisteni info hlasek nakupu
  useEffect(() => {
    messagesReset();
  }, [newDateDo]);

  const cas = newDateDo
    ? new Date(newDateDo).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
    : "??";

  const datum = newDateDo
    ? new Date(newDateDo).toLocaleDateString("cs-CZ", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : "??";

  function handleDateChange(date: Date): void {
    setNewDateDo(date);
  }

  const handleOnExpireCountdown = (expired: boolean) => {
    setError(expired);
  };

  function setShop(toggle: boolean): void {
    setShopAllowed(toggle);
  }

  return (
    <section className="main-body">
          <HeadingMain
        nadpis={
          "Nákup elektřiny pro nákupní skupinu " +
          shopItem.envelope?.purchaseEnvelopeId
        }
        crumbs={breadcrumbsData}
      />

      <InfoHeadingWithErrors
        setShopAllowed={setShop}
        // statusDne={envelopes?.data.statusDne}
        statusDne={undefined}
        additionalContent={
          error ? (
            <div>
              Obchodování v aktuální cenové hladině již není možné. <br />{" "}
              Možnost obchodovat je přerušena.
            </div>
          ) : (
            <div>
              <ContractShopDetailCountdown
                expiryTimestamp={newDateDo}
                onExpire={handleOnExpireCountdown}
              />
            </div>
          )
        }
      />

      {/* <InfoWindow
        // heading={
        //   error
        //     ? "Aktuálně dostupné ceny nejsou platné!"
        //     : `Aktuálně dostupné ceny jsou platné do ${cas} - ${datum}`
        // }
        type={error ? "warning" : "success"}
        content={
          error ? (
            <React.Fragment>
              Obchodování v aktuální cenové hladině již není možné. <br />{" "}
              Možnost obchodovat je přerušena.
            </React.Fragment>
          ) : (
            <ContractShopDetailCountdown
              expiryTimestamp={newDateDo}
              onExpire={handleOnExpireCountdown}
            />
          )
        }
      /> */}

      <ContractShopInfoWindow />

      <Container fluid className="px-0">
        <Row className="pb-4">
          <Col>
            <OfferBuyProcess
              navigation={["Sjednaný", "Zpřesněný"]}
              error={error}
              shopItem={shopItem}
              newDateToCountDown={handleDateChange}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContractShopDetail;
