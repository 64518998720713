import * as React from "react";
import { PlanList } from "../../../../entities/diagram/Diagrams";
import TableBodyRow from "./TableBodyRow";

interface Props {
    toggleUpload: (toggle: boolean, idNakupObalkaOcenovaneMisto: number, name: string, pid: string, year: number) => void;
    content: PlanList[];
}

const TableBody = (props: Props) => {
    function handleToggle(
        toggle: boolean,
        idNakupObalkaOcenovaneMisto: number,
        name: string,
        pid: string,
        year: number
    ): void {
        props.toggleUpload(toggle, idNakupObalkaOcenovaneMisto, name, pid, year);
    }

    return (
        <tbody>
            {props.content.map((customer: PlanList) => (
                <TableBodyRow
                    key={customer.compRegNumber}
                    content={customer}
                    toggleUpload={handleToggle}
                />
            ))}
        </tbody>
    );
};

export default TableBody;
