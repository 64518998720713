import * as React from "react";
import TableHeading from "./TableHeading";
import TableBody from "./TableBody";
import TableB from "react-bootstrap/Table";
import { purchaseCustomerList } from "../../../../entities/nakup/Envelopes";

interface Props {
    content: purchaseCustomerList[];
    shopAllowed: boolean;
}

const Table = (props: Props) => {
    return (
        <TableB className="basic-table" responsive="xl">
            <TableHeading
                content={[
                    "",
                    "Odběratel",
                    "Sjednaný plán",
                    "Zpřesněný plán",
                    "Nakoupeno",
                    "Zbývá nakoupit",
                    "",
                ]}
            />
            <TableBody content={props.content} shopAllowed={props.shopAllowed} />
        </TableB>
    );
};

export default Table;
