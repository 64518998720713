import * as React from "react";
import NavItem from "./NavItem";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import MenuModel from "./auth/MenuModel";
import nav from "./navigation.json";

interface Props {}

const Navigation = (props: Props) => {
  if (nav) {
    return (
      <nav>
        <ul>
          {JSON.parse(JSON.stringify(nav)).map((item: MenuModel) => (
            <NavItem key={item.id} item={item} icon={item.icon} />
          ))}
        </ul>
      </nav>
    );
  } else {
    return <></>;
  }
};

export default Navigation;
