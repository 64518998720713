import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useGetPlanPurchaseWithInfoQuery } from "app/api/main/planPurchase/planPurchaseWithInfoApi";
import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import CircleLoader from "shared/components/circleLoader/CircleLoader";
import { selectedUserItem } from "../../app/store/selectedUser/selectedUserSlice";
import { useSelectedUserSlice } from "../../app/store/selectedUser/useSelectedUserSlice";
import { Years } from "../../entities/diagram/Diagrams";
import { Envelopes } from "../../entities/nakup/Envelopes";
import InfoHeadingWithErrors from "../../shared/features/InfoHeadingWithErrors";
import HeadingMain from "../../shared/pages/heading";
import PurchaseOffersList from "../../widgets/pages/nakup/PurchaseOffersList";
import AppButton from "shared/components/AppButton/AppButton";
import { selectYearsItem } from "../../app/store/years/yearsSlice";
import { useYearsSlice } from "../../app/store/years/useYearsSlice";
import conf from "../../app/config/appConfig";

const HomePage = () => {
    const breadcrumbsData = [
        { name: "Nákupní zóna", url: "/nakup" },
    ];

    const { yearsUpdate } = useYearsSlice();
    const yearsFromState = useSelector(selectYearsItem);


    const selectedUserID = useSelector(selectedUserItem);
    const { selectedUserUpdate } = useSelectedUserSlice();
    const [selectedCustomerId, setSelectedCustomerId] = useState(
        selectedUserID.userid
    );
    const [shopAllowed, setShopAllowed] = useState<boolean>(false);
    const [rerender, setRerender] = useState<number>(0);

    const [years, setYears] = useState<Years>({
        od: yearsFromState?.od || conf.REACT_APP_DIAGRAM_FROM,
        do: yearsFromState?.do || conf.REACT_APP_DIAGRAM_TO,
    });
    const [yearsInp, setYearsInp] = useState<Years>({
        od: yearsFromState?.od || conf.REACT_APP_DIAGRAM_FROM,
        do: yearsFromState?.do || conf.REACT_APP_DIAGRAM_TO,
    });
    const { accessTokenPayload } = useOidcAccessToken();

    const { data: envelopesData, refetch: envelopesDataRefetch } =
        useGetPlanPurchaseWithInfoQuery({
            Email: accessTokenPayload.email,
            PeriodYearFrom: Number.parseInt(years.od),
            PeriodYearTo: Number.parseInt(years.do),
        });

    useEffect(() => {
        envelopesDataRefetch();
    }, [rerender]);

    function yearsControl() {
        var to = yearsInp.do;
        var from = yearsInp.od;
        var intFrom = parseInt(yearsInp.od) || 0;
        var intTo = parseInt(yearsInp.do) || 0;

        if (intTo < intFrom) {
            to = "" + (intFrom + 1);
            intTo = intFrom + 1;
        }

        if (intFrom < 2000) {
            from = "2000";
            intFrom = 2000;
        }

        if (intFrom > 2099) {
            from = "2099";
        }

        if (intTo > 2099) {
            to = "2099";
        }

        setYearsInp({ od: from, do: to });
        setYears({ od: from, do: to });
        setRerender(rerender + 1);
    }

    const generateYearRange = () => {
        const startYear = parseInt(years.od);
        const endYear = parseInt(years.do);
        const yearRange = [];

        for (let year = startYear; year <= endYear; year++) {
            yearRange.push(year);
        }

        return yearRange;
    };

    function getUniqueCustomers(
        envelopes: Envelopes | undefined
    ): { compRegNumber: string; name: string }[] {
        const uniqueCustomers: { compRegNumber: string; name: string }[] = [];

        if (envelopes && envelopes.data && envelopes.data.purchaseCustomerList) {
            const customerMap: Map<string, string> = new Map();

            for (const customer of envelopes.data.purchaseCustomerList) {
                if (!customerMap.has(customer.compRegNumber)) {
                    customerMap.set(customer.compRegNumber, customer.name);
                    uniqueCustomers.push({
                        compRegNumber: customer.compRegNumber,
                        name: customer.name,
                    });
                }
            }
        }

        return uniqueCustomers;
    }

    const renderBody = (pEnvelopes?: Envelopes) => {
        if (!pEnvelopes) return <CircleLoader />;

        if (pEnvelopes.data.purchaseCustomerList.length === 0) {
            return (
                <section className="purchaseOffersList widget">
                    <div className="widget-content">
                        <b>Nemáte žádný  produkt pro postupný nákup</b>
                    </div>
                </section>
            );
        }

        const yearRange = generateYearRange();
        const diagramElements = yearRange.map((year) => (
            <Row className="pb-4" key={year}>
                <Col>
                    <PurchaseOffersList
                        year={year}
                        data={pEnvelopes}
                        selectedCustomerId={selectedCustomerId}
                        shopAllowed={shopAllowed}
                    />
                </Col>
            </Row>
        ));

        return diagramElements;
    };

    const handleOdOnChange = (e: any) => {
        setYearsInp({ od: e.target.value, do: years.do });

        yearsUpdate({
            od: e.target.value,
            do: years.do,
        });
    }

    const handleDoOnChange = (e: any) => {
        setYearsInp({ od: years.od, do: e.target.value });
        yearsUpdate({
            od: years.od,
            do: e.target.value,
        });
    }

    function setShop(toggle: boolean): void {
        setShopAllowed(toggle);
    }

    const handleCustomerSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCustomerId(e.target.value);
        selectedUserUpdate({
            userid: e.target.value,
        });
    };

    return (
        <section className="main-body">
            <HeadingMain nadpis="Nákupní zóna" crumbs={breadcrumbsData} />

            <InfoHeadingWithErrors
                setShopAllowed={setShop}
                statusDne={envelopesData?.data.statusDne}
            />
            <div className="header-filters">
                <div className="client-filter-wrap">
                    <div className="client-filter">
                        <span>Filtrovat dle zákazníka: </span>
                        <Form.Select
                            onChange={handleCustomerSelect}
                            value={selectedCustomerId}
                        >
                            <option value="">Vyberte zákazníka</option>
                            {getUniqueCustomers(envelopesData).map((customer) => (
                                <option
                                    key={customer.compRegNumber}
                                    value={customer.compRegNumber}
                                >
                                    {customer.name}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                </div>

                <div className="year-picker-wrap">
                    <div className="year-picker">
                        Od:{" "}
                        <Form.Control
                            onChange={handleOdOnChange}
                            type="number"
                            min={2000}
                            max={2099}
                            step={1}
                            value={yearsInp.od}
                        />
                    </div>

                    <div className="year-picker">
                        Do:{" "}
                        <Form.Control
                            onChange={handleDoOnChange}
                            type="number"
                            min={2000}
                            max={2099}
                            step={1}
                            value={yearsInp.do}
                        />
                    </div>

                    {/* <button className="basic-button" onClick={() => yearsControl()}>
            Zobrazit aaaa
          </button> */}
                    <AppButton onClick={() => yearsControl()}>Zobrazit</AppButton>
                </div>
            </div>
            {renderBody(envelopesData)}
        </section>
    );
};

export default HomePage;
