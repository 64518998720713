import { BuyTranche, Prices } from "entities/period/Prices";
import { mainBaseApi } from "../mainBaseApi";
import GetCenaParams from "./GetCenaParams";

const endpointUrl = "Tranche";

const priceApi = mainBaseApi.injectEndpoints({
  endpoints: (build) => ({
    getCena: build.query<Prices | null, GetCenaParams | null>({
      query: (params) => ({
        url: `${endpointUrl}/GetCena`,
        method: "GET",
        params: {
          RokOd: params?.rokOd,
          RokDo: params?.rokDo,
        },
      }),
    }),
    buy: build.mutation<any, BuyTranche>({
      query: (params: BuyTranche) => ({
        url: `${endpointUrl}/Buy`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["GetPlanPurchaseWithInfo"],
    }),
  }),
});

export const { useGetCenaQuery, useBuyMutation } = priceApi;
