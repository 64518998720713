import * as React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

interface Props {
    content: string;
    navigation?: string[] | null;
    xlxs?: boolean | null;
    filterOffers?: (filter: string) => void;
}

const Heading = (props: Props) => {
    const [activeIndex, setActiveIndex] = React.useState(0); // Aktivní index


    const handleButtonClick = (filter: string, index: number) => {
        if (props.filterOffers) {
            setActiveIndex(index);
            props.filterOffers(filter);
        }
    };

    return (
        <header className="widget-heading">
            <h2>{props.content}</h2>
            {props.navigation && (
                <ButtonGroup>
                    {props.navigation.map((item, index) => (
                        <Button
                            key={index}
                            variant="secondary"
                            onClick={() => handleButtonClick(item, index)}
                            className={index === activeIndex ? "active" : ""}
                        >
                            {item}
                        </Button>
                    ))}
                </ButtonGroup>
            )}

            {props.xlxs && (
                <button className="button-link">Stáhnout v xlxs</button>
            )}
        </header>
    );
};

export default Heading;
