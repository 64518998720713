import React, { useEffect, useState } from "react";

interface Props {
  handleChange: (file: File) => void;
  handleDrop: (file: File) => void;
  error: React.ReactNode;
}

const FileUpload = (props: Props) => {
  const [dragActive, setDragActive] = React.useState(false);
  const [fileName, setFileName] = useState<string>("");
  const [fileKey, setFileKey] = useState(Date.now());
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const files = e.dataTransfer?.files;
    if (files && files[0]) {
      const file = files[0];
      setFileName(file.name);
      props.handleChange(file);
    } else {
      setFileName("");
    }
  };

  useEffect(() => {
    if (props.error) {
      setFileName("");

      setFileKey(Date.now());
    }
  }, [props.error]);

  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFileName(file.name);
      props.handleChange(file);
    } else {
      setFileName("");
    }
  };

  return (
    <>
      <form
        className="form-file-upload mt-3"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <label
          className={
            dragActive ? "drag-active label-file-upload" : "label-file-upload"
          }
        >
          <input
            type="file"
            className="input-file-upload"
            multiple={false}
            onChange={handleChange}
            key={fileKey}
          />
          <div>
            {fileName !== "" ? (
              <p>{fileName}</p>
            ) : (
              <p>
                Sem přetáhněte soubor, nebo jej{" "}
                <span className="button-link black">vyberte z počítače</span>.
              </p>
            )}
          </div>
        </label>
        {dragActive && (
          <div
            className="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
    </>
  );
};

export default FileUpload;
