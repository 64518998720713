import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import AppState from "../AppState";
import EnvelopeNavigationItem from "entities/navigation/EnvelopeNavigationItem";

export interface NavigationState {
  envelopeNavigationItem: EnvelopeNavigationItem;
}

export const initialState: NavigationState = {
  envelopeNavigationItem: {
    envelope: null,
    firmaId: "",
    firmaNazev: "",
    statusDne: undefined,
  },
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    envelopeNavigationItemUpdate: (
      state,
      action: PayloadAction<Partial<EnvelopeNavigationItem>>
    ) => {
      state.envelopeNavigationItem = {
        ...state.envelopeNavigationItem,
        ...action.payload,
      };
    },
  },
});

export const actions = navigationSlice.actions;

export default navigationSlice.reducer;

// SELECTORS
export const selectEnvelopeNavigationItem = (state: AppState) =>
  state.navigation.envelopeNavigationItem;
