import { useEffect, useState } from "react";

export const usePodminky = (podminkyType: "aktualni" | "archiv") => {
  const [podminky, setPodminky] = useState<string[]>([]);

  useEffect(() => {
    const fetchPodminky = async () => {
      const response = await fetch(
        `/${process.env.REACT_APP_CLIENT}/podminky/${podminkyType}/list.json`
      );
      const data = await response.json();
      setPodminky(data);
    };

    fetchPodminky();
  }, [podminkyType]);

  return {
    podminky,
    setPodminky,
  };
};
