import { PeriodWithPrice } from "entities/period/PeriodWithPrice";
import { mainBaseApi } from "../mainBaseApi";
import PeriodGetParams from "./PeriodGetParams";

const endpointUrl = "Period";

const periodApi = mainBaseApi.injectEndpoints({
  endpoints: (build) => ({
    getPeriod: build.query<PeriodWithPrice | null, PeriodGetParams | null>({
      query: (query) => ({
        url: `${endpointUrl}/Get`,
        method: "GET",
        params: {
          Email: query?.email,
          PurchaseEnvelopeId: query?.purchaseEnvelopeId,
        },
      }),
    }),
  }),
});

export const { useGetPeriodQuery } = periodApi;
