import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DiagramItem } from "../../../entities/diagram/Diagrams";
import AppState from "../AppState";

export interface DiagramState {
    diagramItem: DiagramItem;
}

export const initialState: DiagramState = {
    diagramItem: {
        sucess: false,
        error: false,
  },
};

export const diagramSlice = createSlice({
  name: "diagram",
  initialState,
  reducers: {
    envelopeNavigationItemUpdate: (
      state,
      action: PayloadAction<Partial<DiagramItem>>
    ) => {
      state.diagramItem = {
          ...state.diagramItem,
        ...action.payload,
      };
    },
  },
});

export const actions = diagramSlice.actions;

export default diagramSlice.reducer;

// SELECTORS
export const selectDiagramItem = (state: AppState) =>
  state.diagram.diagramItem;
