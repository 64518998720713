import * as React from 'react';
import { purchaseCustomerList } from '../../../../entities/nakup/Envelopes';
import TableBodyRow from './TableBodyRow';

interface Props {
    content: purchaseCustomerList[];
    shopAllowed: boolean;
}

const TableBody = (props: Props) => {
    return (
        <tbody>
            {props.content.map((customer: purchaseCustomerList) => (
                <TableBodyRow key={customer.compRegNumber} content={customer} shopAllowed={props.shopAllowed} />
            ))}
        </tbody>
    );
};

export default TableBody;
