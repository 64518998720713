import { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { PurchaseEnvelopeList } from '../../../entities/nakup/Envelopes';
import Heading from '../../../shared/widgets/Heading';
import DetailGraph from './DetailGraph';

interface Props {
    envelope: PurchaseEnvelopeList;
    navigation: string[];
}

const OfferDetailGraph = (props: Props) => {
    const [activeIndex, setActiveIndex] = useState(0); // Aktivní index


    const handleButtonClick = (filter: string, index: number) => {
            setActiveIndex(index);        
    };

    return (
        <section className="OfferDetail widget">
            <Heading content={"Predikce spotřeby a přehled nákupů pro rok 2023"} />
            <div className="widget-content">
                <ButtonGroup>
                    {props.navigation.map((item, index) => (
                        <Button
                            key={index}
                            variant="secondary"
                            onClick={() => handleButtonClick(item, index)}
                            className={index === activeIndex ? "active" : ""}
                        >
                            {item}
                        </Button>
                    ))}
                </ButtonGroup>
                <DetailGraph envelope={props.envelope} activeIndex={activeIndex} />
            </div>
        </section>
    );
};

export default OfferDetailGraph;
