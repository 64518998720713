import FileList from "./FileList";
import { usePodminky } from "./usePodminky";

const Podminky = () => {
  const { podminky } = usePodminky("aktualni");

  return (
    <FileList
      files={podminky.map((podminka) => ({ name: podminka }))}
      heading={`Aktuálně platné podmínky používání`}
      path={`${process.env.REACT_APP_CLIENT}/podminky/aktualni/`}
    />
  );
};

export default Podminky;
