import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { periodListToSendItem } from "entities/period/Prices";
import AppState from "../AppState";

export interface PeriodState {
  periodsToSend: periodListToSendItem[];
}

export const initialState: PeriodState = {
  periodsToSend: [],
};

export const periodSlice = createSlice({
  name: "period",
  initialState,
  reducers: {
    periodsToSendSet: (
      state,
      action: PayloadAction<periodListToSendItem[]>
    ) => {
      state.periodsToSend = [...action.payload];
    },
  },
});

export const actions = periodSlice.actions;

export default periodSlice.reducer;

// SELECTORS
export const selectPeriodsToSend = (state: AppState) =>
  state.period.periodsToSend;
