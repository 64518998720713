import InfoWindow from "shared/features/infoWindow/InfoWindow";

interface Props {
  checkTrancheBuyResultMessages: any[];
}

const ModalBuyProcessInfoWindow = (props: Props) => {
  return (
    <>
      {props.checkTrancheBuyResultMessages.map((item) => {
        const type = item.purchaseEnvStatus === "OK" ? "success" : "warning";
        return <InfoWindow type={type} content={<>{item.description}</>} />;
      })}
    </>
  );
};

export default ModalBuyProcessInfoWindow;
