import { OidcSecure } from "@axa-fr/react-oidc";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import LayoutUnauthorized from "pages/LayoutUnauthorized";
import { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./app/layout/Layout";
import DiagramHome from "./pages/diagram/HomePage";
import PodminkyHome from "./pages/podminky/HomePage";
import ContractShopDetail from "./pages/nakup/ContractShopDetail";
import ContractShopList from "./pages/nakup/ContractShopList";
import NakupHome from "./pages/nakup/HomePage";
import conf from "app/config/appConfig";

function App() {
    const [isAuthHeader, setIsAuthHeader] = useState<boolean>(false);

    useEffect(() => {
        if (!isAuthHeader) return;

        if (!conf.REACT_APP_DEFAULT_START_ROUTE) return;

        if (window.location.pathname !== "/") return;

        if (conf.REACT_APP_DEFAULT_START_ROUTE === window.location.pathname) return;

        window.location.href =
            window.location.origin + conf.REACT_APP_DEFAULT_START_ROUTE;
    }, [isAuthHeader]);

    return (
        <>
            <OidcSecure>
                {isAuthHeader && (
                    <Routes>
                        <Route path="nakup" element={<Layout outlet={<Outlet />} />}>
                            <Route index element={<NakupHome />} />
                            <Route path=":envelopeId" element={<NakupHome />} />
                            <Route path="smlouva">
                                <Route index element={<ContractShopList />} />
                                <Route path="nakup-komodity" element={<ContractShopDetail />} />
                            </Route>
                        </Route>

                        <Route path="diagram" element={<Layout outlet={<Outlet />} />}>
                            {" "}
                            <Route index element={<DiagramHome />} />
                        </Route>

                        <Route path="podminky" element={<Layout outlet={<Outlet />} />}>
                            {" "}
                            <Route index element={<PodminkyHome />} />
                        </Route>
                    </Routes>
                )}
            </OidcSecure>

            <LayoutUnauthorized
                isAuthHeader={isAuthHeader}
                setIsAuthHeader={setIsAuthHeader}
            />
        </>
    );
}

export default App;
