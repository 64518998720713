import * as React from 'react';
import { TrancheBoughtList } from '../../../../entities/nakup/Envelopes';
import TableBodyRow from './TableBodyRow';

interface Props {
    history: TrancheBoughtList[];
    year: number;
}

const TableBody = (props: Props) => {

    if (!Array.isArray(props.history)) {
        return null; // or handle the error condition appropriately
    }

    return (
        <tbody>
            {props.history.map((item, index) => (
                <TableBodyRow
                    key={index}
                    content={item} // p�izp�sobte si form�t data podle va�ich pot�eb
                    cancelled={item.purchaseStatus === "Storno" ? true : false}
                    offline={false}
                    year={props.year}
                />
            ))}
        </tbody>
    );
};

export default TableBody;
