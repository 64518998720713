import * as React from "react";

interface Props {
  content: string[];
  sort: (sort: string) => void;
}

const TableHeadingRow = (props: Props) => {
  const { content } = props;
  const modifiedContent = content.map((heading) =>
    heading.replace("/", "<br>")
  );

  const handleOnClickHeading = (heading: string) => {
    props.sort(heading);
  };

  return (
    <tr>
      {modifiedContent.map((heading, index) => (
        <th
          key={index}
          dangerouslySetInnerHTML={{ __html: heading }}
          onClick={() => handleOnClickHeading(heading)}
        ></th>
      ))}
    </tr>
  );
};

export default TableHeadingRow;
