import { selectAuth } from "app/store/auth/authSlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTimer } from "react-timer-hook";
import { zeroPad } from "shared/helpers/numberHelpers";

interface Props {
  onExpire: () => void;
}

const PinCountdown = (props: Props) => {
  const auth = useSelector(selectAuth);

  const [expiryTimestamp, setExpiryTimestamp] = useState<string>("");

  useEffect(() => {
    if (auth.authModel.pinVerifiedTimestamp === "") return;

    getTimestamp();
  }, [auth.authModel.pinVerifiedTimestamp]);

  const getTimestamp = () => {
    var time = new Date(auth.authModel.pinVerifiedTimestamp);
    time.setMinutes(
      time.getMinutes() + auth.authModel.pinValidityDurationInMinutes
    );

    if (isNaN(Date.parse(auth.authModel.pinVerifiedTimestamp))) {
      return;
    }

    if (time) {
      setExpiryTimestamp(time.toISOString());
    }
  };

  const { seconds, minutes, isRunning, start, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: props.onExpire,
  });

  useEffect(() => {
    restart(new Date(expiryTimestamp));
  }, [expiryTimestamp]);

  return (
    <>
      {/* {expiryTimestamp !== "" && <>Platnost kodu vyprší za</>}

      {isRunning && (
        <>
          {" "}
          {minutes}:{zeroPad(seconds, 2)}.
        </>
      )}

      {!isRunning && <p style={{ color: "red" }}>Platnost kódu vypršela.</p>} */}
    </>
  );
};

export default PinCountdown;
