import * as React from "react";
import { selectMessages } from "app/store/shop/shopSlice";
import { useSelector } from "react-redux";
import InfoWindow from "../../shared/features/infoWindow/InfoWindow";
import InfoWindowModel from "shared/features/infoWindow/InfoWindowModel";
import { useExternalLog } from "shared/features/externalLog/useExternalLog";
import { useEffect } from "react";
import conf from "app/config/appConfig";

interface Props {
    onMessagesSet?: (messages: InfoWindowModel[]) => void;
}

const ContractShopInfoWindow = (props: Props) => {
    const messages = useSelector(selectMessages);
    const [tempMessages, setTempMessages] = React.useState<InfoWindowModel[]>([]);

    useEffect(() => {
        debugger;
        if (messages.length > 0) {
            setTempMessages(messages);
            props.onMessagesSet?.(messages);
        }
    }, [messages.length]);

    return (
        <>
            {tempMessages?.map((message: InfoWindowModel) => {
                if (!message.code || message.code === "basic") {
                    return (
                        <InfoWindow
                            heading={message.heading}
                            type={message.type}
                            content={<>{message.content}</>}
                        />
                    );
                }

                if (message.code === "nakoupeno") {
                    const content = message.content?.split("|");
                    const phoneNumber = conf.REACT_APP_TEL;
                    const phoneNumberWithoutSpaces = phoneNumber
                        ? phoneNumber.replace(/\s/g, "")
                        : "";

                    return (
                        <InfoWindow
                            heading={message.heading}
                            type={message.type}
                            content={
                                <>
                                    Potvrzujeme Vaši objednávku elektřiny pro období{" "}
                                    <b>{content?.[0]}</b> v množství{" "}
                                    <b>
                                        {content?.[1]} MWh / {content?.[2]} MW
                                        {message.trancheType === "BaseLoad" ? null : (
                                            <>
                                                {" "}/{" "}{content?.[3]}% spotřeby
                                            </>
                                        )}
                                    </b>
                                    .<br /> Souhrn Vaši objednávky zasíláme na Váš kontaktní email. Pro více informací nás kontaktujte na telefonu{" "}
                                    <b>
                                        <a href={"tel:" + phoneNumberWithoutSpaces}>
                                            {phoneNumber}
                                        </a>
                                    </b>{" "}
                                    nebo nám napište{" "}
                                    <a href={"mailto:" + conf.REACT_APP_MAIL}>
                                        <b>{conf.REACT_APP_MAIL}</b>.
                                    </a>
                                </>
                            }
                        />
                    );
                }
            })}
        </>
    );
};

export default ContractShopInfoWindow;
