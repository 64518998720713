import { useOidcAccessToken } from "@axa-fr/react-oidc";
import conf from "app/config/appConfig";
import { selectAuth } from "app/store/auth/authSlice";
import { useAuthSlice } from "app/store/auth/useAuthSlice";
import { store } from "app/store/store";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePin } from "shared/helpers/numberHelpers";

export const usePin = (
  pPinValidityDurationInMinutes?: number,
  onPinChanged?: (pin: String) => void
) => {
  const { accessTokenPayload } = useOidcAccessToken();

  const auth = useSelector(selectAuth);

  const [pinExpired, setPinExpired] = useState<boolean>(true);

  const [pinVerified, setPinVerified] = useState<boolean>(true);

  const [sendingPin, setSendingPin] = useState<boolean>(false);

  const [pinValidityDurationInMinutes, setPinValidityDurationInMinutes] =
    useState<number>(
      pPinValidityDurationInMinutes ??
        auth.authModel.pinValidityDurationInMinutes
    );

  const { authUpdate } = useAuthSlice();

  const checkIsExpired = (targetDate: Date) => {
    const currentDate = new Date();
    const expiredDate = new Date(
      currentDate.getTime() - pinValidityDurationInMinutes * 60000
    );

    return targetDate <= expiredDate;
  };

  const verifyPin = (pin: string) => {
    if (auth.authModel.pinCreatedTimestamp === "") return false;

    const verified = pin === auth.authModel.pin;

    if (verified) {
      authUpdate({ pinVerifiedTimestamp: new Date().toISOString() });
    }

    setPinVerified(verified);
    return verified;
  };

  const sendPin = async (pin: string) => {
    setSendingPin(true);
    let url = conf.REACT_APP_API_URL;
    try {
      console.log("Sending pin", pin);
      await axios.post(url + "Email/Send", {
        emailMessage: {
          subject: "Nákupní portál - PIN",
          to: [accessTokenPayload.email],
          body: `<p>Vážený zákazníku, <br /> vygenerovaný PIN kód pro vstup do Nákupní zóny je ${pin}. <br /> Děkujeme Vám za využívání našich služeb. <br /> Sev.en Industry Supply a.s.</p><p><div>----------------------------------------------</div><div>Na tento e-mail neodpovídejte, byl vygenerován automaticky ze systému.</div></p>`,
          isBodyHtml: true,
        },
      });
    } catch (error) {
      // Zpracování chyby
      console.log(error);
    }
    setSendingPin(false);
  };

  const sendNewPin = async () => {
    const newPin = generatePin().toString();
    const newCreatedTimestamp = new Date().toISOString();

    authUpdate({
      pin: newPin,
      pinVerifiedTimestamp: "",
      pinCreatedTimestamp: newCreatedTimestamp,
    });
    await sendPin(newPin);
  };

  const checkStatus = async () => {
    if (sendingPin) return;
    const state = store.getState();
    const newCreatedTimestamp = new Date().toISOString();
    const newPin = generatePin().toString();

    // Jeste nebyl vytvoren
    if (state.auth.authModel.pinCreatedTimestamp === "") {
      // authUpdate({
      //   pin: newPin,
      //   pinValidityDurationInMinutes: pinValidityDurationInMinutes,
      //   pinCreatedTimestamp: newCreatedTimestamp,
      // });

      setPinExpired(false);
      setPinVerified(false);

      // await sendPin(newPin);
      return;
    }

    // Jeste nevyprsel
    const pinVerifiedDate = new Date(state.auth.authModel.pinVerifiedTimestamp);
    if (
      state.auth.authModel.pinVerifiedTimestamp !== "" &&
      !checkIsExpired(pinVerifiedDate)
    ) {
      setPinVerified(true);
      return;
    }

    // Vyprsel
    setPinExpired(true);
    setPinVerified(false);

    if (state.auth.authModel.pinVerifiedTimestamp === "") return;

    // authUpdate({
    //   pin: newPin,
    //   pinVerifiedTimestamp: "",
    //   pinValidityDurationInMinutes: pinValidityDurationInMinutes,
    //   pinCreatedTimestamp: newCreatedTimestamp,
    // });
    // await sendPin(newPin);
  };

  useEffect(() => {
    if (
      pinValidityDurationInMinutes ===
      auth.authModel.pinValidityDurationInMinutes
    )
      return;

    setPinValidityDurationInMinutes(
      auth.authModel.pinValidityDurationInMinutes
    );
  }, [auth.authModel.pinValidityDurationInMinutes]);

  // Zmena pinu
  useEffect(() => {
    if (auth.authModel.pin === "") return;

    onPinChanged?.(auth.authModel.pin);
  }, [auth.authModel.pin]);

  // Nastaveni / Kontrola platnosti pinu
  useEffect(() => {
    checkStatus();
  }, []);

  return {
    pinExpired,
    verifyPin,
    pinVerified,
    checkStatus,
    sendNewPin,
  };
};
