import Navigation from "../../shared/menu/Navigation";

interface Props {
  toggler: boolean;
}

const Sidebar = (props: Props) => {
  return (
    <aside className={props.toggler ? "sideBar smaller" : "sideBar"}>
      <Navigation />
    </aside>
  );
};

export default Sidebar;
