import { Action, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ShopItem } from "../../../entities/nakup/Envelopes";
import AppState from "../AppState";
import InfoWindowModel from "shared/features/infoWindow/InfoWindowModel";

export interface ShopState {
  shopItem: ShopItem;
  messages: InfoWindowModel[];
}

export const initialState: ShopState = {
  shopItem: {
    nadpis: "",
    itemData: undefined,
    prices: undefined,
    envelope: undefined,
    kurz: 0,
    perioda: undefined,
    periody: [],
    pricesWithData: null,
        periodWithPrice: null,
        pricesAll: undefined,
  },
  messages: [],
};

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    shopItemUpdate: (state, action: PayloadAction<Partial<ShopItem>>) => {
      state.shopItem = {
        ...state.shopItem,
        ...action.payload,
      };
    },
    messagesUpdate: (state, action: PayloadAction<InfoWindowModel>) => {
      let newMessages: InfoWindowModel[] = [];
      if (state.messages !== undefined && state.messages.length > 0) {
        newMessages = [...state.messages];
      }

      newMessages.push(action.payload);

      state.messages = newMessages;
    },
    messagesReset: (state, action: Action) => {
      state.messages = [];
    },
  },
});

export const actions = shopSlice.actions;

export default shopSlice.reducer;

// SELECTORS
export const selectShopItem = (state: AppState) => state.shop.shopItem;

export const selectMessages = (state: AppState) => state.shop.messages;
