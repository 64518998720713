import { useDispatch } from "react-redux";
import { SelectedUser } from "../../../entities/diagram/Diagrams";
import { actions } from "./selectedUserSlice";

export const useSelectedUserSlice = () => {
  const dispatch = useDispatch();

    const selectedUserUpdate = (
        selectedUserItem: Partial<SelectedUser>
    ) => {
        dispatch(actions.selectedUserUpdate(selectedUserItem));
  };

  return {
      selectedUserUpdate,
  };
};
