import { useDispatch } from "react-redux";
import { actions } from "./authSlice";
import AuthModel from "./AuthModel";

export const useAuthSlice = () => {
  const dispatch = useDispatch();

  const authUpdate = (authModel: Partial<AuthModel>) => {
    dispatch(actions.authUpdate(authModel));
  };
  return {
    authUpdate,
  };
};
