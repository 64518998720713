import conf from "app/config/appConfig";

export const oidcConfig = {
  client_id: conf.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: window.location.origin + "/authentication/callback",
  silent_redirect_uri:
    window.location.origin + "/authentication/silent-callback",
  scope: conf.REACT_APP_OIDC_SCOPE, // offline_access scope allow your client to retrieve the refresh_token
  authority: conf.REACT_APP_OIDC_AUTHORITY,
  service_worker_only: false,
  loadUserInfo: true,
};
