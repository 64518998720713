import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { mainBaseApi } from "app/api/main/mainBaseApi";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from "redux-persist";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import storage from "redux-persist/lib/storage";
import AppState from "./AppState";
import authReducer from "./auth/authSlice";
import diagramReducer from "./diagram/diagramSlice";
import menuReducer from "./menuSlice";
import navigationReducer from "./navigation/navigationSlice";
import periodReducer from "./period/periodSlice";
import selectedUserReducer from "./selectedUser/selectedUserSlice";
import yearsReducer from "./years/yearsSlice";
import shopReducer from "./shop/shopSlice";
import conf from "app/config/appConfig";

// Kombinujte reducery pomoc� combineReducers
const rootReducer = combineReducers<AppState>({
    auth: authReducer,
    menu: menuReducer,
    shop: shopReducer,
    navigation: navigationReducer,
    diagram: diagramReducer,
    selectedUser: selectedUserReducer,
    years: yearsReducer,
    period: periodReducer,
    mainBaseApi: mainBaseApi.reducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;

// Vytvořte perzistentní reducer
const persistedReducer = persistReducer<RootReducer, AnyAction>(
    {
        key: "root",
        version: 6, // TODO: Verze podle verze buildu
        storage,
        stateReconciler: hardSet,
        migrate: (state: any, version) => {
            //TODO: Trochu bordel, chtelo by to uklidit
            const persistedStore = localStorage.getItem("persist:root");
            if (persistedStore === null) return Promise.resolve(state);

            const persistedStoreParsed = JSON.parse(persistedStore || "{}");
            const persistedStoreVersion = JSON.parse(
                persistedStoreParsed._persist
            ).version;

            if (persistedStoreVersion === version) return Promise.resolve(state);

            return Promise.resolve({
                ...state,
                auth: {
                    ...state.auth,
                    authModel: {
                        ...state.auth.authModel,
                        pin: "",
                        pinValidityDurationInMinutes: Number.parseInt(
                            conf.REACT_APP_PIN_VALIDITY_DURATION_IN_MINUTES
                        ),
                        pinCreatedTimestamp: "",
                        pinVerifiedTimestamp: "",
                    },
                },
            });
        },
        // migrate: createMigrate(migration, { debug: false }),
        blacklist: ["mainBaseApi"],
    },

    rootReducer
);

// Vytvořte store
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(
            mainBaseApi.middleware
            //   rtkQueryErrorMiddleware
        ),
});

// Vytvořte persistor
export const persistor = persistStore(store);
