import { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import ModalBuyProcessPinCountdown from "./ModalBuyProcessPinCountdown";

interface Props {
    verifyPin: (pin: string) => void;
    invalidPin: boolean;
    hide?: boolean;
    userEmail: string;
    onExpire: () => void;
    error: boolean;
}

const ModalBuyProcessPin = (props: Props) => {
    const [value, setValue] = useState<string>("");
    const [loadingButton, setLoadingButton] = useState(false);

    const handleOnChagePin = (e: any) => {
        const { name, value } = e.target;
        let parsedValue = parseFloat(value);

        if (isNaN(parsedValue)) {
            setValue("");
            return;
        }

        setValue(parsedValue.toString());
    };

    const handleOnClickVerifyPin = () => {
        if (props.invalidPin) {
            setLoadingButton(false);

        } else {
            setLoadingButton(true);
        }
        props.verifyPin(value.toString());
    };

    const getTimestamp = () => {
        var time = new Date();
        time.setMinutes(time.getMinutes() + 1);

        return time;
    };

    useEffect(() => {
        setLoadingButton(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.invalidPin]);

    return (
        <>
            {!props.hide && (
                <Container style={{ paddingTop: "1rem" }} className={"pinCode"}>
                    <Row>
                        <Col>
                            Na adresu <b>{props.userEmail}</b> byl odeslán ověřovací{" "}
                            <b>PIN</b> kód.
                        </Col>
                    </Row>

                    <Row className={"pincodeCode"}>
                        <Col md={"4"}>
                            <InputGroup>
                                <Form.Control
                                    name="pin"
                                    placeholder="PIN"
                                    aria-describedby="pin"
                                    value={value}
                                    onChange={handleOnChagePin}
                                    isInvalid={props.invalidPin}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Nesprávný PIN
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                        <Col md="8">
                            <Button className="basic-button" onClick={handleOnClickVerifyPin} disabled={props.error}>
                                {loadingButton ? (
                                    <div>
                                        <span className="spinner btnsmall" />
                                        Dokončování...
                                    </div>
                                ) : (
                                    "Ověřit a dokončit nákup"
                                )}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ModalBuyProcessPinCountdown
                                expiryTimestamp={getTimestamp()}
                                onExpire={props.onExpire}
                            />
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default ModalBuyProcessPin;
