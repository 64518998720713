import * as React from 'react';
import { Crumb } from '../../entities/Crumb';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';

interface Props {
    nadpis: string;
    crumbs: Crumb[];
}

const HeadingMain = (props: Props) => {
    return (
        <header className="content-heading">
            <h1>
                {props.nadpis}
            </h1>
            <Breadcrumbs crumbs={props.crumbs} />
        </header>
    );

};

export default HeadingMain;
