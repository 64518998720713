export function formatAmount(amount: number, fractionDigits: number) {
    const formattedString = amount.toFixed(fractionDigits).replace(",", ".");
    return formattedString;
}

export function formatAmountNumber(amount: number, fractionDigits: number) {
    const formattedNumber = parseFloat(amount.toFixed(fractionDigits).replace(",", "."));
    return formattedNumber;
}

