import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import conf from "app/config/appConfig";
import AppState from "app/store/AppState";
import { planPurchaseWithInfoTags } from "./planPurchase/planPurchaseWithInfoApi";

export const reducerPath = "mainBaseApi";

export const mainBaseApi = createApi({
  reducerPath: reducerPath,
  tagTypes: ["Nakup", "GetPlanPurchaseWithInfo"],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({
    baseUrl: conf.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const authModel = (getState() as AppState).auth.authModel;
      if (authModel.token && endpoint !== "refresh") {
        headers.set("Authorization", `Bearer ${authModel.token}`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});
