import { PropsWithChildren } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/esm/helpers";

interface Props
  extends PropsWithChildren<
    ReplaceProps<"button", BsPrefixProps<"button"> & ButtonProps>
  > {}

const AppButton = (props: Props) => {
  return <Button {...props}>{props.children}</Button>;
};

export default AppButton;

AppButton.defaultProps = {
  className: "basic-button",
};
