import * as React from "react";
import TableHeading from "./TableHeading";
import TableBody from "./TableBody";
import TableB from "react-bootstrap/Table";
import { Noom, PlanList } from "../../../../entities/diagram/Diagrams";
import { useEffect, useState } from "react";

interface Props {
    content: PlanList[];
    toggleUpload: (
        toggle: boolean,
        idNakupObalkaOcenovaneMisto: number,
        name: string,
        pid: string,
        year: number
    ) => void;
}

const Table = (props: Props) => {
    const [sortedContent, setSortedContent] = useState<PlanList[]>([]);

    useEffect(() => {
        setSortedContent(props.content);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.content]);

    function handleToggle(
        toggle: boolean,
        idNakupObalkaOcenovaneMisto: number,
        name: string,
        pid: string,
        year: number
    ): void {
        props.toggleUpload(toggle, idNakupObalkaOcenovaneMisto, name, pid, year);
    }

    const handleSort = (sort: string) => {
        const sortName = mapSortString(sort);

        if (!sortName) return;

        const newSort = [...props.content];
        newSort.forEach((planList) => {
            planList.purchaseEnvelopeList.forEach((purchaseEnvelopeList) => {
                // purchaseEnvelopeList.noomList.sort((a, b) => {
                //   if ((a as any)[sortName] < (b as any)[sortName]) {
                //     return -1;
                //   }
                //   if ((a as any)[sortName] > (b as any)[sortName]) {
                //     return 1;
                //   }
                //   return 0;
                // });
                const sortedNoomList = sortArray(
                    purchaseEnvelopeList.noomList,
                    sortName,
                    "asc"
                );
                // purchaseEnvelopeList.noomList = [
                //   ...sortArray(purchaseEnvelopeList.noomList, sortName, "asc"),
                // ];

                purchaseEnvelopeList.noomList = sortedNoomList;
            });

            //   planList.purchaseEnvelopeList.sort((a, b) => {
            //     if ((a as any)[sort] < (b as any)[sort]) {
            //       return -1;
            //     }
            //     if ((a as any)[sort] > (b as any)[sort]) {
            //       return 1;
            //     }
            //     return 0;
            //   });
        });

        setSortedContent(newSort);
    };

    function sortArray(
        array: Noom[],
        key: keyof Noom,
        order: "asc" | "desc"
    ): Noom[] {
        return array.sort((a, b) => {
            const valueA = a[key];
            const valueB = b[key];

            let comparison = 0;
            if (valueA > valueB) {
                comparison = 1;
            } else if (valueA < valueB) {
                comparison = -1;
            }

            return order === "desc" ? -comparison : comparison;
        });
    }

    const mapSortString = (sort: string) => {
        switch (sort) {
            case "Měření":
                return "typMereni";
        }

        return "";
    };

    return (
        <>
            <TableB className="basic-table" responsive="xl">
                <TableHeading
                    content={[
                        "Obálka",
                        "Odběratel/IČO",
                        "Ean/Název MS",
                        "Segment",
                        "Měření",
                        "Sjednaný plán",
                        "Zpřesněný plán",
                        "Nakoupeno",
                        "",
                    ]}
                    sort={handleSort}
                />
                <TableBody content={sortedContent} toggleUpload={handleToggle} />
            </TableB>
            <p className="text-left"><i>Objem elektřiny v tabulkových přehledech výše uvádíme v jednotkách MWh.</i></p>
        </>
    );
};

export default Table;
