import * as React from 'react';

interface Props {
    content: string[];
}

const TableHeadingRow = (props: Props) => {
    const { content } = props;

    return (
        <tr>
            {content.map((heading, index) => (
                <th key={index}>{heading}</th>
            ))}
        </tr>
    );
};

export default TableHeadingRow;
