interface Props {
  style?: React.CSSProperties;
}

const CircleLoader = (props: Props) => {
  return (
    <div className="loader-basic">
      <span className="spinner" />
    </div>
  );
};
export default CircleLoader;
