import {
  faBolt,
  faDownload,
  faQuestionCircle,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { selectDiagramItem } from "../../../../app/store/diagram/diagramSlice";
import { useDiagramSlice } from "../../../../app/store/diagram/useDiagramSlice";
import {
  LoaderMultiple,
  Noom,
  PlanList,
  PurchaseEnvelopeList,
} from "../../../../entities/diagram/Diagrams";
import { useSnackbar } from "notistack";
import conf from "app/config/appConfig";

interface Props {
  content: PlanList;
  toggleUpload: (
    toggle: boolean,
    idNakupObalkaOcenovaneMisto: number,
    name: string,
    pid: string,
    year: number
  ) => void;
}

const TableBodyRow = (props: Props) => {
  const { diagramItemUpdate } = useDiagramSlice();
  const diagramItem = useSelector(selectDiagramItem);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { content } = props;
  const [loader, setLoader] = React.useState<LoaderMultiple>({
    status: false,
    pid: null,
  });

  const handleOnClickDownload = (idNakupObalkaOcenovaneMisto: number) => {
    setLoader({ status: true, pid: idNakupObalkaOcenovaneMisto });

    diagramItemUpdate({
      sucess: false,
      error: false,
    });

    const params = new URLSearchParams({
      IdNakupObalkaOcenovaneMisto: idNakupObalkaOcenovaneMisto.toString(),
    });

    axios
      .get(`${conf.REACT_APP_API_URL}PlanEnvelope/Export`, {
        method: "GET",
        responseType: "blob",
        params: params,
      })
      .then((response) => {
        if (response.data.type === "application/json") {
          diagramItemUpdate({
            error: true,
            sucess: diagramItem.sucess,
          });

          setLoader({ status: false, pid: idNakupObalkaOcenovaneMisto });

          enqueueSnackbar("Něco se nezdařilo", {
            variant: "error",
          });
          return;
        }

        const fname = response.headers["content-disposition"]
          .split("filename=")[1]
          .split(".")[0];
        const ext = response.headers["content-disposition"]
          .split(".")[1]
          .split(";")[0];
        const filename = `${fname.replace(`"`, ``)}.${ext.replace(`"`, ``)}`;

        const href = window.URL.createObjectURL(response.data);

        const anchorElement = document.createElement("a");

        anchorElement.href = href;
        anchorElement.download = filename;

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
        setLoader({ status: false, pid: idNakupObalkaOcenovaneMisto });

        enqueueSnackbar("Úspěšně staženo", {
          variant: "success",
        });
      });
  };

  return (
    <>
      {content.purchaseEnvelopeList.map((envelope: PurchaseEnvelopeList) =>
        envelope.noomList.map((noom: Noom) => (
          <tr key={noom.idOcenovaneMisto}>
            <td className="text-left">
              <FontAwesomeIcon icon={faBolt} className={"electrify"} />{" "}
              {envelope.purchaseEnvelopeId}
              <span>{spoctiOmList(envelope)}</span>
            </td>
            <td className="text-left">
              {content.name}
              <span>{content.compRegNumber}</span>
            </td>
            <td className="text-left">
              {noom.eanEic}
              <span>{noom.nazevMS}</span>
            </td>
            <td className="text-left">{noom.segment}</td>
            <td className="text-left">{noom.typMereni}</td>
            <td className="text-right">
              {formatNumberWithSpaces(
                noom.ocenenyPlan,
                conf.REACT_APP_DECIMALS
              )}
            </td>
            <td className="text-right">
              {formatNumberWithSpaces(
                noom.aktualniPlan,
                conf.REACT_APP_DECIMALS
              )}
            </td>
            <td className="text-right">
              {formatNumberWithSpaces(noom.nakoupeno, conf.REACT_APP_DECIMALS)}
            </td>
            <td className="text-center buttons-side-by-side respowidth">
              <button
                onClick={() =>
                  handleOnClickDownload(noom.idNakupObalkaOcenovaneMisto)
                }
                className="basic-button ico-left"
                disabled={
                  (loader.pid === noom.idNakupObalkaOcenovaneMisto &&
                    loader.status) ||
                  noom.segment.toLowerCase() === "mop"
                }
              >
                {loader.pid === noom.idNakupObalkaOcenovaneMisto &&
                loader.status ? (
                  <div>
                    <span className="spinner btnsmall" />
                    <span className="remove-respo">Stahování...</span>
                  </div>
                ) : (
                  <div>
                    <FontAwesomeIcon icon={faDownload} />{" "}
                    <span className="remove-respo">Stáhnout</span>
                  </div>
                )}
              </button>
              <button
                className="basic-button ico-left"
                disabled={noom.segment.toLowerCase() === "mop"}
                onClick={() =>
                  props.toggleUpload(
                    true,
                    noom.idNakupObalkaOcenovaneMisto,
                    envelope.purchaseEnvelopeId,
                    content.compRegNumber,
                    envelope.purchaseEnvYear
                  )
                }
              >
                <FontAwesomeIcon icon={faUpload} />{" "}
                <span className="remove-respo">Nahrát</span>
              </button>
            </td>
          </tr>
        ))
      )}
    </>
  );
};

export default TableBodyRow;

function formatNumberWithSpaces(
  number: number,
  maximumFractionDigits: number
): string {
  const formattedNumber =
    number?.toLocaleString(undefined, { maximumFractionDigits }) || "0";
  return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function spoctiOmList(purchaseEnvelope: PurchaseEnvelopeList): React.ReactNode {
  const pocetOm = purchaseEnvelope.purchaseEnvelopePortalName;
  let vysledek;

  if (pocetOm === null) {
    vysledek = <></>;
  } else {
    const pocetOmArray = pocetOm.split(";");
    const pocetOmCount = pocetOmArray.length;

    if (pocetOmCount === 1) {
      vysledek = <>{pocetOm}</>;
    } else if (pocetOmCount >= 2 && pocetOmCount <= 4) {
      vysledek = (
        <>
          {pocetOmCount} smlouvy
          <FontAwesomeIcon
            icon={faQuestionCircle}
            data-tooltip-content={pocetOm}
            data-tooltip-id={"tooltip-" + purchaseEnvelope.purchaseEnvelopeId}
          />
          <Tooltip
            id={"tooltip-" + purchaseEnvelope.purchaseEnvelopeId}
            place="right"
          />
        </>
      );
    } else {
      vysledek = (
        <>
          {pocetOmCount} smluv
          <FontAwesomeIcon
            icon={faQuestionCircle}
            data-tooltip-content={pocetOm}
            data-tooltip-id={"tooltip-" + purchaseEnvelope.purchaseEnvelopeId}
          />
          <Tooltip
            id={"tooltip-" + purchaseEnvelope.purchaseEnvelopeId}
            place="right"
          />
        </>
      );
    }
  }

  return vysledek;
}
