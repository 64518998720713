import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react"; // Opraven� import
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { OidcProvider } from "@axa-fr/react-oidc";
import { oidcConfig } from "app/oidc/oidcConfig";
import { store, persistor } from "app/store/store";
import { SnackbarProvider } from "notistack";
import CircleLoader from "shared/components/circleLoader/CircleLoader";

const handleOnSessionLost = () => {};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<CircleLoader />} persistor={persistor}>
        <BrowserRouter>
          <OidcProvider
            configuration={oidcConfig}
            authenticatingComponent={CircleLoader}
            loadingComponent={CircleLoader}
            callbackSuccessComponent={CircleLoader}
            onSessionLost={handleOnSessionLost}
          >
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </OidcProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
