import { useDispatch } from "react-redux";
import { actions } from "./diagramSlice";
import { DiagramItem } from "../../../entities/diagram/Diagrams";

export const useDiagramSlice = () => {
  const dispatch = useDispatch();

    const diagramItemUpdate = (
      diagramItem: Partial<DiagramItem>
  ) => {
        dispatch(actions.envelopeNavigationItemUpdate(diagramItem));
  };

  return {
      diagramItemUpdate,
  };
};
