import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  faArrowRight,
  faLock,
  faTimes,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import conf from "app/config/appConfig";
import { initialState } from "app/store/auth/authSlice";
import { useAuthSlice } from "app/store/auth/useAuthSlice";
import * as React from "react";
import { useEffect } from "react";

interface Props {
  toggler: boolean;
  toggleUserSidebar: (toggle: boolean) => void;
}

const UserSidebar = (props: Props) => {
  const [sidebarToggle, setSidebarToggle] = React.useState<boolean>(false);
  const { accessTokenPayload } = useOidcAccessToken();
  const userEmail = accessTokenPayload.email;

  const { logout } = useOidc();

  const { authUpdate } = useAuthSlice();

  useEffect(() => {
    setSidebarToggle(props.toggler);
  }, [props.toggler]);

  function handleToggle(toggle: boolean): void {
    props.toggleUserSidebar(toggle);
  }

  const handleOnClickOdhlasit = async () => {
    authUpdate(initialState.authModel);
    await logout(window.location.origin);
  };

  const getZmenaHeslaUrl = () => {
    return `${conf.REACT_APP_OIDC_AUTHORITY}/Manage/ChangePassword?fromUi=true&clientId=${conf.REACT_APP_OIDC_CLIENT_ID}`;
  };

  return (
    <aside className={sidebarToggle ? "userSideBar shown" : "userSideBar"}>
      <div className={"userSideBarHeader"}>
        <span>
          <FontAwesomeIcon icon={faUser} />
        </span>
        <p>
          <strong>{userEmail}</strong>
          {userEmail}
        </p>
        <button onClick={() => handleToggle(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className={"userSideBarBody"}>
        <ul>
          <li>
            <a href="">
              <FontAwesomeIcon icon={faUsers} /> Správa účtů
            </a>
          </li>
          <li>
            <a href={getZmenaHeslaUrl()}>
              <FontAwesomeIcon icon={faLock} /> Změnit heslo
            </a>
          </li>
          <li>
            <a onClick={handleOnClickOdhlasit}>
              <FontAwesomeIcon icon={faArrowRight} /> Odhlásit se
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default UserSidebar;
