import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Col, Row, Table } from "react-bootstrap";
import Heading from "shared/widgets/Heading";

interface FileListProps {
  files: { name: string | undefined }[];
  heading: string;
  path: string;
}

const FileList: React.FC<FileListProps> = (props: FileListProps) => {
  const { files, heading, path } = props;

  const removeFileExtension = (fileName: string | undefined): string => {
    if (fileName) {
      const parts = fileName.split(".");
      if (parts.length > 1) {
        parts.pop(); // Remove the last part (file extension)
        return parts.join(".");
      }
    }
    return fileName || "";
  };

  const downloadFile = async (fileName: string, path: string) => {
    try {
      const response = await axios.get(path + fileName, {
        responseType: "blob", // Nastavte typ odpovědi na blob (binární data)
      });

      // Vytvořte URL z blob a vytvořte odkaz pro stažení
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // Uvolnění objektu URL po stažení
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Chyba při stahování souboru:", error);
    }
  };

  if (!files || files.length === 0) {
    return (
      <Row className="pb-4">
        <Col>
          <section className="diagramList widget">
            <Heading content={heading} />
            <div className="widget-content">
              <p>Žádný dokument k zobrazení</p>
            </div>
          </section>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="pb-4">
      <Col>
        <section className="podminkyWidget widget">
          <Heading content={heading} />
          <div className="widget-content">
            <Table>
              <tbody>
                {files.map((file, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        href={path + file.name}
                        onClick={(e) => {
                          e.preventDefault();
                          downloadFile(file.name ?? "", path);
                        }}
                      >
                        {removeFileExtension(file.name)}
                      </a>
                    </td>
                    <td>
                      <a
                        href={path + file.name}
                        target="_blank"
                        rel="noreferrer"
                        className="basic-button"
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </section>
      </Col>
    </Row>
  );
};

export default FileList;
