import * as React from "react";
import { useEffect, useState } from "react";
import { PurchaseEnvelopeList } from "../../../../entities/nakup/Envelopes";
import { PeriodWithPrice } from "../../../../entities/period/PeriodWithPrice";
import { Prices, PricesWithData } from "../../../../entities/period/Prices";
import TableBodyRow from "./TableBodyRow";

interface Props {
    content: PeriodWithPrice | null;
    prices: Prices | null;
    filter: string;
    shopAllowed: boolean;
    envelope: PurchaseEnvelopeList;
    pricesWithData: PricesWithData | null;
    isFetching: boolean;
}

const TableBody = (props: Props) => {
    const { content, filter, shopAllowed, prices, envelope, pricesWithData } =
        props;
    const [pricesWithDataRef, setPricesWithDataRef] =
        useState<PricesWithData | null>(null);
    const [errorCounter, setErrorCounter] = useState<number>(0);

    useEffect(() => {
        if (pricesWithData === null) return;
        setErrorCounter(0);
        setPricesWithDataRef(pricesWithData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(pricesWithData)]);

    useEffect(() => {
        if (content?.data.periodList === null) return;
        setErrorCounter(0);
        content?.data.periodList.map((periodPriceItem, index) => {

            const filteredPrice = prices?.data?.cenaProTranseEE.find(
                (price) => price.produkt === periodPriceItem.periodCode
            );

            if (!filteredPrice) {
                setErrorCounter(errorCounter + 1);
            }

            return null;
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content?.data.periodList, pricesWithData]);


    const getPeriodLabel = (obdobi: string, rok: number) => {
        if (obdobi.startsWith("M")) {
            const month = obdobi.slice(1);
            const monthName = getMonthName(month);
            return `${monthName} ${rok}`;
        } else if (obdobi.startsWith("Q")) {
            const quarter = obdobi.slice(1);
            return `${quarter}. Kvartál ${rok}`;
        } else if (obdobi === "R") {
            return `Celý rok ${rok}`;
        }
        return obdobi;
    };

    const getMonthName = (month: string) => {
        const monthIndex = parseInt(month, 10) - 1;
        const monthNames = [
            "Leden",
            "Únor",
            "Březen",
            "Duben",
            "Květen",
            "Červen",
            "Červenec",
            "Srpen",
            "Září",
            "Říjen",
            "Listopad",
            "Prosinec",
        ];
        return monthNames[monthIndex];
    };

    if (
        !props.isFetching &&
        (!pricesWithDataRef || !pricesWithDataRef?.data.formulaList)
    ) {
        return (
            <tbody>
                <tr>
                    <td colSpan={7}>
                        Žádná data...
                    </td>
                </tr>
            </tbody>
        );
    }

    if (errorCounter === content?.data.periodList.length) {
        return (
            <tbody>
                <tr>
                    <td colSpan={7}>
                        Nejsou ceny pro žádné období...
                    </td>
                </tr>
            </tbody>
        );
    }

    return (
        <tbody>
            {content?.data.periodList.map((periodPriceItem, index) => {
                const isLastInSection =
                    index === content.data.periodList.length - 1 ||
                    content.data.periodList[index + 1].periodCode.charAt(0) !==
                    periodPriceItem.periodCode.charAt(0);
                // Filtrujte položky z `prices.data.cenaProTranse`, které mají stejný produkt jako `periodPriceItem.obdobi`

                const filteredPrice = prices?.data?.cenaProTranseEE.find(
                    (price) => price.produkt === periodPriceItem.periodCode
                );

                if (!filteredPrice) {
                    return null;
                }

                const selectedPeriodList =
                    pricesWithDataRef?.data.formulaList[0].periodList.find(
                        (period) => period.periodCode === periodPriceItem.periodCode
                    );

                return (
                    <TableBodyRow
                        itemData={periodPriceItem}
                        key={index}
                        envelope={envelope}
                        nadpis={getPeriodLabel(
                            periodPriceItem.periodCode,
                            envelope.purchaseEnvYear
                        )}
                        separator={isLastInSection}
                        filter={filter}
                        kurz={selectedPeriodList?.exchangeRate ?? 25}
                        pricesWithData={pricesWithDataRef}
                        periodWithPrice={content}
                        perioda={selectedPeriodList}
                        periody={pricesWithDataRef?.data.formulaList[0].periodList}
                        filterVal={
                            periodPriceItem.periodCode.startsWith("M")
                                ? "Měsíční"
                                : periodPriceItem.periodCode.startsWith("Q")
                                    ? "Kvartální"
                                    : "Roční"
                        }
                        shopAllowed={shopAllowed}
                        prices={filteredPrice}
                        pricesAll={prices?.data?.cenaProTranseEE}
                    />
                );
            })}
        </tbody>
    );
};

export default TableBody;
