import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

interface Props {
  type: string;
  text?: string;
  url?: string;
}

const CrumbItem = (props: Props) => {
  const navigate = useNavigate();

  const handleOnClickAnchor = () => {
    navigate(props.url!);
  };

  switch (props.type) {
    case "home":
      return (
        // <li>
        //   {/* <a href={props.url}> */}
        //   <a onClick={handleOnClickAnchor} href="#">
        //     <FontAwesomeIcon icon={faHome} />
        //   </a>
        // </li>
        <li className={"link-first"}>
          <a href="#" onClick={handleOnClickAnchor}>
            {props.text}
          </a>
        </li>
      );
    case "link":
      return (
        <li className={"link"}>
          <a href="#" onClick={handleOnClickAnchor}>
            {props.text}
          </a>
        </li>
      );
    case "active":
      return <li className="active">{props.text}</li>;
    default:
      return null;
  }
};

export default CrumbItem;
