import * as React from "react";

interface Props {
  heading?: string | null;
  type: string;
  content?: React.ReactNode;
}

const InfoWindow = (props: Props) => {
  switch (props.type) {
    case "warning":
      return (
        <div className="infoWindow warning">
          {props.heading && <h2>{props.heading}</h2>}

          {props.content && (
            <div className="infoWindow-content">{props.content}</div>
          )}
        </div>
      );
    case "success":
      return (
        <div className="infoWindow success">
          {props.heading && <h2>{props.heading}</h2>}

          {props.content && (
            <div className="infoWindow-content">{props.content}</div>
          )}
        </div>
      );
    case "info":
      return (
        <div className="infoWindow info">
          {props.heading && <h2>{props.heading}</h2>}

          {props.content && (
            <div className="infoWindow-content">{props.content}</div>
          )}
        </div>
      );
    default:
      return <></>;
  }
};

export default InfoWindow;
