import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import AuthModel from "./AuthModel";
import AppState from "../AppState";
import conf from "app/config/appConfig";

export interface AuthState {
  authModel: AuthModel;
}

export const initialState: AuthState = {
  authModel: {
    token: "",
    pin: "",
    pinValidityDurationInMinutes: Number.parseInt(
      conf.REACT_APP_PIN_VALIDITY_DURATION_IN_MINUTES
    ),
    pinCreatedTimestamp: "",
    pinVerifiedTimestamp: "",
    pinExpired: true,
    pinVerified: true,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authUpdate: (state, action: PayloadAction<Partial<AuthModel>>) => {
      state.authModel = {
        ...state.authModel,
        ...action.payload,
      };
    },
  },
});

export const actions = authSlice.actions;

export default authSlice.reducer;

// SELECTORS
export const selectAuth = (state: AppState) => state.auth;
