import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useOidcAccessToken } from '@axa-fr/react-oidc/dist/ReactOidc';
import { access } from 'fs';

interface Props {
    toggleUserSidebar: (toggle: boolean) => void;
    userSidebarToggle: boolean;
}

const UserInfoToggle = (props: any) => {
    const { accessTokenPayload } = useOidcAccessToken();
    const userEmail = accessTokenPayload.email;

    function handleToggle(toggle: boolean): void {
        props.toggleUserSidebar(toggle);
    }

    return (
        <>
            <button className="header-userInfoToggler" onClick={() => handleToggle(!props.userSidebarToggle)}>
                <span><FontAwesomeIcon icon={faUser} /></span> <p>{userEmail}</p>
            </button>
        </>
    );
};

export default UserInfoToggle;