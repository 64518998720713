/* eslint-disable @typescript-eslint/no-unused-vars */
import Archiv from "features/podminky/Archiv";
import Podminky from "features/podminky/Podminky";
import HeadingMain from "../../shared/pages/heading";

const HomePage = () => {
  const breadcrumbsData = [
    { name: "Nákupní zóna", url: "/nakup" },
    { name: "Podmínky používání nákupního portálu", url: "/podminky" },
  ];

  return (
    <section className="main-body">
      <HeadingMain
        nadpis="Podmínky používání nákupního portálu"
        crumbs={breadcrumbsData}
      />
      <Podminky />
      {/*<Archiv />*/}
    </section>
  );
};

export default HomePage;
