import { periodListToSendItem } from "entities/period/Prices";
import { useDispatch } from "react-redux";
import { actions } from "./periodSlice";

export const usePeriodSlice = () => {
  const dispatch = useDispatch();

  const periodsToSendSet = (periodsToSend: periodListToSendItem[]) => {
    dispatch(actions.periodsToSendSet(periodsToSend));
  };

  return {
    periodsToSendSet,
  };
};
