import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Years } from "../../../entities/diagram/Diagrams";
import conf from "../../config/appConfig";
import AppState from "../AppState";

export interface YearsState {
    yearsItem: Years;
}

export const initialState: YearsState = {
    yearsItem: {
        od: conf.REACT_APP_DIAGRAM_FROM,
        do: conf.REACT_APP_DIAGRAM_TO
    },
};

export const yearsSlice = createSlice({
    name: "years",
    initialState,
    reducers: {
        yearsUpdate: (
            state,
            action: PayloadAction<Partial<Years>>
        ) => {
            state.yearsItem = {
                ...state.yearsItem,
                ...action.payload,
            };
        },
    },
});

export const actions = yearsSlice.actions;

export default yearsSlice.reducer;

// SELECTORS
export const selectYearsItem = (state: AppState) =>
    state.years.yearsItem;
