import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { PurchaseEnvelopeList } from "../../../entities/nakup/Envelopes";
import Heading from "../../../shared/widgets/Heading";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { selectEnvelopeNavigationItem } from "../../../app/store/navigation/navigationSlice";
import { useGetPriceQuery } from "app/api/main/price/priceApi";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { selectPeriodsToSend } from "app/store/period/periodSlice";

interface Props {
  envelope: PurchaseEnvelopeList;
  firmaId: string;
  firmaNazev: string;
}

const OfferDetail = (props: Props) => {
  const envelopeNavigationItem = useSelector(selectEnvelopeNavigationItem);
  const periodsToSend = useSelector(selectPeriodsToSend);

  const { accessTokenPayload } = useOidcAccessToken();

  const { data: pricesWithData, isFetching: pricesWithDataIsFetching } =
    useGetPriceQuery(
      {
        Email: accessTokenPayload.email,
        PurchaseEnvelopeId:
          props.envelope?.purchaseEnvelopeId?.toString() ?? "",
        PeriodList: periodsToSend,
      },
      {
        // skip: shopItem.envelope && periodsToSend && periodsToSend.length > 0,
      }
    );

  const omList =
    props.envelope.omList || envelopeNavigationItem?.envelope?.omList;

  if (pricesWithDataIsFetching) {
    // if (true) {
    return (
      <section className="OfferDetail widget">
        <Heading content={"Informace o nákupní skupině"} />
        <div className="widget-content">
          <div className="loader-basic">
            <span
              className="spinner"
              style={{ width: "2rem", color: "#61c250" }}
            />
          </div>
        </div>
      </section>
      // <CircleLoader />
    );
  }

  const omListRows = omList.map((om, index) => (
    <Row className="mb-2" key={index}>
      <Col md={6}>
        <p>{om.eanEic}</p>
      </Col>
      <Col md={6}>
        <span>{om.nazevMS}</span>
      </Col>
    </Row>
  ));

  return (
    <section className="OfferDetail widget">
      <Heading content={"Informace o nákupní skupině"} />
      <div className="widget-content">
        <Row className="mb-3">
          <Col md={6}>
            <label>Nákupní skupina:</label>

            <p>
              {props.envelope.purchaseEnvelopeId ||
                envelopeNavigationItem?.envelope?.purchaseEnvelopeId}
            </p>
          </Col>
          <Col md={6}>
            <label>Zahrnuté smlouvy</label>

            <p>
              {spoctiOmList(props.envelope || envelopeNavigationItem?.envelope)}
            </p>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <label>Název firmy</label>

            <p>{props.firmaNazev || envelopeNavigationItem?.firmaNazev}</p>
          </Col>
          <Col md={6}>
            <label>IČ</label>

            <p>{props.firmaId || envelopeNavigationItem?.firmaId}</p>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col md={6}>
            <label>Počet akt. dostupných kroků:</label>

            <p>-</p>
          </Col>
          <Col md={6}>
            <label>Počet vyčerpaných kroků:</label>

            <p>-</p>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={6}>
            <label>Sjednaný produkt:</label>
            <p>{pricesWithData?.data?.formulaList?.[0]?.productName}</p>
          </Col>
          <Col md={6}></Col>
        </Row>
        {omList.length > 0 && (
          <>
            <Row className="mb-3">
              <Col>
                <label>Odběrná místa zahrnutá ve smlouvě</label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <label>EAN</label>
              </Col>
              <Col md={6}>
                <label>Název MS</label>
              </Col>
            </Row>
          </>
        )}
        {omList.length > 0 && omListRows}
      </div>
    </section>
  );
};

export default OfferDetail;

function spoctiOmList(purchaseEnvelope: PurchaseEnvelopeList): React.ReactNode {
  const pocetOm = purchaseEnvelope.purchaseEnvelopePortalName;
  let vysledek;

  if (pocetOm === null) {
    vysledek = <></>;
  } else {
    const pocetOmArray = pocetOm.split(";");
    const pocetOmCount = pocetOmArray.length;

    if (pocetOmCount === 1) {
      vysledek = <>{pocetOm}</>;
    } else if (pocetOmCount >= 2 && pocetOmCount <= 4) {
      vysledek = (
        <>
          {pocetOmCount} smlouvy
          <FontAwesomeIcon
            icon={faQuestionCircle}
            data-tooltip-content={pocetOm}
            data-tooltip-id={"tooltip-" + purchaseEnvelope.purchaseEnvelopeId}
          />
          <Tooltip
            id={"tooltip-" + purchaseEnvelope.purchaseEnvelopeId}
            place="right"
          />
        </>
      );
    } else {
      vysledek = (
        <>
          {pocetOmCount} smluv
          <FontAwesomeIcon
            icon={faQuestionCircle}
            data-tooltip-content={pocetOm}
            data-tooltip-id={"tooltip-" + purchaseEnvelope.purchaseEnvelopeId}
          />
          <Tooltip
            id={"tooltip-" + purchaseEnvelope.purchaseEnvelopeId}
            place="right"
          />
        </>
      );
    }
  }

  return vysledek;
}
