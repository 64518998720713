import { TrancheBoughtList } from '../../../entities/nakup/Envelopes';
import Heading from '../../../shared/widgets/Heading';
import Table from './transactionHistoryTable/Table';

interface Props {
    history: TrancheBoughtList[];
    year: number;
}

const TransactionHistory = (props: Props) => {
    return (
        <section className="TransactionHistory widget">
            <Heading content={"Historie nákupů"} xlxs={false} />
            <div className="widget-content">
                <Table history={props.history} year={props.year} />
            </div>
        </section>
    );
};

export default TransactionHistory;
