import { useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { zeroPad } from "shared/helpers/numberHelpers";

interface Props {
  expiryTimestamp: Date;

  onExpire: (expired: boolean) => void;
}

const ContractShopDetailCountdown = (props: Props) => {
  const handleOnExpire = () => {
    props.onExpire(true);
  };

  const { days, hours, minutes, seconds, restart } = useTimer({
    expiryTimestamp: props.expiryTimestamp,
    onExpire: handleOnExpire,
  });

  useEffect(() => {
    props.onExpire(false);
    restart(props.expiryTimestamp);
  }, [props.expiryTimestamp]);

  const getDaysFormatted = (days: number) => {
    return days === 0 ? "" : `${days} dní `;
  };

  return (
    <>
      Obchodování v aktuální cenové hladině je možné{" "}
      <b>
        ještě {getDaysFormatted(days)} {zeroPad(hours, 2)}:{zeroPad(minutes, 2)}
        :{zeroPad(seconds, 2)}
      </b>
      .<br /> Poté bude možnost obchodovat přerušena.
    </>
  );
};

export default ContractShopDetailCountdown;
