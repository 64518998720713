import * as React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import MenuModel from "./auth/MenuModel";
import conf from "app/config/appConfig";

interface Props {
  item: MenuModel;
  icon?: IconProp | null;
}

const NavItem = (props: Props) => {
  const renderIcon = (icon: IconProp | null) => {
    if (icon) {
      return <FontAwesomeIcon icon={icon} />;
    } else {
      return null;
    }
  };

  library.add(fas);

  switch (props.item.type) {
    case "heading":
      return <h3>{props.item.title}</h3>;
    case "dynamicLink": //TOHLE bude natahovat elementy bez refreshe!!! s loaderem! a blurem jak maji na flexi!
      return (
        <li>
          <a href={conf.REACT_APP_MENU_BASE_URL + "" + props.item.url}>
            {props.item.icon !== undefined ? (
              <span>{renderIcon(props.item.icon)}</span>
            ) : (
              ""
            )}
            {props.item.title}
          </a>
        </li>
      );
    case "route": //tohle budou klasicke routy
      return (
        <li>
          <a
            href={props.item.routePath}
            className={
              //   path.includes(props.item.routePath ?? "") ? "active" : ""
              window.location.pathname.includes(props.item.routePath ?? "")
                ? "active"
                : ""
            }
          >
            {props.item.icon !== undefined ? (
              <span>{renderIcon(props.item.icon)}</span>
            ) : (
              ""
            )}
            {props.item.title}
          </a>
        </li>
      );
    default:
      return null;
  }
};

export default NavItem;
