import { useOidcAccessToken } from "@axa-fr/react-oidc";
import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { Diagrams, ModalInfo, Years } from "../../entities/diagram/Diagrams";
import HeadingMain from "../../shared/pages/heading";
import DiagramList from "../../widgets/pages/diagram/DiagramList";
import ModalUpload from "../../widgets/pages/diagram/modal/ModalUpload";
import InfoWindow from "../../shared/features/infoWindow/InfoWindow";
import { selectDiagramItem } from "../../app/store/diagram/diagramSlice";
import { useSelector } from "react-redux";
import { useDiagramSlice } from "../../app/store/diagram/useDiagramSlice";
import { useSelectedUserSlice } from "../../app/store/selectedUser/useSelectedUserSlice";
import { selectedUserItem } from "../../app/store/selectedUser/selectedUserSlice";
import CircleLoader from "shared/components/circleLoader/CircleLoader";
import { selectYearsItem } from "../../app/store/years/yearsSlice";
import { useYearsSlice } from "../../app/store/years/useYearsSlice";
import conf from "../../app/config/appConfig";
import AppButton from "shared/components/AppButton/AppButton";

const HomePage = () => {
    const [modalShow, setModalShow] = useState(false);
    const diagramItem = useSelector(selectDiagramItem);
    const { diagramItemUpdate } = useDiagramSlice();
    const [success, setSuccess] = useState(diagramItem.sucess);
    const [error, setError] = useState(diagramItem.error);
    const { selectedUserUpdate } = useSelectedUserSlice();
    const selectedUserID = useSelector(selectedUserItem);

    const { yearsUpdate } = useYearsSlice();
    const yearsFromState = useSelector(selectYearsItem);

    const [selectedCustomerId, setSelectedCustomerId] = useState(
        selectedUserID.userid
    );
    const [rerender, setRerender] = useState<number>(0);
    const [idNakupObalkaOcenovaneMisto, setIdNakupObalkaOcenovaneMisto] =
        React.useState<number | null>(null);
    const [envelopes, setEnvelopes] = useState<Diagrams | undefined>();
    const [years, setYears] = useState<Years>({
        od: yearsFromState?.od || conf.REACT_APP_DIAGRAM_FROM,
        do: yearsFromState?.do || conf.REACT_APP_DIAGRAM_TO,
    });
    const [yearsInp, setYearsInp] = useState<Years>({
        od: yearsFromState?.od || conf.REACT_APP_DIAGRAM_FROM,
        do: yearsFromState?.do || conf.REACT_APP_DIAGRAM_TO,
    });

    useEffect(() => {
        diagramItemUpdate({
            error: false,
            sucess: false,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSuccess(diagramItem.sucess);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagramItem.sucess]);

    useEffect(() => {
        setError(diagramItem.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagramItem.error]);

    const phoneNumber = conf.REACT_APP_TEL;
    const phoneNumberWithoutSpaces = phoneNumber
        ? phoneNumber.replace(/\s/g, "")
        : "";

    const [modalInfo, setModalInfo] = useState<ModalInfo>({
        name: "",
        pid: "",
        year: 0,
    });

    const { accessTokenPayload } = useOidcAccessToken();

    function handleToggle(
        toggle: boolean,
        idNakupObalkaOcenovaneMisto: number,
        name: string,
        pid: string,
        year: number
    ): void {
        if (toggle) {
            setIdNakupObalkaOcenovaneMisto(idNakupObalkaOcenovaneMisto);
            setModalShow(toggle);
            setModalInfo({ name, pid, year });
        } else {
            setIdNakupObalkaOcenovaneMisto(null);
            setModalShow(toggle);
            setModalInfo({ name: "", pid: "", year: 0 });
        }
    }

    const getEnvelopes = async (): Promise<void> => {
        let params = new URLSearchParams();
        let email = accessTokenPayload.email;

        //parametry uživatele
        params.set("Email", email ? email : "");
        params.set("PeriodYearFrom", years.od);
        params.set("PeriodYearTo", years.do);

        let url = conf.REACT_APP_API_URL;

        try {
            let response = await axios.get(url + "PlanEnvelope/Get", {
                params: params,
            });
            let data = response.data;

            setEnvelopes(undefined);
            setEnvelopes(data);
        } catch (error) {
            // Zpracování chyby
            console.log(error);
        }
    };

    const generateYearRange = () => {
        const startYear = parseInt(years.od);
        const endYear = parseInt(years.do);
        const yearRange = [];

        for (let year = startYear; year <= endYear; year++) {
            yearRange.push(year);
        }

        return yearRange;
    };
    function yearsControl() {
        var to = yearsInp.do;
        var from = yearsInp.od;
        var intFrom = parseInt(yearsInp.od) || 0;
        var intTo = parseInt(yearsInp.do) || 0;

        if (intTo < intFrom) {
            to = "" + (intFrom + 1);
            intTo = intFrom + 1;
        }

        if (intFrom < 2000) {
            from = "2000";
            intFrom = 2000;
        }

        if (intFrom > 2099) {
            from = "2099";
        }

        if (intTo > 2099) {
            to = "2099";
        }

        setYearsInp({ od: from, do: to });
        setYears({ od: from, do: to });
        setRerender(rerender + 1);
    }

    useEffect(() => {
        getEnvelopes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getEnvelopes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rerender]);

    useEffect(() => {
        if (success) {
            setRerender(rerender + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success]);

    const breadcrumbsData = [
        // { name: "Domů", url: "/" },
        { name: "Úprava diagramu spotřeby", url: "/diagram" },
    ];

    const renderBody = (pDiagrams?: Diagrams) => {
        if (!pDiagrams) return <CircleLoader />;

        if (pDiagrams.data.bpList.length === 0) {
            return (
                <section className="purchaseOffersList widget">
                    <div className="widget-content">
                        <b>Nemáte žádný  produkt pro postupný nákup</b>
                    </div>
                </section>
            );
        }

        const yearRange = generateYearRange();
        const diagramElements = yearRange.map((year) => (
            <Row className="pb-4" key={year}>
                <Col>
                    <DiagramList
                        year={year}
                        toggleUpload={handleToggle}
                        selectedCustomerId={selectedCustomerId}
                        data={pDiagrams}
                    />
                </Col>
            </Row>
        ));

        return diagramElements;
    };

    const handleCustomerSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        selectedUserUpdate({
            userid: e.target.value,
        });
        setSelectedCustomerId(e.target.value);
    };

    const handleOnUploaded = () => {
        setEnvelopes(undefined);
        getEnvelopes();
    };

    function getUniqueCustomers(
        envelopes: Diagrams | undefined
    ): { compRegNumber: string; name: string }[] {
        const uniqueCustomers: { compRegNumber: string; name: string }[] = [];

        if (envelopes && envelopes.data && envelopes.data.bpList) {
            const customerMap: Map<string, string> = new Map();

            for (const customer of envelopes.data.bpList) {
                if (!customerMap.has(customer.compRegNumber)) {
                    customerMap.set(customer.compRegNumber, customer.name);
                    uniqueCustomers.push({
                        compRegNumber: customer.compRegNumber,
                        name: customer.name,
                    });
                }
            }
        }

        return uniqueCustomers;
    }

    const handleOdOnChange = (e: any) => {
        setYearsInp({ od: e.target.value, do: years.do });

        yearsUpdate({
            od: e.target.value,
            do: years.do,
        });
    }

    const handleDoOnChange = (e: any) => {
        setYearsInp({ od: years.od, do: e.target.value });
        yearsUpdate({
            od: years.od,
            do: e.target.value,
        });
    }


    return (
        <section className="main-body">
            <HeadingMain nadpis="Úprava diagramu spotřeby" crumbs={breadcrumbsData} />
            {success ? (
                <InfoWindow
                    heading="Soubor byl úspěšně nahrán"
                    type="info"
                    content={
                        <React.Fragment>
                            Pro více informací nás kontaktujte na telefonu{" "}
                            <b>
                                <a href={"tel:" + phoneNumberWithoutSpaces}>{phoneNumber}</a>
                            </b>{" "}
                            nebo nám napište email{" "}
                            <b>
                                <a href={"mailto:" + conf.REACT_APP_MAIL}>
                                    {conf.REACT_APP_MAIL}
                                </a>
                            </b>
                            .
                        </React.Fragment>
                    }
                />
            ) : null}

            {error ? (
                <InfoWindow
                    heading="Chyba stahování"
                    type="warning"
                    content={
                        <React.Fragment>
                            Pro více informací nás kontaktujte na telefonu{" "}
                            <b>
                                <a href={"tel:" + phoneNumberWithoutSpaces}>{phoneNumber}</a>
                            </b>{" "}
                            nebo nám napište email{" "}
                            <b>
                                <a href={"mailto:" + conf.REACT_APP_MAIL}>
                                    {conf.REACT_APP_MAIL}
                                </a>
                            </b>
                            .
                        </React.Fragment>
                    }
                />
            ) : null}

            <div className="header-filters">
                <div className="client-filter-wrap">
                    <div className="client-filter">
                        <span>Filtrovat dle zákazníka: </span>
                        <Form.Select
                            onChange={handleCustomerSelect}
                            value={selectedCustomerId}
                        >
                            <option value="">Vyberte zákazníka</option>
                            {getUniqueCustomers(envelopes).map((customer) => (
                                <option
                                    key={customer.compRegNumber}
                                    value={customer.compRegNumber}
                                >
                                    {customer.name}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
                <div className="year-picker-wrap">
                    <div className="year-picker">
                        Od:{" "}
                        <Form.Control
                            onChange={handleOdOnChange}
                            type="number"
                            min={2000}
                            max={2099}
                            step={1}
                            value={yearsInp.od}
                        />
                    </div>

                    <div className="year-picker">
                        Do:{" "}
                        <Form.Control
                            onChange={handleDoOnChange}
                            type="number"
                            min={2000}
                            max={2099}
                            step={1}
                            value={yearsInp.do}
                        />
                    </div>

                    <AppButton onClick={() => yearsControl()}>Zobrazit</AppButton>
                </div>
            </div>

            {renderBody(envelopes)}

            <ModalUpload
                show={modalShow}
                onHide={() => setModalShow(false)}
                onUploaded={handleOnUploaded}
                content={
                    <React.Fragment>
                        Nahrání zpřesněného diagramu pro <b>{modalInfo.name}</b>
                        <br /> ({modalInfo.pid}) pro elektřinu pro rok {modalInfo.year}
                    </React.Fragment>
                }
                heading={"Nahrání zpřesněného diagramu"}
                idNakupObalkaOcenovaneMisto={idNakupObalkaOcenovaneMisto}
            />
        </section>
    );
};

export default HomePage;
