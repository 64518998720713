import { useEffect, useState } from "react";
import {
  Diagrams,
  PlanList,
  PurchaseEnvelopeList,
} from "../../../entities/diagram/Diagrams";
import Heading from "../../../shared/widgets/Heading";
import Table from "./table/Table";

interface Props {
  year: number;
  toggleUpload: (
    toggle: boolean,
    idNakupObalkaOcenovaneMisto: number,
    name: string,
    pid: string,
    year: number
  ) => void;
  data: Diagrams;
  selectedCustomerId: string;
}

const DiagramList = (props: Props) => {
  const [filteredData, setFilteredData] = useState<PlanList[]>([]);
  function handleToggle(
    toggle: boolean,
    idNakupObalkaOcenovaneMisto: number,
    name: string,
    pid: string,
    year: number
  ): void {
    props.toggleUpload(toggle, idNakupObalkaOcenovaneMisto, name, pid, year);
  }

  function filterDataByYear() {
    // Filtruje purchaseCustomerList podle roku
    const filteredPurchaseCustomerList: PlanList[] = props.data.data.bpList.map(
      (customer: PlanList) => ({
        ...customer,
        purchaseEnvelopeList: customer.purchaseEnvelopeList.filter(
          (envelope: PurchaseEnvelopeList) =>
            envelope.purchaseEnvYear === props.year
        ),
      })
    );

    return filteredPurchaseCustomerList;
  }

  useEffect(() => {
    setFilteredData([...filterDataByYear()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedCustomerId, props.data.data.datumDotazu]);

  const filteredDataByCustomer = props.selectedCustomerId
    ? filteredData.filter(
        (customer: PlanList) =>
          customer.compRegNumber === props.selectedCustomerId
      )
    : filteredData;

  const allListsEmpty = filteredDataByCustomer.every(
    (customer: PlanList) => customer.purchaseEnvelopeList.length === 0
  );

  if (allListsEmpty) {
    return null;
  }

  return (
    <section className="diagramList widget">
      <Heading content={"Diagramy pro rok " + props.year} />
      <div className="widget-content">
        <Table content={filteredDataByCustomer} toggleUpload={handleToggle} />
      </div>
    </section>
  );
};

export default DiagramList;
