import { useTimer } from "react-timer-hook";
import { zeroPad } from "shared/helpers/numberHelpers";

interface Props {
  expiryTimestamp: Date;
  onExpire: () => void;
}

const ModalBuyProcessPinCountdown = (props: Props) => {
  const { seconds, minutes, isRunning } = useTimer({
    expiryTimestamp: props.expiryTimestamp,
    onExpire: props.onExpire,
  });

  return (
    <>
      {seconds > 0 && (
        <>
          {" "}
          Platnost kodu vyprší za {minutes}:{zeroPad(seconds, 2)}.
        </>
      )}

      {!isRunning && <p style={{ color: "red" }}>Platnost kódu vypršela.</p>}
    </>
  );
};

export default ModalBuyProcessPinCountdown;
