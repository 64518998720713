/* eslint-disable eqeqeq */
import conf from "app/config/appConfig";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircleLoader from "shared/components/circleLoader/CircleLoader";
import { useShopSlice } from "../../../../app/store/shop/useShopSlice";
import { PurchaseEnvelopeList } from "../../../../entities/nakup/Envelopes";
import { PeriodPriceItem } from "../../../../entities/period/PeriodPriceItem";
import { PeriodWithPrice } from "../../../../entities/period/PeriodWithPrice";
import {
    PricesWithData,
    cenaProTranse,
    period,
} from "../../../../entities/period/Prices";

interface Props {
    nadpis: string;
    separator: boolean;
    filter: string;
    filterVal: string;
    shopAllowed: boolean;
    itemData: PeriodPriceItem;
    prices: cenaProTranse | undefined;
    envelope: PurchaseEnvelopeList;
    kurz: number;
    perioda: period | undefined;
    periody: period[] | undefined;
    pricesWithData: PricesWithData | null;
    periodWithPrice: PeriodWithPrice | null;
    pricesAll: cenaProTranse[] | undefined;
}

const TableBodyRow = (props: Props) => {
    let navigate = useNavigate();
    const { shopItemUpdate } = useShopSlice();

    const [cena, setCena] = useState<number>(props.prices?.cenaEurMWh ?? 0);
    const [perioda, setPerioda] = useState<period | undefined>();
    const [allowed, setAllowed] = useState<boolean>(false);
    const [hideRow, setHideRow] = useState<boolean>(false);
    const [platnostDo, setPlatnostDo] = useState<string>(props.prices ? props.prices.platnostDo : "");
    const [platnostDoDate, setPlatnostDoDate] = useState<Date>(platnostDo ? new Date(platnostDo) : new Date());
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [cas, setCas] = useState<string>(platnostDo ? formatDate(new Date(platnostDoDate)) : "??");
    const [isCzk, setIsCzk] = useState<boolean>(props.pricesWithData?.data.formulaList[0].formulaName.startsWith("CZK") ?? false);

    function formatDate(date: Date) {
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${hours}:${minutes} ${day}.${month}.${year}`;
    }
    useEffect(() => {
        if (platnostDo) {
            setAllowed(true);

            if (platnostDoDate.getTime() > currentDate.getTime()) {
                setAllowed(true);
            } else {
                setAllowed(false);
            }
        } else {
            setAllowed(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.pricesWithData) {
            setIsCzk(props.pricesWithData?.data.formulaList[0].formulaName.startsWith("CZK") ?? false);

            if (props.prices) {
                setCena(props.prices.cenaEurMWh);
                setPlatnostDo(props.prices.platnostDo);
                setPlatnostDoDate(new Date(platnostDo));
                setCas(formatDate(new Date(platnostDoDate)));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pricesWithData]);

    useEffect(() => {
        if (props.prices) {
            setCena(props.prices.cenaEurMWh);
            setPlatnostDo(props.prices.platnostDo);
            setPlatnostDoDate(new Date(platnostDo));
            debugger;
            setCas(formatDate(new Date(platnostDoDate)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.prices]);

    useEffect(() => {
        if (props.perioda) {
            setPerioda(props.perioda);

            if (
                (!props.perioda?.alfa && !props.perioda?.gama) ||
                (props.perioda?.alfa === 0 && props.perioda?.gama === 0)
            ) {
                setAllowed(false);
            } else {
                setAllowed(true);
            }
        }

        if (platnostDo) {
            setAllowed(true);
            if (platnostDoDate.getTime() > currentDate.getTime()) {
                setAllowed(true);
            } else {
                setAllowed(false);
            }
        } else {
            setAllowed(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.perioda)]);


    useEffect(() => {
        debugger;
        console.log(cena);
        if (!cena || cena === 0) {
            setHideRow(true);
            setCena(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cena]);

    const routeChange = () => {
        shopItemUpdate({
            nadpis: props.nadpis,
            itemData: props.itemData,
            prices: props.prices,
            envelope: props.envelope,
            kurz: props.kurz,
            perioda: props.perioda,
            periody: props.periody,
            pricesWithData: props.pricesWithData,
            periodWithPrice: props.periodWithPrice,
            pricesAll: props.pricesAll,
        });
        navigate("nakup-komodity");
    };

    if (props.filter != "Vše") {
        if (props.filter != props.filterVal) {
            return <></>;
        }
    }

    if (!perioda) {
        return (
            <tr className={"withloader"}>
                <td colSpan={7}>
                    <CircleLoader />
                </td>
            </tr>
        );
    }

    const koeficients = (perioda: period) => {
        let content = null;
        let czk =
            props.pricesWithData?.data.formulaList[0].formulaName.startsWith("CZK");

        if (perioda) {
            content = (
                <p>
                    {perioda.alfa !== 0 && perioda.alfa != null && (
                        <i>*{perioda.alfa.toFixed(conf.REACT_APP_ALFA_DECIMALS).replace(".", ",")}</i>
                    )}
                    {perioda.gama !== 0 && perioda.gama != null && (
                        <i>
                            {perioda.alfa == 0 || perioda.alfa == null ? " " : "; "} +
                            {perioda.gama.toFixed(conf.REACT_APP_GAMA_DECIMALS).replace(".", ",")} {czk ? "CZK" : "EUR"}
                        </i>
                    )}
                </p>
            );
        }

        return content;
    };


    if (cas === "??" || hideRow) {
        return <></>;
    }

    const renderTableRow = (pPricesWithData: PricesWithData | null, pCas: string) => {
        if (!pPricesWithData) {
            return <></>;
        }
        if (!pCas) {
            return <></>;
        }

        return (
            <tr className={props.separator ? "with-separator-top" : ""}>
                <td className="text-left">
                    <strong>{props.nadpis}</strong>
                    <span>CZECH BL {props.itemData.periodCode}</span>
                </td>
                <td className="text-left">
                    <strong>
                        {cena !== null
                            ? cena
                                ?.toFixed(conf.REACT_APP_CENA_DECIMALS)
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                ?.replace(".", ",")
                            : "0,00"}{" "}
                        {"EUR"}
                    </strong>
                    <span>platná do {cas}</span>
                </td>
                <td className="text-right koeficients">
                    {koeficients(perioda!)}
                    {isCzk && <span>kurz CZK/EUR: {props.kurz.toFixed(conf.REACT_APP_KURZ_DECIMALS).replace(".", ",")}</span>}
                </td>
                <td className="text-right">
                    {getTotalAmount(props.envelope.ocPlanNOList, props.itemData.periodCode)}
                </td>
                <td className="text-right">
                    {getTotalAmount(
                        props.envelope.aktPlanNOList,
                        props.itemData.periodCode
                    )}
                </td>
                <td className="text-right">
                    {getTotalAmount(
                        props.envelope.nakoupenoNOList,
                        props.itemData.periodCode
                    )}
                    <small>
                        {getPercents(
                            props.envelope.nakoupenoNOList,
                            props.envelope.ocPlanNOList,
                            props.itemData.periodCode
                        )}{" "}
                        %
                    </small>
                </td>
                <td className="text-right">
                    {getTotalAmountDiff(
                        props.envelope.nakoupenoNOList,
                        props.envelope.ocPlanNOList,
                        props.itemData.periodCode
                    )}
                    <small>
                        {getPercentsDiff(
                            props.envelope.nakoupenoNOList,
                            props.envelope.ocPlanNOList,
                            props.itemData.periodCode
                        )}{" "}
                        %
                    </small>
                </td>
                <td className="text-center">
                    {props.shopAllowed && allowed ? (
                        <button className="basic-button" onClick={routeChange}>
                            Nakoupit
                        </button>
                    ) : (
                        <button className="basic-button disabled">Nyní nelze zakoupit</button>
                    )}

                </td>
            </tr>
        );
    };

    return renderTableRow(props.pricesWithData ?? null, cas);
};

export default TableBodyRow;

const getTotalAmount = (list: any[], obdobi: string): string => {
    let filteredList = [];

    if (obdobi.startsWith("M")) {
        const month = parseInt(obdobi.slice(1), 10);
        filteredList = list.filter((item) => item.month === month);
    } else if (obdobi.startsWith("Q")) {
        const quarter = parseInt(obdobi.slice(1), 10);
        const startMonth = (quarter - 1) * 3 + 1;
        const endMonth = quarter * 3;
        filteredList = list.filter(
            (item) => item.month >= startMonth && item.month <= endMonth
        );
    } else if (obdobi === "R") {
        filteredList = list;
    }

    const total = filteredList.reduce(
        (total: number, item: any) => total + item.amount,
        0
    );

    return total
        .toFixed(conf.REACT_APP_DECIMALS)
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace(".", ",");
};

const getPercents = (list: any[], listTotal: any[], obdobi: string): string => {
    let filteredList = list;
    let filteredListTotal = listTotal;

    if (obdobi.startsWith("M")) {
        const month = parseInt(obdobi.slice(1), 10);
        filteredList = list.filter((item) => item.month === month);
        filteredListTotal = listTotal.filter((item) => item.month === month);
    } else if (obdobi.startsWith("Q")) {
        const quarter = parseInt(obdobi.slice(1), 10);
        const startMonth = (quarter - 1) * 3 + 1;
        const endMonth = quarter * 3;
        filteredList = list.filter(
            (item) => item.month >= startMonth && item.month <= endMonth
        );
        filteredListTotal = listTotal.filter(
            (item) => item.month >= startMonth && item.month <= endMonth
        );
    }

    const listSmall = filteredList.reduce(
        (total: number, item: any) => total + item.amount,
        0
    );
    const total = filteredListTotal.reduce(
        (total: number, item: any) => total + item.amount,
        0
    );
    const result = total === 0 ? 0 : listSmall / (total / 100);

    return result
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace(".", ",");
};

const getPercentsDiff = (
    list: any[],
    celkem: any[],
    obdobi: string
): string => {
    let filteredList = list;
    let filteredListCelkem = celkem;

    if (obdobi.startsWith("M")) {
        const month = parseInt(obdobi.slice(1), 10);
        filteredList = list.filter((item) => item.month === month);
        filteredListCelkem = celkem.filter((item) => item.month === month);
    } else if (obdobi.startsWith("Q")) {
        const quarter = parseInt(obdobi.slice(1), 10);
        const startMonth = (quarter - 1) * 3 + 1;
        const endMonth = quarter * 3;
        filteredList = list.filter(
            (item) => item.month >= startMonth && item.month <= endMonth
        );
        filteredListCelkem = celkem.filter(
            (item) => item.month >= startMonth && item.month <= endMonth
        );
    }

    const total = filteredList.reduce(
        (total: number, item: any) => total + item.amount,
        0
    );
    const totalCelkem = filteredListCelkem.reduce(
        (totalCelkem: number, item: any) => totalCelkem + item.amount,
        0
    );
    const zbyva = totalCelkem - total;
    let result = totalCelkem === 0 ? 0 : zbyva / (totalCelkem / 100);
    if (result < 0) {
        result = 0;
    }
    return result
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace(".", ",");
};

const getTotalAmountDiff = (
    list: any[],
    celkem: any[],
    obdobi: string
): string => {
    let filteredList = list;
    let filteredListCelkem = celkem;

    if (obdobi.startsWith("M")) {
        const month = parseInt(obdobi.slice(1), 10);
        filteredList = list.filter((item) => item.month === month);
        filteredListCelkem = celkem.filter((item) => item.month === month);
    } else if (obdobi.startsWith("Q")) {
        const quarter = parseInt(obdobi.slice(1), 10);
        const startMonth = (quarter - 1) * 3 + 1;
        const endMonth = quarter * 3;
        filteredList = list.filter(
            (item) => item.month >= startMonth && item.month <= endMonth
        );
        filteredListCelkem = celkem.filter(
            (item) => item.month >= startMonth && item.month <= endMonth
        );
    }

    const total = filteredList.reduce(
        (total: number, item: any) => total + item.amount,
        0
    );
    const totalCelkem = filteredListCelkem.reduce(
        (totalCelkem: number, item: any) => totalCelkem + item.amount,
        0
    );
    let vysledek = totalCelkem - total;
    if (vysledek < 0) {
        vysledek = 0;
    }
    return vysledek
        .toFixed(conf.REACT_APP_DECIMALS)
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace(".", ",");
};
