import {
  faBolt,
  faFire,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { useNavigationSlice } from "app/store/navigation/useNavigationSlice";
import {
  purchaseCustomerList,
  PurchaseEnvelopeList,
} from "../../../../entities/nakup/Envelopes";
import { Tooltip } from "react-tooltip";
import { useEffect } from "react";
import conf from "app/config/appConfig";

interface Props {
  content: purchaseCustomerList;
  shopAllowed: boolean;
}

const TableBodyRow = (props: Props) => {
  const { content } = props;
  let navigate = useNavigate();
  let { envelopeId } = useParams();

  const { envelopeNavigationItemUpdate } = useNavigationSlice();

  useEffect(() => {
    if (!envelopeId) return;

    const envelope = content.purchaseEnvelopeList.find(
      (x) => x.purchaseEnvelopeId === envelopeId
    );
    if (!envelope) return;

    routeChange(envelope);
  }, [envelopeId]);

  const routeChange = (envelope: PurchaseEnvelopeList) => {
    envelopeNavigationItemUpdate({
      envelope: envelope,
      firmaId: content.compRegNumber,
      firmaNazev: content.name,
    });

    navigate("/nakup/smlouva");
  };

  return (
    <>
      {content.purchaseEnvelopeList.map((envelope: PurchaseEnvelopeList) => (
        <tr key={envelope.purchaseEnvelopeId}>
          <td className="text-left">
            {envelope.komodita === "EE" ? (
              <FontAwesomeIcon icon={faBolt} className={"electrify"} />
            ) : (
              <FontAwesomeIcon icon={faFire} />
            )}{" "}
            {envelope.purchaseEnvelopeId}
            <span>{spoctiOmList(envelope)}</span>
            <span>{envelope.produktNamePrimary ?? envelope.productName}</span>
          </td>
          <td className="text-left">
            {content.name} <span>{content.compRegNumber}</span>
          </td>
          <td className="text-right">
            {getTotalAmount(envelope.ocPlanNOList)}
          </td>
          <td className="text-right">
            {getTotalAmount(envelope.aktPlanNOList)}
          </td>
          <td className="text-right">
            {getTotalAmount(envelope.nakoupenoNOList)}
            <small>
              {getPercents(envelope.nakoupenoNOList, envelope.aktPlanNOList)} %
            </small>
          </td>
          <td className="text-right">
            {getTotalAmountDiff(
              envelope.nakoupenoNOList,
              envelope.ocPlanNOList
            )}
            <small>
              {getPercentsDiff(envelope.nakoupenoNOList, envelope.ocPlanNOList)}{" "}
              %
            </small>
          </td>
          <td className="text-center">
            <button
              className="basic-button"
              disabled={!envelope.purchaseEnvelopePortalName}
              onClick={() => routeChange(envelope)}
            >
              Detail{props.shopAllowed ? "/K nákupu" : ""}
            </button>
          </td>
        </tr>
      ))}
    </>
  );
};
const getTotalAmount = (list: any[]): string => {
  const total = list.reduce(
    (total: number, item: any) => total + item.amount,
    0
  );
  return total
    .toFixed(conf.REACT_APP_DECIMALS)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");
};

const getTotalAmountDiff = (list: any[], celkem: any[]): string => {
  const total = list.reduce(
    (total: number, item: any) => total + item.amount,
    0
  );
  const totalCelkem = celkem.reduce(
    (totalCelkem: number, item: any) => totalCelkem + item.amount,
    0
  );
  var vysledek = totalCelkem - total;
  if (vysledek < 0) {
    vysledek = 0;
  }
  return vysledek
    .toFixed(conf.REACT_APP_DECIMALS)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");
};

const getPercents = (list: any[], listTotal: any[]): string => {
  const listSmall = list.reduce(
    (total: number, item: any) => total + item.amount,
    0
  );
  const total = listTotal.reduce(
    (total: number, item: any) => total + item.amount,
    0
  );
  const result = total === 0 ? 0 : listSmall / (total / 100);
  return result
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");
};

const getPercentsDiff = (list: any[], celkem: any[]): string => {
  const total = list.reduce(
    (total: number, item: any) => total + item.amount,
    0
  );
  const totalCelkem = celkem.reduce(
    (totalCelkem: number, item: any) => totalCelkem + item.amount,
    0
  );
  var zbyva = totalCelkem - total;
  let result = totalCelkem === 0 ? 0 : zbyva / (totalCelkem / 100);

  if (result < 0) {
    result = 0;
  }
  return result
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");
};

function spoctiOmList(purchaseEnvelope: PurchaseEnvelopeList): React.ReactNode {
  const pocetOm = purchaseEnvelope.purchaseEnvelopePortalName;
  let vysledek;

  if (pocetOm === null) {
    vysledek = <></>;
  } else {
    const pocetOmArray = pocetOm.split(";");
    const pocetOmCount = pocetOmArray.length;

    if (pocetOmCount === 1) {
      vysledek = <>{pocetOm}</>;
    } else if (pocetOmCount >= 2 && pocetOmCount <= 4) {
      vysledek = (
        <>
          {pocetOmCount} smlouvy
          <FontAwesomeIcon
            icon={faQuestionCircle}
            data-tooltip-content={pocetOm}
            data-tooltip-id={"tooltip-" + purchaseEnvelope.purchaseEnvelopeId}
          />
          <Tooltip
            id={"tooltip-" + purchaseEnvelope.purchaseEnvelopeId}
            place="right"
          />
        </>
      );
    } else {
      vysledek = (
        <>
          {pocetOmCount} smluv
          <FontAwesomeIcon
            icon={faQuestionCircle}
            data-tooltip-content={pocetOm}
            data-tooltip-id={"tooltip-" + purchaseEnvelope.purchaseEnvelopeId}
          />
          <Tooltip
            id={"tooltip-" + purchaseEnvelope.purchaseEnvelopeId}
            place="right"
          />
        </>
      );
    }
  }

  return vysledek;
}

export default TableBodyRow;
