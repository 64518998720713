import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

interface Props {
  onClickBuy: () => Promise<void>;
  error: boolean;
}

const ModalBuyProcess = (props: Props) => {
  const [loadingButton, setLoadingButton] = useState(false);

  const handleOnClickBuy = async () => {
    setLoadingButton(true);
    await props.onClickBuy();
    setLoadingButton(false);
  };

  return (
    <Container style={{ paddingTop: "1rem" }} className={"pinCode"}>
      <Row className={"pincodeCode"}>
        <Col md="12" className="text-center">
          <Button
            className="basic-button"
            onClick={handleOnClickBuy}
            disabled={loadingButton || props.error}
          >
            {loadingButton ? (
              <div>
                <span className="spinner btnsmall" />
                Dokončování...
              </div>
            ) : (
              "Dokončit nákup"
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ModalBuyProcess;
