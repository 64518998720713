import { Envelopes } from "entities/nakup/Envelopes";
import { mainBaseApi } from "../mainBaseApi";
import PlanPurchaseWithInfoGetParams from "./PlanPurchaseWithInfoGetParams";
import { store } from "app/store/store";
import { actions } from "app/store/navigation/navigationSlice";

const endpointUrl = "PlanPurchaseWithInfo";
export const planPurchaseWithInfoTags = ["GetPlanPurchaseWithInfo"];

const planPurchaseWithInfoApi = mainBaseApi.injectEndpoints({
    endpoints: (build) => ({
        getPlanPurchaseWithInfo: build.query<
            Envelopes | undefined,
            PlanPurchaseWithInfoGetParams | null
        >({
            query: (query) => ({
                url: `${endpointUrl}/Get`,
                method: "GET",
                params: {
                    ...query,
                },
            }),
            providesTags: ["GetPlanPurchaseWithInfo"],
            transformResponse: (response: Envelopes) => {
                store.dispatch(
                    actions.envelopeNavigationItemUpdate({
                        statusDne: response.data.statusDne,
                    })
                );
                return response;
            },
        }),
    }),
});

export const { useGetPlanPurchaseWithInfoQuery } = planPurchaseWithInfoApi;
