import PozadovanyObjemCalculationResult from "entities/nakup/PozadovanyObjemCalculationResult";
import { useEffect, useState } from "react";
import { List } from "../../../../entities/nakup/Envelopes";

export const usePozadovanyObjem = (
    ocPlanNOList: any[],
    aktPlanNOList: any[],
    nakoupenoNOList: any[],
    periodCode: string,
    year: number,
    profilOrBaseload: string
) => {
    const [result, setResult] = useState<PozadovanyObjemCalculationResult>({
        percent: 0,
        mw: 0,
        mwh: 0,
    });

    const [dostupneMnozstviMWh, setDostupneMnozstviMWh] = useState(0);

    const [percent, setPercent] = useState(0);
    const [mw, setMw] = useState(0);
    const [mwh, setMwh] = useState(0);

    // Procenta
    useEffect(() => {
        let value = isNaN(percent) ? 0 : percent;

        const vypocetMWh = (percent / 100) * dostupneMnozstviMWh;

        setResult({
            percent: value,
            mw: vypocetMWh / getHours(),
            mwh: vypocetMWh,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [percent]);

    useEffect(() => {
        let value = isNaN(mw) ? 0 : mw;
        const mwh = value * getHours();

        setResult({
            percent: (mwh / dostupneMnozstviMWh) * 100,
            mw: value,
            mwh: mwh,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mw]);

    useEffect(() => {
        let value = isNaN(mwh) ? 0 : mwh;

        setResult({
            percent: (value / dostupneMnozstviMWh) * 100,
            mw: value / getHours(),
            mwh: value,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mwh]);

    // Vypocet zbyvajiciho mnozstvi
    useEffect(() => {
        // if (aktPlanNOList.length === 0) return;
        if (ocPlanNOList.length === 0) return;
        if (periodCode === "") return;

        const vysledek = getTotalAmount(ocPlanNOList, periodCode);

        setDostupneMnozstviMWh(parseFloat(vysledek));
    }, [ocPlanNOList, periodCode]);

    const resetResult = () => {
        setResult({
            percent: 0,
            mw: 0,
            mwh: 0,
        });

        setPercent(0);
        setMw(0);
        setMwh(0);
    };

    const extractNumberFromPeriodCode = (periodCode: string): number => {
        const numberStr = periodCode.slice(1);
        const parsedNumber = parseInt(numberStr, 10);

        return parsedNumber;
    };

    const findMinAmount = (data: List[], periodCode: string): List => {
        let minAmount = data?.[0]?.amount ?? 0;
        let minAmountIndex = 0;

        //rok
        let startIndex = 0;
        let endIndex = 11;

        //kvart�ly
        if (periodCode === "Q1") {
            startIndex = 0;
            endIndex = 2;
        }
        if (periodCode === "Q2") {
            startIndex = 3;
            endIndex = 5;
        }
        if (periodCode === "Q3") {
            startIndex = 6;
            endIndex = 8;
        }
        if (periodCode === "Q4") {
            startIndex = 9;
            endIndex = 11;
        }

        //m�s�ce
        if (periodCode.includes("M")) {
            var monthnumber = extractNumberFromPeriodCode(periodCode);
            startIndex = monthnumber - 1;
            endIndex = monthnumber - 1;
        }

        for (let i = startIndex; i <= endIndex; i++) {
            if (data[i].amount < minAmount) {
                minAmount = data[i].amount;
                minAmountIndex = i + 1;
            }
        }

        return { amount: minAmount, month: minAmountIndex };
    };

    const fillDiagram = (baseMWRemaining?: number) => {
        setMwh(0);
        //Profil
        if (profilOrBaseload === "Profil") {
            if (periodCode.startsWith("Q") || periodCode.startsWith("R")) {
                const minnakup = getPercentsDiff(
                    nakoupenoNOList,
                    ocPlanNOList,
                    periodCode
                );

                const vypocetZCelku =
                    dostupneMnozstviMWh * (parseFloat(minnakup) / 100);

                let value = vypocetZCelku;
                setResult({
                    percent: parseFloat(minnakup),
                    mw: value / getHours(),
                    mwh: value,
                });
                return;
            }

            const nakoupenoMWh = getTotalAmount(nakoupenoNOList, periodCode);
            const rozdil = dostupneMnozstviMWh - parseFloat(nakoupenoMWh);

            let value = rozdil;

            setResult({
                percent: (value / dostupneMnozstviMWh) * 100,
                mw: value / getHours(),
                mwh: value,
            });
            return;
        } else {
            //Baseload
            const minAmount = findMinAmount(ocPlanNOList, periodCode);
            let rozdil =
                minAmount.amount - nakoupenoNOList[minAmount.month].amount;
            let podil =
                rozdil / getHoursInMonth(getDaysInMonth(minAmount.month, year));
            let value = podil;

            const resultValue = {
                percent: ((value * getHours()) / dostupneMnozstviMWh) * 100,
                mw: baseMWRemaining ?? value,
                mwh: (baseMWRemaining ?? value) * getHours(),
            };
            setResult(resultValue);
        }
    };

    const getHoursInMonth = (daysInMonth: number) => {
        const days = daysInMonth;
        let hours = days * 24;
        hours += getLeepHour();
        return hours;
    };

    const getHours = () => {
        const days = getDays();
        let hours = days * 24;
        hours += getLeepHour();
        return hours;
    };

    const getLeepHour = () => {
        //   klidne tam dej ze pokud to je 3 mesic nebo kvartal 1 tak uber 1 hodinu
        // a 10 mesic nebo 4 kvartal pridej jeddnu hodinu
        let leepHour = 0;

        if (periodCode === "M3" || periodCode === "Q1") {
            leepHour = -1;
        }

        if (periodCode === "M10" || periodCode === "Q4") {
            leepHour = 1;
        }

        return leepHour;
    };

    const getDays = () => {
        let days = 0;
        if (periodCode.startsWith("M")) {
            if (periodCode.length === 3) {
                days = getDaysInMonth(
                    Number.parseInt(periodCode[1] + periodCode[2]),
                    year
                );
            } else {
                days = getDaysInMonth(Number.parseInt(periodCode[1]), year);
            }
        }

        if (periodCode.startsWith("Q")) {
            days = getDaysInQuarter(periodCode, year);
        }

        if (periodCode.startsWith("R")) {
            days = getDaysInYear(year);
        }

        return days;
    };

    const getDaysInMonth = (month: number, year: number) => {
        return new Date(year, month, 0).getDate();
    };

    const getDaysInQuarter = (periodCode: string, year: number) => {
        let days = 0;
        const quarter = Number.parseInt(periodCode[1]);
        const startMonth = (quarter - 1) * 3 + 1;
        const endMonth = quarter * 3;
        for (let i = startMonth; i <= endMonth; i++) {
            days += getDaysInMonth(i, year);
        }

        return days;
    };

    const getDaysInYear = (year: number) => {
        return (year % 4 === 0 && year % 100 > 0) || year % 400 == 0 ? 366 : 365;
    };

    const getTotalAmount = (list: any[], obdobi: string): string => {
        let filteredList = [];

        if (obdobi.startsWith("M")) {
            const month = parseInt(obdobi.slice(1), 10);
            filteredList = list.filter((item) => item.month === month);
        } else if (obdobi.startsWith("Q")) {
            const quarter = parseInt(obdobi.slice(1), 10);
            const startMonth = (quarter - 1) * 3 + 1;
            const endMonth = quarter * 3;
            filteredList = list.filter(
                (item) => item.month >= startMonth && item.month <= endMonth
            );
        } else if (obdobi === "R") {
            filteredList = list;
        }

        const total = filteredList.reduce(
            (total: number, item: any) => total + item.amount,
            0
        );

        return total;
    };

    // Zbyva nakoupit
    const getPercentsDiff = (
        list: any[],
        celkem: any[],
        obdobi: string
    ): string => {
        let filteredList = list;
        let filteredListCelkem = celkem;

        if (obdobi.startsWith("M")) {
            const month = parseInt(obdobi.slice(1), 10);
            filteredList = list.filter((item) => item.month === month);
            filteredListCelkem = celkem.filter((item) => item.month === month);
        } else if (obdobi.startsWith("Q") || obdobi.startsWith("R")) {
            const quarter = parseInt(obdobi.slice(1), 10);
            let startMonth = (quarter - 1) * 3 + 1;
            let endMonth = quarter * 3;

            if (obdobi.startsWith("R")) {
                startMonth = 1;
                endMonth = 12;
            }

            filteredList = list.filter(
                (item) => item.month >= startMonth && item.month <= endMonth
            );
            filteredListCelkem = celkem.filter(
                (item) => item.month >= startMonth && item.month <= endMonth
            );

            const resultsInMonths: { p: number; m: number }[] = [];
            filteredList.forEach((item) => {
                const celkemMonth = filteredListCelkem.find(
                    (x) => x.month === item.month
                );
                const resultInMonth = (item.amount / celkemMonth.amount) * 100;
                const remaining = 100 - resultInMonth;
                if (remaining < 100 && remaining > 0) {
                    resultsInMonths.push({ p: remaining, m: item.month });
                }
            });

            if (resultsInMonths.length === 0) return "100";

            const lowestP = resultsInMonths.reduce((prev, current) =>
                prev.p < current.p ? prev : current
            );
            return lowestP.p.toString();
        }

        const total = filteredList.reduce(
            (total: number, item: any) => total + item.amount,
            0
        );
        const totalCelkem = filteredListCelkem.reduce(
            (totalCelkem: number, item: any) => totalCelkem + item.amount,
            0
        );
        const zbyva = totalCelkem - total;
        let result = totalCelkem === 0 ? 0 : zbyva / (totalCelkem / 100);
        if (result < 0) {
            result = 0;
        }
        return result
            .toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            .replace(".", ",");
    };

    // const isOverbought = (list: any[], celkem: any[], obdobi: string) => {
    const isOverbought = () => {
        let list = nakoupenoNOList;
        let celkem = ocPlanNOList;

        let filteredList = list;
        let filteredListCelkem = celkem;
        let obdobi = periodCode;

        if (obdobi.startsWith("M")) return false;

        const quarter = parseInt(obdobi.slice(1), 10);
        let startMonth = (quarter - 1) * 3 + 1;
        let endMonth = quarter * 3;

        if (obdobi.startsWith("R")) {
            startMonth = 1;
            endMonth = 12;
        }

        filteredList = list.filter(
            (item) => item.month >= startMonth && item.month <= endMonth
        );
        filteredListCelkem = celkem.filter(
            (item) => item.month >= startMonth && item.month <= endMonth
        );

        let result = false;
        filteredList.forEach((item) => {
            const celkemMonth = filteredListCelkem.find(
                (x) => x.month === item.month
            );
            const resultInMonth = (item.amount / celkemMonth.amount) * 100;
            if (resultInMonth >= 100) {
                result = true;
                return;
            }
        });

        return result;
    };

    return {
        result,
        setPercent,
        setMw,
        setMwh,
        fillDiagram,
        resetResult,
        isOverbought,
    };
};
