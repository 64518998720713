import { selectEnvelopeNavigationItem } from "app/store/navigation/navigationSlice";
import { useShopSlice } from "app/store/shop/useShopSlice";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import CircleLoader from "shared/components/circleLoader/CircleLoader";
import InfoWindowModel from "shared/features/infoWindow/InfoWindowModel";
import { PurchaseEnvelopeList } from "../../entities/nakup/Envelopes";
import InfoHeadingWithErrors from "../../shared/features/InfoHeadingWithErrors";
import HeadingMain from "../../shared/pages/heading";
import OfferDetail from "../../widgets/pages/nakup/OfferDetail";
import OfferDetailGraph from "../../widgets/pages/nakup/OfferDetailGraph";
import OffersList from "../../widgets/pages/nakup/OffersList";
import TransactionHistory from "../../widgets/pages/nakup/TransactionHistory";
import ContractShopInfoWindow from "./ContractShopInfoWindow";

const ContractShopList = () => {
  const [shopAllowed, setShopAllowed] = React.useState<boolean>(false);

  const envelopeNavigationItem = useSelector(selectEnvelopeNavigationItem);

  const { messagesReset, messagesUpdate } = useShopSlice();
  const breadcrumbsData = [
    // { name: "Domů", url: "/" },
    { name: "Nákupní zóna ", url: "/nakup" },
    { name: "Detail skupiny", url: "/nakup/smlouva" },
  ];

  const LoadingCircle = () => {
    return <CircleLoader />;
  };

  if (!envelopeNavigationItem.envelope) {
    // Data se ještě načítají, zobrazte zatížení nebo jinou indikaci načítání
    return <LoadingCircle />;
  }

  const renderHistory = (pEnvelope: PurchaseEnvelopeList) => {
    const history = (
      <Row className="pb-4">
        <Col>
          <TransactionHistory
            history={pEnvelope.trancheBoughtList}
            year={pEnvelope.purchaseEnvYear}
          />
        </Col>
      </Row>
    );

    return history;
  };

  const renderOfferList = (pEnvelope: PurchaseEnvelopeList) => {
    const offerlist = (
      <Row className="pb-4">
        <Col xs={12}>
          <OffersList shopAllowed={shopAllowed} envelope={pEnvelope} />
        </Col>
      </Row>
    );

    return offerlist;
  };

  function setShop(toggle: boolean): void {
    setShopAllowed(toggle);
  }

  const handleOnMessagesSet = (messages: InfoWindowModel[]) => {
    messagesReset();
    const newMessages = messages.filter(
      (message) => message.code !== "nakoupeno"
    );

    newMessages.forEach((message) => {
      messagesUpdate(message);
    });
  };

  return (
    <section className="main-body">
          <HeadingMain
        nadpis={
          "Nákup elektřiny pro nákupní skupinu: " +
            envelopeNavigationItem?.envelope?.purchaseEnvelopeId ?? ""
        }
        crumbs={breadcrumbsData}
      />

      <InfoHeadingWithErrors setShopAllowed={setShop} statusDne={undefined} />

      <ContractShopInfoWindow onMessagesSet={handleOnMessagesSet} />

      <Container fluid className="px-0">
        <Row className="pb-4">
          <Col lg={12} xl={6}>
            <OfferDetail
              envelope={envelopeNavigationItem.envelope}
              firmaId={envelopeNavigationItem.firmaId}
              firmaNazev={envelopeNavigationItem.firmaNazev}
            />
          </Col>
          <Col lg={12} xl={6}>
            <OfferDetailGraph
              navigation={["Sjednaný", "Zpřesněný"]}
              envelope={envelopeNavigationItem.envelope}
            />
          </Col>
        </Row>

        {renderOfferList(envelopeNavigationItem.envelope)}

        {renderHistory(envelopeNavigationItem.envelope)}
      </Container>
    </section>
  );
};

export default ContractShopList;
