import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import PinModalInput from "./PinModalInput";
import { usePin } from "./usePin";
import { useNavigate } from "react-router-dom";
import PinModalCountdown from "./PinModalCountdown";
import { useSelector } from "react-redux";
import { selectAuth } from "app/store/auth/authSlice";
import { useAuthSlice } from "app/store/auth/useAuthSlice";

interface Props {
    rerender: boolean;
}

const PinModal = (props: Props) => {
    const auth = useSelector(selectAuth);

    const navigate = useNavigate();
    const { accessTokenPayload } = useOidcAccessToken();
    const { pinExpired, verifyPin, pinVerified, checkStatus, sendNewPin } =
        usePin();

    const { authUpdate } = useAuthSlice();

    const [verifyButtonDisabled, setVerifyButtonDisabled] =
        useState<boolean>(false);

    // TODO:zpet do countdownu
    const [expiryTimestamp, setExpiryTimestamp] = useState<Date>(new Date());

    useEffect(() => {
        setTimeout(function () {
            const backdrop = document.querySelector(".modal-backdrop.show");
            backdrop?.classList.add("custom-backdrop");
        }, 300);
    }, []);

    useEffect(() => {
        checkStatus();
        setTimeout(function () {
            const backdrop = document.querySelector(".modal-backdrop.show");
            backdrop?.classList.add("custom-backdrop");
        }, 300);
    }, [props.rerender]);

    // const showModal = pinExpired && !!pinVerified;
    const showModal = !pinVerified;
    const handleOnClickModalClose = () => {
        const backdrop = document.querySelector(".modal-backdrop.show");
        backdrop?.classList.remove("custom-backdrop");
        navigate("/diagram");
    };

  useEffect(() => {
    if (!auth.authModel.pinCreatedTimestamp) {
      setVerifyButtonDisabled(true);
      return;
    }

        var time = new Date(auth.authModel.pinCreatedTimestamp);
        time.setMinutes(time.getMinutes() + 2);

        setExpiryTimestamp(time);
    }, [auth.authModel.pinCreatedTimestamp]);

    const handleOnModalCountdownExpire = () => {
        authUpdate({ pinCreatedTimestamp: "", pinVerifiedTimestamp: "", pin: "" });
        setVerifyButtonDisabled(true);
    };

    const handleSendNewPin = async () => {
        await sendNewPin();
        setVerifyButtonDisabled(false);
    };

    return (
        <>
            {showModal && (
                <Modal
                    centered
                    show={showModal}
                    size="lg"
                    backdrop="static"
                    keyboard={false}
                    onHide={handleOnClickModalClose}
                    className="modal-with-menu"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Dvoufaktorové ověření</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container className={"pinCode"} style={{ paddingTop: "1rem" }}>
                            <Row>
                                <Col className="text-centered">
                                    Pro vstup do Nákupní zóny prosím vyplňte <b>PIN</b>, který
                                    vám zasíláme na emailovou adresu{" "}
                                    <b>{accessTokenPayload.email}</b>.
                                </Col>
                            </Row>

                            <Row className={"pincodeCode"} style={{ paddingTop: "2rem" }}>
                                <Col md={"12"}>
                                    <PinModalInput
                                        verifyPin={verifyPin}
                                        onClickSendPin={handleSendNewPin}
                                        verifyButtonDisabled={verifyButtonDisabled}
                                        pinCreatedTimestamp={auth.authModel.pinCreatedTimestamp}
                                    />
                                </Col>
                            </Row>

                            {auth.authModel.pinCreatedTimestamp && (
                                <Row>
                                    <Col className="text-centered">
                                        <PinModalCountdown
                                            onExpire={handleOnModalCountdownExpire}
                                            expiryTimestamp={expiryTimestamp}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Container>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default PinModal;
