import { LoaderMultiple } from "entities/diagram/Diagrams";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface Props {
    show: boolean;
    onHide: () => void;
    content?: React.ReactNode;
    heading?: string;
    checkTrancheBuy: () => Promise<boolean>;
    sendPin: () => Promise<void>;
    hideSendPin?: boolean;
    error: boolean;
}

const OfferBuyProcessForm = (props: Props) => {
    const [loader, setLoader] = React.useState<LoaderMultiple>({
        status: false,
        pid: null,
    });

    const handleOnClickBuy = async () => {
        setLoader({ status: true, pid: null });
        const checkTrancheBuy = await props.checkTrancheBuy();

        if (!checkTrancheBuy) {
            setLoader({ status: false, pid: null });
            return;
        }

        await props.sendPin();
        setLoader({ status: false, pid: null });
    };

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                centered
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    {props.heading && <Modal.Title>{props.heading}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>{props.content ? props.content : ""}</Modal.Body>
                <Modal.Footer>
                    {!props.hideSendPin && (
                        <Button
                            className="basic-button"
                            onClick={handleOnClickBuy}
                            disabled={loader.status || props.error}
                        >
                            {loader.status ? (
                                <div>
                                    <span className="spinner btnsmall" />
                                    Zasílání...
                                </div>
                            ) : (
                                "Zaslat ověřovací kód"
                            )}
                        </Button>
                    )}
                    <Button className="button-link black" onClick={props.onHide}>
                        Zrušit nákup
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default OfferBuyProcessForm;
