import * as React from "react";
import TableHeading from "./TableHeading";
import TableBody from "./TableBody";
import TableB from "react-bootstrap/Table";
import { PeriodWithPrice } from "../../../../entities/period/PeriodWithPrice";
import { Prices, PricesWithData } from "../../../../entities/period/Prices";
import { PurchaseEnvelopeList } from "../../../../entities/nakup/Envelopes";

interface Props {
  content: PeriodWithPrice | null;
  filter: string;
  shopAllowed: boolean;
  prices: Prices | null;
  envelope: PurchaseEnvelopeList;
  pricesWithData: PricesWithData | null;
  isFetching: boolean;
}

const Table = (props: Props) => {
  return (
    <TableB className="basic-table" responsive="xl">
      <TableHeading
        content={[
          "Období/Produkt",
          "Cena",
          "Koeficient",
          "Sjednaný plán",
          "Zpřesněný plán",
          "Nakoupeno",
          "Zbývá nakoupit",
          "",
        ]}
      />
      <TableBody
        content={props.content}
        pricesWithData={props.pricesWithData}
        envelope={props.envelope}
        prices={props.prices}
        filter={props.filter}
        shopAllowed={props.shopAllowed}
        isFetching={props.isFetching}
      />
    </TableB>
  );
};

export default Table;
