import * as React from 'react';
import Navigation from '../../shared/menu/Navigation';

interface Props {
    content: React.ReactElement;
}

const ContentWindow = (props: Props) => {
    return (
        <div className="contentWindow">
            {props.content}
        </div>
    );
};

export default ContentWindow;
