/* eslint-disable no-self-compare */
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useGetPriceQuery } from "app/api/main/price/priceApi";
import { useBuyMutation } from "app/api/main/tranche/trancheApi";
import conf from "app/config/appConfig";
import { selectPeriodsToSend } from "app/store/period/periodSlice";
import { useShopSlice } from "app/store/shop/useShopSlice";
import axios from "axios";
import { LoaderMultiple } from "entities/diagram/Diagrams";
import { getPeriodLabel } from "features/period/periodHelper";
import SelectPeriod from "features/selectPeriod/SelectPeriod";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LinkButton from "shared/components/LinkButton/LinkButton";
import CircleLoader from "shared/components/circleLoader/CircleLoader";
import InfoWindow from "shared/features/infoWindow/InfoWindow";
import { ShopItem, ShowGraph } from "../../../entities/nakup/Envelopes";
import {
    BuyTranche,
    cenaProTranse,
    formulaListToSend,
    period,
    periodToSend,
} from "../../../entities/period/Prices";
import OfferBuyProcessAmountInput from "./OfferBuyProcessAmountInput";
import { usePozadovanyObjem } from "./hooks/usePozadovanyObjem";
import ModalBuyProcess from "./modal/ModalBuyProcess";
import ModalBuyProcessInfo from "./modal/ModalBuyProcessInfo";
import ModalBuyProcessInfoWindow from "./modal/ModalBuyProcessInfoWindow";
import ModalBuyProcessLastStep from "./modal/ModalBuyProcessLastStep";
import ModalBuyProcessPin from "./modal/ModalBuyProcessPin";
import {
    formatAmount,
    formatAmountNumber,
} from "shared/formatters/amountFormatter";

interface Props {
    error: boolean;
    shopItem: ShopItem;
    showInGraph: (graphToShow: ShowGraph | undefined) => void;
    newDateToCountDown: (date: Date) => void;
}

const OfferBuyProcessForm = (props: Props) => {
    const navigate = useNavigate();

    const periodsToSend = useSelector(selectPeriodsToSend);

    const [modalShow, setModalShow] = useState<boolean>(false);
    const [price, setPrice] = useState<number>(0);
    const [pricesAll, setPricesAll] = useState<cenaProTranse[]>(
        props.shopItem.pricesAll ?? []
    );
    const [priceZaNakup, setPriceZaNakup] = useState<number>(0);
    const [disableButtonFill, setDisableButtonFill] = useState<boolean>(false);
    const [period, setPeriod] = useState<period>();
    const [mnozstviMWH, setMnozstviMWH] = useState<number>(10);
    const [checkTrancheBuyResultMessages, setCheckTrancheBuyResultMessages] =
        useState<any[]>([]);
    const [buyTrancheToSend, setBuyTrancheToSend] = useState<BuyTranche>();
    const [generatedPin, setGeneratedPin] = useState<number | null>(null);
    const [pinInvalid, setPinInvalid] = useState<boolean>(false);
    const [showHighlight, setShowHighlight] = useState<boolean>(false);

    const { accessTokenPayload } = useOidcAccessToken();
    const userEmail = accessTokenPayload.email;
    const { messagesUpdate, messagesReset } = useShopSlice();
    const [loadingButton, setLoadingButton] = useState(false);

    const [inputMW, setInputMW] = useState<string>("");
    const [inputPercent, setInputPercent] = useState<string>("");
    const [inputMWh, setInputMWh] = useState<string>("");

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { data: pricesWithData, isFetching: pricesWithDataIsFetching } =
        useGetPriceQuery(
            {
                Email: accessTokenPayload.email,
                PurchaseEnvelopeId:
                    props.shopItem.envelope?.purchaseEnvelopeId?.toString() ?? "",
                PeriodList: getPeriodListToSend(period),
            },
            {
                // skip: shopItem.envelope && periodsToSend && periodsToSend.length > 0,
            }
        );

    const [buyTranche, buyResult] = useBuyMutation();

    const [loader, setLoader] = React.useState<LoaderMultiple>({
        status: false,
        pid: null,
    });

    const isCzk =
        props.shopItem.pricesWithData?.data.formulaList[0].formulaName.startsWith(
            "CZK"
        );

    const {
        result,
        setPercent,
        setMw,
        setMwh,
        fillDiagram,
        resetResult,
        isOverbought,
    } = usePozadovanyObjem(
        props.shopItem.envelope?.ocPlanNOList ?? [],
        props.shopItem.envelope?.aktPlanNOList ?? [],
        props.shopItem.envelope?.nakoupenoNOList ?? [],
        period?.periodCode ?? "",
        props.shopItem.envelope?.purchaseEnvYear ?? 0,
        props.shopItem.pricesWithData?.data.formulaList[0].formulaTrancheType ?? ""
    );

    useEffect(() => {
        setPriceZaNakup(
            (period?.calculatedPrice ?? 0) *
            (Number.isNaN(result.mwh) ? 0 : result.mwh)
        );

        if (showHighlight) {
            props.showInGraph({
                periodCode: period?.periodCode ?? "",
                ammount: result.mwh,
                ammountMw: result.mw,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result.mwh]);

    useEffect(() => {
        setPeriod(props.shopItem.perioda);
        setLoadingButton(false);
        setShowHighlight(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            props.shopItem.pricesWithData?.data.formulaList[0].formulaTrancheType ===
            "PeakLoad"
        ) {
            setInputMW("disabled");
            setInputPercent("disabled");
            setInputMWh("disabled");
        } else if (
            props.shopItem.pricesWithData?.data.formulaList[0].formulaTrancheType ===
            "Profil"
        ) {
            setInputMW("disabled+");
            setInputPercent("");
            setInputMWh("disabled");
        } else if (
            props.shopItem.pricesWithData?.data.formulaList[0].formulaTrancheType ===
            "BaseLoad"
        ) {
            setInputMW("");
            setInputPercent("disabled+");
            setInputMWh("disabled");
        } else {
            setInputMW("");
            setInputPercent("");
            setInputMWh("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.shopItem.pricesWithData?.data.formulaList[0].formulaTrancheType]);

    useEffect(() => {
        let vysledek = (period?.amountRemaining ?? 0) <= 0;
        if (
            period?.periodCode.startsWith("Q") ||
            period?.periodCode.startsWith("R")
        ) {
            vysledek = isOverbought();
            console.log({ isOverbought: isOverbought() });
        }
        setDisableButtonFill(vysledek);

        const filteredPrice = pricesAll?.find(
            (price) => price.produkt === period?.periodCode
        );
        setPrice(filteredPrice?.cenaEurMWh ?? 0);
        props.showInGraph(undefined);
        setLoadingButton(false);
        props.newDateToCountDown(
            filteredPrice?.platnostDo
                ? new Date(filteredPrice?.platnostDo)
                : new Date()
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    useEffect(() => {
        const periodToSend: periodToSend = {
            periodCode: period?.periodCode ?? "",
            priceBaseLoad: period?.priceBaseLoad ?? 0,
            priceBaseLoadSpecified: period?.priceBaseLoadSpecified ?? false,
            pricePeakLoad: period?.pricePeakLoad ?? 0,
            pricePeakLoadSpecified: period?.pricePeakLoadSpecified ?? false,
            forwardPoints: period?.forwardPoints ?? 0,
            forwardPointsSpecified: period?.forwardPointsSpecified ?? false,
            forwardPoints3M: period?.forwardPoints3M ?? 0,
            forwardPoints3MSpecified: period?.forwardPoints3MSpecified ?? false,
            forwardPoints6M: period?.forwardPoints6M ?? 0,
            forwardPoints6MSpecified: period?.forwardPoints6MSpecified ?? false,
            exchangeRate: period?.exchangeRate ?? 0,
            kindDerivationPrice: period?.kindDerivationPrice ?? "",
            dateLoadedPrice: period?.dateLoadedPrice ?? "",
            alfa: period?.alfa ?? 0,
            beta: period?.beta ?? 0,
            gama: period?.gama ?? 0,
            calculatedPrice: period?.calculatedPrice ?? 0,
            amountRemaining: period?.amountRemaining ?? 0,
            amount: result.mwh,
            OverAmountRemAllowed: period?.OverAmountRemAllowed ?? false,
        };

        const formulaList: formulaListToSend = {
            formulaDisplayName:
                props.shopItem.pricesWithData?.data.formulaList[0].formulaDisplayName ??
                "",
            formulaName:
                props.shopItem.pricesWithData?.data.formulaList[0].formulaName ?? "",
            periodList: [periodToSend],
        };

        const buyTranche: BuyTranche = {
            email: props.shopItem.pricesWithData?.data.uzivatel ?? "",
            purchaseEnvelopeId: props.shopItem.envelope?.purchaseEnvelopeId ?? "",
            formulaList: [formulaList],
        };

        setBuyTrancheToSend(buyTranche);
        setLoadingButton(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period, JSON.stringify(result)]);

    function getPeriodListToSend(period?: period) {
        const selectedPeriod = periodsToSend.find(
            (x) => x.periodCode === period?.periodCode ?? ""
        );
        if (selectedPeriod) return [selectedPeriod];

        return [];
    }

    const buyTrancheEvent = async () => {
        setLoadingButton(true);
        const checkTrancheBuyResult = await checkTrancheBuy();
        if (checkTrancheBuyResult) {
            setLoadingButton(true);
            setModalShow(true);
        } else {
            setLoadingButton(false);
        }
    };

    const checkTrancheBuy = async () => {
        setCheckTrancheBuyResultMessages([]);

        let url = conf.REACT_APP_API_URL;
        try {
            let response = await axios.post(url + "Tranche/Check", buyTrancheToSend);
            let data = response.data;

            let error = false;
            data.data.resultList.forEach((item: any) => {
                if (item.purchaseEnvStatus === "Error") {
                    error = true;
                    return;
                }
            });

            if (error) {
                setCheckTrancheBuyResultMessages(data.data.resultList);
                return false;
            }

            return true;
        } catch (error) {
            // Zpracování chyby
            console.log(error);
        }

        return false;
    };

    const handleOnClickBuy = async () => {
        await trancheBuy();
    };

    const trancheBuy = async () => {
        if (buyTrancheToSend === undefined) return;

        setCheckTrancheBuyResultMessages([]);
        messagesReset();

        const response = await buyTranche(buyTrancheToSend);
        let data = (response as any).data;

        let error = false;
        data.data.data.resultList.forEach((item: any) => {
            if (item.purchaseEnvStatus === "Error") {
                error = true;
                return;
            }
        });

        if (error) {
            setCheckTrancheBuyResultMessages(data.data.data.resultList);
            return;
        }

        data.data.data.resultList.forEach((item: any) => {
            messagesUpdate({
                code: "nakoupeno",
                heading: "Potvrzujeme Vaši objednávku",
                type: "info",
                content: `${getPeriodLabel(
                    period?.periodCode ?? "",
                    props.shopItem.envelope?.purchaseEnvYear ?? 0
                )}|${result.mwh
                    .toFixed(conf.REACT_APP_DECIMALS)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    .replace(".", ",")}|${result.mw
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        .replace(".", ",")}|${result.percent
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        .replace(".", ",")}`,
                trancheType: props.shopItem.pricesWithData?.data.formulaList[0].formulaName ?? "",
            });
        });
        setModalShow(false);
        resetResult();
        navigate(`/nakup/${props.shopItem.envelope?.purchaseEnvelopeId}`);
    };

    const sendPin = async () => {
        const pin = Math.floor(1000 + Math.random() * 9000);

        let url = conf.REACT_APP_API_URL;
        try {
            let response = await axios.post(url + "Email/Send", {
                emailMessage: {
                    subject: "Nákupní portál - PIN",
                    to: [userEmail],
                    body: `<>Vážený zákazníku, <br /> vygenerovaný PIN kód pro potvrzení nákupu tranše je ${pin}. <br /> Děkujeme Vám za využívání našich služeb. <br /> Sev.en Industry Supply a.s.</p><p><div>----------------------------------------------</div><div>Na tento e-mail neodpovídejte, byl vygenerován automaticky ze systému.</div></p>`,
                    isBodyHtml: true,
                },
            });

            setGeneratedPin(pin);
            setPinInvalid(false);
        } catch (error) {
            // Zpracování chyby
            console.log(error);
        }
    };

    const verifyPin = async (pin: string) => {
        if (generatedPin?.toString() === pin) {
            await trancheBuy();

            setGeneratedPin(null);
            setPinInvalid(false);
            return;
        }

        setPinInvalid(true);
    };

    const handleOnChangeSelectPeriod = (
        e: ChangeEvent<HTMLInputElement>,
        period: period,
        pricesAll: cenaProTranse[]
    ) => {
        setPricesAll(pricesAll);
        resetResult();
        setPeriod(period);
    };

    const handleOnChangeObjem = (e: any) => {
        const { name, value } = e.target;
        let parsedValue = parseFloat(value.replace(",","."));

        if (parsedValue < 0) {
            parsedValue = 0;
        }

        switch (name) {
            case "percent":
                setPercent(
                    formatAmountNumber(parsedValue, conf.REACT_APP_PERCENT_DECIMALS)
                );
                break;
            case "mw":
                setMw(formatAmountNumber(parsedValue, conf.REACT_APP_MW_DECIMALS));
                break;
            case "mwh":
                setMwh(formatAmountNumber(parsedValue, conf.REACT_APP_MWH_DECIMALS));
                break;
            default:
                break;
        }
    };

    const handleDoplnitDiagram = () => {
        fillDiagram(period?.baseMWRemaining ?? 0);
    };

    const showInGraph = () => {
        props.showInGraph({
            periodCode: period?.periodCode ?? "",
            ammount: result.mwh,
            ammountMw: result.mw,
        });

        // setShowHighlight(true);
    };

    const handleStahnoutDetailniDiagram = () => {
        const pid = Math.random();
        const purchaseEnvelopeId =
            props.shopItem.envelope?.purchaseEnvelopeId ?? "";

        setLoader({ status: true, pid: pid });

        const params = new URLSearchParams({
            purchaseEnvelopeId: purchaseEnvelopeId,
            periodCode: period?.periodCode ?? "",
            formulaName:
                props.shopItem.pricesWithData?.data.formulaList[0].formulaName ?? "",
            amount: result.mwh.toString(),
        });

        axios
            .get(`${conf.REACT_APP_API_URL}Tranche/DetailDiagram`, {
                method: "GET",
                responseType: "blob",
                params: params,
            })
            .then((response) => {
                if (response.data.type === "application/json") {
                    setLoader({ status: false, pid: pid });

                    enqueueSnackbar("Něco se nezdařilo", {
                        variant: "error",
                    });
                    return;
                }

                const fname = response.headers["content-disposition"]
                    .split("filename=")[1]
                    .split(".")[0];
                const ext = response.headers["content-disposition"]
                    .split(".")[1]
                    .split(";")[0];
                const filename = `${fname.replace(`"`, ``)}.${ext.replace(`"`, ``)}`;

                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement("a");

                anchorElement.href = href;
                anchorElement.download = filename;

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
                setLoader({ status: false, pid: pid });

                enqueueSnackbar("Úspěšně staženo", {
                    variant: "success",
                });
            });
    };

    const hideSentPin = JSON.parse(conf.REACT_APP_PIN_USE_IN_BUY_PROCESS)
        ? generatedPin !== null
        : true;

    return (
        <>
            <Container>
                <Row className="mb-3">
                    <Col>
                        <label>Produkt:</label>
                        <SelectPeriod
                            shopItem={props.shopItem}
                            onChange={handleOnChangeSelectPeriod}
                        />
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col>
                        <label>
                            Požadovaný objem:{" "}
                            <LinkButton
                                onClick={handleDoplnitDiagram}
                                disabled={disableButtonFill}
                            >
                                Doplnit do 100% sjednaného diagramu
                            </LinkButton>
                        </label>
                        <Row className="trio-inputs mb-2">
                            <Col md={3}>
                                <OfferBuyProcessAmountInput
                                    name="percent"
                                    value={
                                        inputPercent === "disabled+"
                                            ? ""
                                            : formatAmount(
                                                result.percent,
                                                conf.REACT_APP_PERCENT_DECIMALS
                                            )
                                    }
                                    label="%"
                                    input={inputPercent}
                                    onBlur={handleOnChangeObjem}
                                />
                            </Col>

                            <Col>
                                <span>/</span>
                            </Col>

                            <Col md={3}>
                                <OfferBuyProcessAmountInput
                                    name="mw"
                                    value={
                                        inputMW === "disabled+"
                                            ? ""
                                            : formatAmount(result.mw, conf.REACT_APP_MW_DECIMALS)
                                    }
                                    label="MW"
                                    input={inputMW}
                                    onBlur={handleOnChangeObjem}
                                />
                            </Col>

                            <Col>
                                <span>/</span>
                            </Col>
                            <Col md={3}>
                                <OfferBuyProcessAmountInput
                                    name="mwh"
                                    value={
                                        inputMWh === "disabled+"
                                            ? ""
                                            : formatAmount(result.mwh, conf.REACT_APP_MWH_DECIMALS)
                                    }
                                    label="MWh"
                                    input={inputMWh}
                                    onBlur={handleOnChangeObjem}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-left">
                                <LinkButton onClick={handleStahnoutDetailniDiagram}>
                                    Stáhnout detailní diagram
                                </LinkButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col>
                        <p>
                            Aktuální cena na burze:{" "}
                            <strong>
                                {price
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                    .replace(".", ",")}{" "}
                                {"EUR/MWh"}
                            </strong>
                        </p>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <p>
                            Cena pro zákazníka:{" "}
                            <strong>
                                {period?.calculatedPrice
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                    .replace(".", ",")}{" "}
                                {props.shopItem.pricesWithData?.data.formulaList[0].formulaName.startsWith(
                                    "CZK"
                                )
                                    ? "CZK/MWh"
                                    : "EUR/MWh"}
                            </strong>
                        </p>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <p>
                            Celkový objem tranše:{" "}
                            <strong>
                                {(isNaN(result.mwh) ? 0 : result.mwh)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                    .replace(".", ",")}{" "}
                                MWh
                            </strong>
                        </p>
                    </Col>
                </Row>
                {isCzk && (
                    <Row className="mb-2">
                        <Col>
                            <p>
                                Kurz:{" "}
                                <strong>
                                    {period?.exchangeRate.toFixed(conf.REACT_APP_KURZ_DECIMALS).replace(".", ",")} CZK/EUR
                                </strong>
                            </p>
                        </Col>
                    </Row>
                )}
                <Row className="mb-2">
                    <Col>
                        <p>
                            Celková cena tranše:{" "}
                            <strong>
                                {priceZaNakup
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                    .replace(".", ",")}{" "}
                                {props.shopItem.pricesWithData?.data.formulaList[0].formulaName.startsWith(
                                    "CZK"
                                )
                                    ? "CZK"
                                    : "EUR"}
                            </strong>
                        </p>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col>
                        <p>
                            Sjednaný produkt:{" "}
                            <strong>
                                {pricesWithData?.data.formulaList?.[0]?.productName}
                            </strong>
                        </p>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col>
                        {checkTrancheBuyResultMessages.map((item) => {
                            const type =
                                item.purchaseEnvStatus === "OK" ? "success" : "warning";
                            return (
                                <InfoWindow
                                    type={type}
                                    content={<React.Fragment>{item.description}</React.Fragment>}
                                />
                            );
                        })}
                    </Col>
                </Row>
                {pricesWithDataIsFetching && (
                    <Row>
                        <Col>
                            <CircleLoader />
                        </Col>
                    </Row>
                )}
                {pricesWithData && (
                    <Row className="text-right" style={{ marginBottom: "1rem" }}>
                        <Col>
                            {pricesWithData?.data.alerts.map((message) => {
                                return (
                                    <InfoWindow
                                        heading="Upozornění"
                                        type="warning"
                                        content={
                                            <div>
                                                <p>{message.message}</p>
                                                <br />
                                                <p>Stáhněte si detailní digram</p>
                                            </div>
                                        }
                                    />
                                );
                            })}
                        </Col>
                    </Row>
                )}
                <Row className="text-right">
                    <Col>
                        <button className="basic-button blue" onClick={showInGraph}>
                            Zviditelnit v grafu
                        </button>
                    </Col>
                    <Col>
                        <button
                            className="basic-button"
                            onClick={() => buyTrancheEvent()}
                            disabled={
                                props.error ||
                                result.mwh <= 0 ||
                                Number.isNaN(result.mwh) ||
                                loadingButton
                            }
                        >
                            {loadingButton ? (
                                <div>
                                    <span className="spinner btnsmall" />
                                    Načítání...
                                </div>
                            ) : (
                                "Nakoupit"
                            )}
                        </button>
                    </Col>
                </Row>

                {loader.status && <CircleLoader />}
            </Container>

            <ModalBuyProcessLastStep
                show={modalShow}
                onHide={() => {
                    setModalShow(false);
                    setGeneratedPin(null);
                    setPinInvalid(false);
                    setLoadingButton(false);
                }}
                hideSendPin={hideSentPin}
                error={props.error}
                content={
                    <>
                        <ModalBuyProcessInfo
                            getPeriodLabel={getPeriodLabel}
                            period={period}
                            priceZaNakup={priceZaNakup}
                            result={result}
                            shopItem={props.shopItem}
                        />
                        <ModalBuyProcessInfoWindow
                            checkTrancheBuyResultMessages={checkTrancheBuyResultMessages}
                        />

                        {!JSON.parse(conf.REACT_APP_PIN_USE_IN_BUY_PROCESS) && (
                            <ModalBuyProcess
                                onClickBuy={handleOnClickBuy}
                                error={props.error}
                            />
                        )}

                        {JSON.parse(conf.REACT_APP_PIN_USE_IN_BUY_PROCESS) && (
                            <ModalBuyProcessPin
                                verifyPin={verifyPin}
                                invalidPin={pinInvalid}
                                hide={generatedPin === null}
                                userEmail={userEmail}
                                error={props.error}
                                onExpire={() => {
                                    setGeneratedPin(null);
                                    setPinInvalid(false);
                                }}
                            />
                        )}
                    </>
                }
                checkTrancheBuy={checkTrancheBuy}
                sendPin={sendPin}
                heading={"Potvrzení nákupu komodity"}
            />
        </>
    );
};

export default OfferBuyProcessForm;
