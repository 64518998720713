/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { usePodminky } from "../podminky/usePodminky";

interface Props {
  verifyPin: (pin: string) => boolean;
  verifyButtonDisabled?: boolean;
  onClickSendPin: () => void;
  pinCreatedTimestamp: string;
}

const PinModalInput = (props: Props) => {
  const [value, setValue] = useState<string>("");
  const [invalidPin, setInvalidPin] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(true);
  const [isCheckedPodminky, setIsCheckedPodminky] = useState(false);
  const { podminky } = usePodminky("aktualni");

  const handleOnChagePin = (e: any) => {
    const { name, value } = e.target;
    let parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
      setValue("");
      return;
    }

    setValue(parsedValue.toString());
  };

  useEffect(() => {
    setDisabled(props.verifyButtonDisabled ?? true);
  }, [props.verifyButtonDisabled]);

  const handleOnClickVerifyPin = () => {
    const result = props.verifyPin(value.toString());

    if (isCheckedPodminky) {
      setInvalidPin(!result);
    }
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key !== "Enter") return;
    if (!isCheckedPodminky) return;

    handleOnClickVerifyPin();
  };

  const handleSouhlasPodminky = () => {
    setIsCheckedPodminky(!isCheckedPodminky);
  };

  const downloadFile = async (fileName: string, path: string) => {
    try {
      const response = await axios.get(path + fileName, {
        responseType: "blob", // Nastavte typ odpovědi na blob (binární data)
      });

      // Vytvořte URL z blob a vytvořte odkaz pro stažení
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // Uvolnění objektu URL po stažení
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Chyba při stahování souboru:", error);
    }
  };

  return (
    <div
      style={{
        float: "left",
        width: "100%",
      }}
    >
      <div
        style={{
          float: "left",
          width: "30%",
        }}
      >
        <InputGroup>
          <Form.Control
            name="pin"
            placeholder="PIN"
            aria-describedby="pin"
            value={value}
            onChange={handleOnChagePin}
            isInvalid={invalidPin}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
          <Button
            className="basic-button"
            name="verifyPIN"
            disabled={disabled || !isCheckedPodminky}
            onClick={handleOnClickVerifyPin}
            onKeyDown={handleKeyDown}
            style={{
              borderTopRightRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          >
            Ověřit
          </Button>
          <Form.Control.Feedback type="invalid">
            Nesprávný PIN
          </Form.Control.Feedback>
        </InputGroup>
      </div>

      <div
        style={{
          width: "70%",
          float: "left",
          textAlign: "right",
        }}
      >
        <Button
          name="resetPIN"
          className="basic-button"
          onClick={props.onClickSendPin}
          style={{
            minHeight: "38px",
          }}
        >
          {props.pinCreatedTimestamp ? "Znovu odeslat PIN" : "Odeslat PIN"}
        </Button>
      </div>
      <Form.Group>
        <Form.Check
          type="checkbox"
          label={
            <span>
              Souhlasím s{" "}
              <a
                href={
                  process.env.REACT_APP_CLIENT +
                  "/podminky/aktualni/" +
                  podminky[0]
                }
                onClick={(e) => {
                  e.preventDefault();
                  downloadFile(
                    podminky[0] ?? "",
                    process.env.REACT_APP_CLIENT + "/podminky/aktualni/"
                  );
                }}
              >
                Podmínkami a zásadami používání Nákupního portálu
              </a>
            </span>
          }
          checked={isCheckedPodminky}
          onChange={handleSouhlasPodminky}
        />
      </Form.Group>
    </div>
  );
};

export default PinModalInput;
