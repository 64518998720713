import PinCountDownWrapper from "features/pin/PinCountdownWrapper";
import PinModal from "features/pin/PinModal";
import * as React from "react";
import HeaderButtonToggle from "./HeaderButtonToggle";
import Logo from "./Logo";
import UserInfoToggle from "./UserInfoToggle";
import { usePinLastActivity } from "features/pin/usePinLastActivity";
import conf from "app/config/appConfig";

interface Props {
  toggleUpload: (toggle: boolean) => void;
  toggleUserSidebar: (toggle: boolean) => void;
  userSidebarToggle: boolean;
}

const Header = (props: Props) => {
  const [rerender, setRerender] = React.useState<boolean>(false);
  usePinLastActivity();

  function handleToggle(toggle: boolean): void {
    props.toggleUpload(toggle);
  }

  function handleToggleUserSidebar(toggle: boolean): void {
    props.toggleUserSidebar(toggle);
  }

  const handleOnExpire = () => {
    setRerender(!rerender);
  };

  return (
    <>
      <header className="header">
        <Logo />
        <HeaderButtonToggle toggleUpload={handleToggle} />
        <PinCountDownWrapper handleOnExpire={handleOnExpire} />

        <UserInfoToggle
          toggleUserSidebar={handleToggleUserSidebar}
          userSidebarToggle={props.userSidebarToggle}
        />
        {JSON.parse(conf.REACT_APP_PIN_USE) &&
          window.location.href.includes("nakup") && (
            <PinModal rerender={rerender} />
          )}
      </header>
    </>
  );
};

export default Header;
