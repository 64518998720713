import conf from "app/config/appConfig";
import { ShopItem } from "entities/nakup/Envelopes";
import PozadovanyObjemCalculationResult from "entities/nakup/PozadovanyObjemCalculationResult";
import { period } from "entities/period/Prices";
import { useEffect, useState } from "react";

interface Props {
    getPeriodLabel: (obdobi: string, rok: number) => string;
    period: period | undefined;
    shopItem: ShopItem;
    result: PozadovanyObjemCalculationResult;
    priceZaNakup: number;
}

const ModalBuyProcessInfo = (props: Props) => {
    const [percentage, setPercentage] = useState(""); // Aktivní index

    useEffect(() => {
        setPercentage(props.result.percent.toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            .replace(".", ","));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.result.mwh]);


    return (
        <>
            {" "}
            Chystáte se učinit nákup elektříny pro období{" "}
            <b>
                {props.getPeriodLabel(
                    props.period?.periodCode ?? "",
                    props.shopItem.envelope?.purchaseEnvYear ?? 0
                )}
            </b>
            <br /> v množství{" "}
            <b>
                {props.result.mwh
                    .toFixed(conf.REACT_APP_DECIMALS)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    .replace(".", ",")}{" "}
                MWh /{" "}
                {props.result.mw
                    .toFixed(3)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    .replace(".", ",")}{" "}
                MW 
                {props.shopItem.pricesWithData?.data.formulaList[0].formulaTrancheType === "BaseLoad" ? null : (
                    <>
                        {" "}/{" "}{percentage}% spotřeby
                    </>
                )}
            </b>{" "}
            za{" "}
            <b>
                {props.priceZaNakup
                    .toFixed(conf.REACT_APP_DECIMALS)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    .replace(".", ",")}{" "}
                {props.shopItem.pricesWithData?.data.formulaList[0].formulaName.startsWith(
                    "CZK"
                )
                    ? "CZK"
                    : "EUR"}
            </b>            

            {props.shopItem.pricesWithData?.data.formulaList[0].formulaTrancheType === "BaseLoad" ? null : (
                <>
                    <br />
                    <br />S tímto nákupem pokryjete předpokládanou spotřebu na
                    <br />{" "}
                    {props.getPeriodLabel(
                        props.period?.periodCode ?? "",
                        props.shopItem.envelope?.purchaseEnvYear ?? 0
                    )}{" "}z{" "}
                    <b>
                        {percentage}
                        %.
                    </b>
                </>
            )}

            
        </>
    );
};

const getPercents = (total: number, piece: number): string => {
    const result = total === 0 ? 0 : piece / (total / 100);
    return result
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace(".", ",");
};

export default ModalBuyProcessInfo;
