import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MenuProps } from "shared/menu/auth/MenuModel";
import AppState from "./AppState";

export interface MenuState {
  menuModel: MenuProps;
}

export const initialState: MenuState = {
  menuModel: {
    // Zde dopl�te po�adovan� obsah pro menuModel v initialState
    menuItems: [
      {
        id: "1",
        title: "HLAVNÍ NABÍDKA",
        type: "heading",
      },
      {
        id: "2",
        icon: "home",
        title: "Domů",
        type: "dynamicLink",
        url: "flexiUrl",
      },
      {
        id: "3",
        icon: "file-invoice-dollar",
        title: "Faktury",
        type: "dynamicLink",
        url: "flexiUrl",
      },
      // Zde p�idejte dal�� polo�ky menu dle pot�eby
    ],
  },
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    menuUpdate: (state, action: PayloadAction<Partial<MenuProps>>) => {
      state.menuModel = {
        ...state.menuModel,
        ...action.payload,
      };
    },
  },
});

export const actions = menuSlice.actions;

export default menuSlice.reducer;

// SELECTORS
export const selectMenu = (state: AppState) => state.menu;
