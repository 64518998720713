import { useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { zeroPad } from "shared/helpers/numberHelpers";

interface Props {
  expiryTimestamp: Date;
  onExpire: () => void;
}

const PinModalCountdown = (props: Props) => {
  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp: props.expiryTimestamp,
    onExpire: props.onExpire,
  });

  useEffect(() => {
    restart(props.expiryTimestamp);
  }, [props.expiryTimestamp.toISOString()]);

  return (
    <>
      {isRunning && (
        <>
          {" "}
          Platnost kódu vyprší za {minutes}:{zeroPad(seconds, 2)}.
        </>
      )}

      {!isRunning && <p style={{ color: "red" }}>Platnost kódu vypršela.</p>}
    </>
  );
};

export default PinModalCountdown;
