import { useDispatch } from "react-redux";
import { ShopItem } from "../../../entities/nakup/Envelopes";
import { actions } from "./shopSlice";
import MessageWindowModel from "shared/features/infoWindow/InfoWindowModel";

export const useShopSlice = () => {
  const dispatch = useDispatch();

  const shopItemUpdate = (shopItem: Partial<ShopItem>) => {
    dispatch(actions.shopItemUpdate(shopItem));
  };

  const messagesUpdate = (messages: MessageWindowModel) => {
    dispatch(actions.messagesUpdate(messages));
  };

  const messagesReset = () => {
    dispatch(actions.messagesReset());
  };

  return {
    shopItemUpdate,
    messagesUpdate,
    messagesReset,
  };
};
