import { period } from "entities/period/Prices";

export const useComodityBuyAllowed = () => {
  const getCommodityBuyAllowed = (
    platnostDo?: string,
    platnostDoDate?: Date,
    perioda?: period
  ) => {
    const currentDate = new Date();
    let result = false;

    if (perioda) {
      if (
        (!perioda.alfa && !perioda.gama) ||
        (perioda.alfa === 0 && perioda.gama === 0)
      ) {
        result = false;
      } else {
        result = true;
      }
    }

    if (platnostDo && platnostDoDate) {
      result = true;
      if (platnostDoDate.getTime() > currentDate.getTime()) {
        result = true;
      } else {
        result = false;
      }
    } else {
      result = true;
    }

    return result;
  };

  return {
    getCommodityBuyAllowed,
  };
};
