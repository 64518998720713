import * as React from "react";
import { List, TrancheBoughtList } from "../../../../entities/nakup/Envelopes";
import conf from "app/config/appConfig";

interface Props {
  content: TrancheBoughtList;
  cancelled: boolean;
  offline?: boolean | null;
  year: number;
}

const TableBodyRow = (props: Props) => {
  return (
    <tr>
      <td className="text-left">
        <strong>{formatDate(props.content.purchasingDate.toString())}</strong>
        {props.offline && <span>Offline objednávka</span>}
      </td>
      <td className="text-left">
        <strong>
          {checkPeriod(props.content.boughtList)} {props.year}
        </strong>
        <span>{props.content.trancheId}</span>
      </td>
      <td className="text-right">
        {props.content.amount
          .toFixed(conf.REACT_APP_DECIMALS)
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          .replace(".", ",")}
      </td>
      <td className="text-right">
        {props.content.price
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          .replace(".", ",")}{" "}
        {props.content.currency}/MWh
      </td>
      <td className="text-right">
        {totalPrice(props.content.amount, props.content.price)}{" "}
        {props.content.currency}
      </td>
      <td className="text-center">
        {props.cancelled ? (
          <div className="error-cancelled">Objednávka zrušena</div>
        ) : (
          <div className="error-approved">Objednávka potvrzena</div>
        )}
      </td>
    </tr>
  );
};

export default TableBodyRow;

const formatDate = (dateString: string | number | Date) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Přidáme nuly na začátek čísel pro jednociferné dny, měsíce, hodiny a minuty
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Sestavíme datum ve formátu "dd. mm. yyyy HH:mm"
  const formattedDate = `${formattedDay}. ${formattedMonth}. ${year} ${formattedHours}:${formattedMinutes}`;

  return formattedDate;
};

const totalPrice = (num1: number, num2: number) => {
  var total = num1 * num2;
  return total
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");
};

const checkPeriod = (list: List[]) => {
  const nonZeroMonths = list.filter((item) => item.amount !== 0);

  if (nonZeroMonths.length === 12) {
    return "Celý rok";
  } else {
    const quarters = [
      [1, 2, 3],
      [4, 5, 6],
      [7, 8, 9],
      [10, 11, 12],
    ];
    let foundQuarter = null;

    quarters.forEach((quarter, index) => {
      if (
        quarter.every((month) =>
          nonZeroMonths.find((item) => item.month === month)
        )
      ) {
        foundQuarter = index + 1;
      }
    });

    if (foundQuarter) {
      return `${foundQuarter}. kvartál`;
    } else {
      const month = nonZeroMonths[0].month;
      return getMonthName(month);
    }
  }
};

const getMonthName = (month: number) => {
  const monthNames = [
    "Leden",
    "Únor",
    "Březen",
    "Duben",
    "Květen",
    "Červen",
    "Červenec",
    "Srpen",
    "Září",
    "Říjen",
    "Listopad",
    "Prosinec",
  ];

  return monthNames[month - 1] || "";
};
