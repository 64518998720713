import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SelectedUser } from "../../../entities/diagram/Diagrams";
import AppState from "../AppState";

export interface SelectedUserState {
    selectedUserItem: SelectedUser;
}

export const initialState: SelectedUserState = {
    selectedUserItem: {
        userid: "",
    },
};

export const selectedUserSlice = createSlice({
    name: "selectedUser",
    initialState,
    reducers: {
        selectedUserUpdate: (
            state,
            action: PayloadAction<Partial<SelectedUser>>
        ) => {
            state.selectedUserItem = {
                ...state.selectedUserItem,
                ...action.payload,
            };
        },
    },
});

export const actions = selectedUserSlice.actions;

export default selectedUserSlice.reducer;

// SELECTORS
export const selectedUserItem = (state: AppState) =>
    state.selectedUser.selectedUserItem;
