import * as React from "react";
import ContentWindow from "../../features/layout/ContentWindow";
import Header from "../../features/layout/Header";
import Sidebar from "../../features/layout/Sidebar";
import UserSidebar from "../../features/layout/UserSidebar";
require(`../styles/${process.env.REACT_APP_CLIENT}/defaultTemplate.css`);

interface Props {
  outlet: React.ReactElement;
}

//Z�kladn� layout skl�daj�c� se z hlavi�ky, lev�ho sidebaru s navigac� a pak obsahov�ho okna

const Layout = (props: Props) => {
  const [sidebarToggle, setSidebarToggle] = React.useState<boolean>(false);
  const [userSidebarToggle, setUserSidebarToggle] =
    React.useState<boolean>(false);

  function handleToggle(toggle: boolean): void {
    setSidebarToggle(toggle);
  }

  function handleUserSidebarOpen(toggle: boolean): void {
    setUserSidebarToggle(toggle);
  }

  function handleUserSidebarClose(toggle: boolean): void {
    setUserSidebarToggle(toggle);
  }

  return (
    <>
      {/* <LayoutStyle /> */}

      <Header
        toggleUpload={handleToggle}
        userSidebarToggle={userSidebarToggle}
        toggleUserSidebar={handleUserSidebarOpen}
      />

      <Sidebar toggler={sidebarToggle} />

      <ContentWindow content={props.outlet} />

      <UserSidebar
        toggler={userSidebarToggle}
        toggleUserSidebar={handleUserSidebarClose}
      />
    </>
  );
};

export default Layout;
