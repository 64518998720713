import * as React from "react";
import TableHeadingRow from "./TableHeadingRow";

interface Props {
  content: string[];
  sort: (sort: string) => void;
}

const TableHeading = (props: Props) => {
  return (
    <thead>
      <TableHeadingRow content={props.content} sort={props.sort} />
    </thead>
  );
};

export default TableHeading;
