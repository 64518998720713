import PeriodGetParams from "app/api/main/period/PeriodGetParams";
import { useGetPeriodQuery } from "app/api/main/period/periodApi";
import PriceGetParams from "app/api/main/price/PriceGetParams";
import { useGetPriceQuery } from "app/api/main/price/priceApi";
import GetCenaParams from "app/api/main/tranche/GetCenaParams";
import { useGetCenaQuery } from "app/api/main/tranche/trancheApi";
import { cenaProTranse, period } from "entities/period/Prices";
import { getPeriodsToSend } from "features/period/periodHelper";
import { useEffect, useState } from "react";

export const useSelectPeriod = (
  email: string,
  purchaseEnvelopeId: string,
  periodGetParams: PeriodGetParams,
  getCenaParams: GetCenaParams,
  pSelectedPeriodCode: string
) => {
  const [periods, setPeriods] = useState<period[]>([]);

  const [pricesAll, setPricesAll] = useState<cenaProTranse[]>([]);

  const [selectedPeriodCode, setSelectedPeriodCode] =
    useState<string>(pSelectedPeriodCode);

  // GetPeriodQuery
  const {
    data: periodWithPrice,
    isFetching: periodWithPriceIsFetching,
    refetch: periodWithPriceRefetch,
  } = useGetPeriodQuery(periodGetParams, {
    skip: periodGetParams === null,
  });

  // GetPriceQuery
  const [priceGetParams, setPriceGetParams] = useState<PriceGetParams | null>(
    null
  );
  const { data: pricesWithData, isFetching: pricesWithDataIsFetching } =
    useGetPriceQuery(priceGetParams, {
      skip: priceGetParams === null,
    });

  // GetCenaQuery
  const {
    data: prices,
    isFetching: pricesIsFetching,
    refetch: pricesRefetch,
  } = useGetCenaQuery(getCenaParams, {
    skip: getCenaParams === null,
  });

  useEffect(() => {
    if (periodWithPriceIsFetching) return;
    if (periodWithPrice === undefined || periodWithPrice === null) return;

    if (pricesIsFetching) return;
    if (prices === undefined) return;

    const newPeriodsToSend = getPeriodsToSend(periodWithPrice, prices);

    setPricesAll(prices?.data?.cenaProTranseEE ?? []);

    setPriceGetParams({
      Email: email,
      PurchaseEnvelopeId: purchaseEnvelopeId,
      PeriodList: newPeriodsToSend,
    });
  }, [periodWithPriceIsFetching, pricesIsFetching]);

  useEffect(() => {
    if (pricesWithDataIsFetching) return;

    const newPeriods = (
      pricesWithData?.data.formulaList[0].periodList ?? []
    ).filter((x) => x.priceBaseLoad !== 0);

    setPeriods(newPeriods);
  }, [pricesWithDataIsFetching]);

  useEffect(() => {
    setPeriods([]);

    periodWithPriceRefetch();
    pricesRefetch();
  }, [selectedPeriodCode]);

  return {
    periods,
    selectedPeriodCode,
    setSelectedPeriodCode,
    pricesAll,
    pricesIsFetching,
    pricesWithDataIsFetching,
    periodWithPriceIsFetching,
  };
};
