import conf from "app/config/appConfig";

const Logo = () => {
  return (
    <>
      <div className="logo-wrap">
        <a href={conf.REACT_APP_PUBLIC_URL}>
          <img
            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_CLIENT}/logo.png`}
            alt="Logo"
          />
        </a>
      </div>
    </>
  );
};

export default Logo;
