import { useDispatch } from "react-redux";
import { actions } from "./navigationSlice";
import EnvelopeNavigationItem from "entities/navigation/EnvelopeNavigationItem";

export const useNavigationSlice = () => {
  const dispatch = useDispatch();

  const envelopeNavigationItemUpdate = (
    envelopeNavigationItem: Partial<EnvelopeNavigationItem>
  ) => {
    dispatch(actions.envelopeNavigationItemUpdate(envelopeNavigationItem));
  };

  return {
    envelopeNavigationItemUpdate,
  };
};
