import PinCountdown from "./PinCountdown";

interface Props {
  handleOnExpire: () => void;
}

const PinCountDownWrapper = (props: Props) => {
  const handleOnExpire = () => {
    props.handleOnExpire();
  };

  return (
    <div className="header-countdown">
      {window.location.href.includes("nakup") && (
        <PinCountdown onExpire={handleOnExpire} />
      )}
    </div>
  );
};

export default PinCountDownWrapper;
